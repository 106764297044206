import React, {useState, useEffect} from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Chip,
} from '@mui/material';
import {sendDataToApi} from '../../API/apiHelper';

function renameKeys(obj, newKeys) {
    const keyValues = Object.keys(obj).map(key => {
        const newKey = newKeys[key] || key;
        return {[newKey]: obj[key]};
    });
    return Object.assign({}, ...keyValues);
}

function EditUserDialog({openEditUser, onClose, onSave, initialEditUser}) {
    const [editUser, setEditUser] = useState([]);

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setEditUser({...editUser, [name]: value});
    };

    useEffect(() => {
        setEditUser(initialEditUser);
    }, [initialEditUser]);


    const handleSaveUser = () => {
        const userId = localStorage.getItem('user_id');

        const keysMap = {
            name: 'Name',
            email: 'email',
            position: 'Position',
            status: 'Status',
            password: 'password',
            Role: 'Role',
            id: 'ID',
        };

        const updatedEditUser = renameKeys(editUser, keysMap);

        const updatedUser = {
            ...updatedEditUser,
            ChangedBy: userId
        };
        const response = sendDataToApi('post', '/api/user/update', updatedUser, false, (data) => {
            onSave(editUser);
            onClose();
        });

    };

    return (
        <Dialog open={openEditUser} onClose={onClose}>
            <DialogTitle>Edit user {editUser.name} </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    name="name"
                    label="User Name"
                    type="text"
                    value={editUser.name || ''}
                    onChange={handleInputChange}
                    fullWidth
                />
                <TextField
                    margin="dense"
                    name="password"
                    label="Password"
                    type="password"
                    value={editUser.password || ''}
                    onChange={handleInputChange}
                    fullWidth
                />
                <FormControl fullWidth required>
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                        value={editUser.status || ''}
                        onChange={handleInputChange}
                        name="status"
                        label="User Status"
                        fullWidth
                    >
                        <MenuItem value={"active"}>Active</MenuItem>
                        <MenuItem value={"blocked"}>Blocked</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{mt: '8px'}}>
                    <InputLabel id="role-label">Role</InputLabel>
                    <Select
                        labelId="role-label"
                        multiple
                        value={typeof editUser.Role === 'string' ? [editUser.Role.toLowerCase()] : (editUser.Role || []).map(role => role.toLowerCase())}
                        onChange={handleInputChange}
                        name="Role"
                        renderValue={(selected) => (
                            <div>
                                {selected.map((value) => (
                                    <Chip key={value} label={value}/>
                                ))}
                            </div>
                        )}
                    >
                        <MenuItem value={"account manager"}>Account Manager</MenuItem>
                        <MenuItem value={"publisher manager"}>Publisher Manager</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    margin="dense"
                    name="position"
                    label="User Position"
                    type="text"
                    value={editUser.position || ''}
                    onChange={handleInputChange}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSaveUser}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditUserDialog;
