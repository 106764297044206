import {fetchDataFromApi} from './apiHelper';

export const fetchDspStatisticFields = () => fetchDataFromApi('post', '/api/database/dsp/statistic');
export const fetchDspComanies = () => fetchDataFromApi('post', '/api/database/dsp/companies');
export const fetchDspSmartHub = () => fetchDataFromApi('post', '/api/database/dsp/smarthub');
export const fetchFreeDspSmartHub = () => fetchDataFromApi('post', '/api/database/dsp/freesmarthub');
export const fetchDspBidscube = () => fetchDataFromApi('post', '/api/database/dsp/bidscube');
export const fetchFreeDspBidscube = () => fetchDataFromApi('post', '/api/database/dsp/freebidscube');

export const fetchSspStatisticFields = () => fetchDataFromApi('post', '/api/database/ssp/statistic');
export const fetchSspComanies = () => fetchDataFromApi('post', '/api/database/ssp/companies');
export const fetchSspSmartHub = () => fetchDataFromApi('post', '/api/database/ssp/smarthub');
export const fetchFreeSspSmartHub = () => fetchDataFromApi('post', '/api/database/ssp/freesmarthub');
export const fetchSspBidscube = () => fetchDataFromApi('post', '/api/database/ssp/bidscube');
export const fetchFreeSspBidscube = () => fetchDataFromApi('post', '/api/database/ssp/freebidscube');