import React, {useState, useEffect, useRef} from 'react';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import {
    Button,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails, FormControlLabel, Checkbox, CircularProgress, Box, Radio,
} from '@mui/material';
import { fetchDspCompanies } from '../../../../API/FinancialTools';
import {
    fetchStatisticFieldsRevenue,
    fetchIntervalsRevenue,
    fetchRevenueReportData,
    fetchDataFieldsRevenue,
} from '../../../../API/FinancialReports';
import DateRangeSelector from '../../../../components/date/DateRangeSelector';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import {sendDataToApi, sendJsonToApi} from '../../../../API/apiHelper';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import SelectMulti from '../../../../components/select/SelectMulti';
import {fetchAccManagers} from '../../../../API/AdminUserControl';
import useApi from '../../../../hooks/useApi';
import {toast} from 'react-toastify';
import Cookies from 'js-cookie';
import axios from "axios";

function RevenueReportOptionsForm({onSubmit}) {
    const {data: statistics, loading: loadingStats} = useApi(fetchStatisticFieldsRevenue);
    const {data: interval, loading: loadingInterval} = useApi(fetchIntervalsRevenue);
    const {data: dspCompany, loading: loadingCompany} = useApi(fetchDspCompanies);
    const {data: data, loading: loadingData} = useApi(fetchDataFieldsRevenue);

    const {data: managers, loading: loadingManagers} = useApi(fetchAccManagers);

    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [showAdvertiser, setShowAdvertiser] = useState(false);
    const [showPlatform, setShowPlatform] = useState(false);
    const [showManager, setShowManager] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);
    const [openUpload, setOpenUpload] = useState(false);
    const [showStatus, setShowStatus] = useState(false);

    const [selectedAdvertiser, setSelectedAdvertiser] = useState('');
    const [selectedPlatform, setSelectedPlatform] = useState('');
    const [selectedManager, setSelectedManager] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');

    const [platform, setPlatform] = useState([
        {name: 'SmartHub', id: 'smarthub'},
        {name: 'BidsCube', id: 'bidscube'}
    ]);
    const [irtbStatus, setIrtbStatus] = useState([
        {name: 'Approved', id: 'approved'},
        {name: 'New', id: 'new'}
    ]);
    const [status, setStatus] = useState([
        {name: 'Invoice has been sent', id: 'Invoice has been sent'},
        {name: 'Invoice not sent', id: 'Invoice not sent'},
        {name: 'Client paid', id: 'Client paid'},
    ]);
    const handleClickOpenCreate = () => setOpenCreate(true);
    const handleClickOpenUpload = () => setOpenUpload(true);
    const handleCloseUpload = () => setOpenUpload(false);
    const handleCloseCreate = () => setOpenCreate(false);

    const today = new Date();
    const firstDayPrevMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastDayPrevMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    const startformattedDate = firstDayPrevMonth.toISOString().split('T')[0];
    const endformattedDate = lastDayPrevMonth.toISOString().split('T')[0];


    const [options, setOptions] = useState({
        start_date: startformattedDate,
        end_date: endformattedDate,
        stat: [],
        data: [],
        interval: '',
        manager: '',
        platform: '',
        status: '',
        advertiser: '',
    });

    useEffect(() => {
        const storedValue = Cookies.get('RevenueReport');
        if (storedValue) {
            const parsedValue = JSON.parse(storedValue);

            if ('stat' in parsedValue) {
                setOptions(prevOptions => ({
                    ...prevOptions,
                    stats: parsedValue.stat
                }));
            }
            if ('data' in parsedValue) {
                setOptions(prevOptions => ({
                    ...prevOptions,
                    stats: parsedValue.data
                }));
            }
            if ('start_date' in parsedValue) {
                setOptions(prevOptions => ({
                    ...prevOptions,
                    start_date: parsedValue.start_date
                }));
            }
            if ('end_date' in parsedValue) {
                setOptions(prevOptions => ({
                    ...prevOptions,
                    end_date: parsedValue.end_date
                }));
            }
            if ('interval' in parsedValue) {
                setOptions(prevOptions => ({
                    ...prevOptions,
                    end_date: parsedValue.interval
                }));
            }
        }
    }, []);

    const [selectedRange, setSelectedRange] = useState({
        startDate: new Date(options.start_date),
        endDate: new Date(options.start_date),
        key: 'selection',
    });

    const handleCheckboxChange = (event) => {
        const {name} = event.target;
        setOptions({...options, fields: name});
    };

    useEffect(() => {
        const storedValue = Cookies.get('RevenueReport');
        if (storedValue) {
            const parsedValue = JSON.parse(storedValue);
            setOptions(parsedValue);
        }

    }, []);

    const handleDateChange = (selection) => {
        const endDate = new Date(selection.endDate);
        endDate.setHours(12, 12, 12, 12);
        const endDateISOString = endDate.toISOString().split('T')[0];

        const startDate = new Date(selection.startDate);
        startDate.setHours(12, 12, 12, 12);
        const startDateISOString = startDate.toISOString().split('T')[0];

        setSelectedRange((prevSelectedRange) => ({
            ...prevSelectedRange,
            startDate: new Date(startDateISOString),
            endDate: new Date(endDateISOString),
            key: 'selection',
        }));

        if (endDate && startDate) {
            setOptions((prevOptions) => ({
                ...prevOptions,
                start_date: startDateISOString,
                end_date: endDateISOString,
            }));
        }
    };

    const handleCheckboxChangeStat = (event) => {
        const {name, checked} = event.target;
        let stat = [...options.stat];

        if (checked) {
            stat.push(name);
        } else {
            stat = stat.filter((satat) => satat !== name);
        }

        setOptions({...options, stat});
    };

    const handleCheckboxChangeData = (event) => {
        const {name, checked} = event.target;
        let data = [...options.data];

        if (checked) {
            data.push(name);
        } else {
            data = data.filter((satat) => satat !== name);
        }

        setOptions({...options, data});
    };

    const handleCheckboxChangeInterval = (event) => {
        const { name } = event.target;

        if (options.interval.includes(name)) {
            setOptions({...options, interval: ''});
        } else {
            setOptions({...options, interval: name});
        }
    };

    const handleSubmit = async (event) => {
        if (options.stat.length === 0 || options.data.length === 0) {
            toast.info(`Please select at least one statistic and one data field.`);
            return;
        }
        onSubmit(options);
    };

    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            manager: [selectedManager],
        }));
    }, [selectedManager]);

    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            platform: [selectedPlatform],
        }));
    }, [selectedPlatform]);

    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            status: [selectedStatus],
        }));
    }, [selectedStatus]);

    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            advertiser: [selectedAdvertiser],
        }));
    }, [selectedAdvertiser]);


    return (
        <Grid container spacing={2} sx={{width: "100%", marginLeft: '0px',}}>
            <Accordion sx={{width: "100%"}}>
                <AccordionSummary
                    expandIcon={<KeyboardDoubleArrowDownOutlinedIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{backgroundColor: '#f0f3f5', width: "100%"}}
                >
                    Options
                </AccordionSummary>
                <AccordionDetails sx={{width: "100%"}}>
                    <form onSubmit={handleSubmit}>
                        <Grid item xs={12} md={12} sx={{width: "100%"}}>
                            <Grid item xs={12} md={12} sx={{width: "100%", margin: '25px 0 25px auto'}}>

                                <Grid item xs={12} md={12}>
                                    {Object.keys(data).length != 0 ? (<strong>Data</strong>) : ''}
                                </Grid>
                                {data.map((statistic, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                                checked={options.data.includes(statistic.key)}
                                                onChange={handleCheckboxChangeData}
                                                name={statistic.key}
                                            />
                                        }
                                        label={statistic.value}
                                    />
                                ))}

                            </Grid>

                            <Grid item xs={12} md={12} sx={{width: "100%", margin: '25px 0 25px auto'}}>

                                <Grid item xs={12} md={12}>
                                    {Object.keys(statistics).length != 0 ? (<strong>Statistic</strong>) : ''}
                                </Grid>
                                {statistics.map((statistic, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                                checked={options.stat.includes(statistic.key)}
                                                onChange={handleCheckboxChangeStat}
                                                name={statistic.key}
                                            />
                                        }
                                        label={statistic.value}
                                    />
                                ))}

                            </Grid>

                            <Grid item xs={12} md={12} sx={{width: "100%", margin: '25px 0 25px auto'}}>

                                <Grid item xs={12} md={12}>
                                    {Object.keys(interval).length != 0 ? (<strong>Time Interval</strong>) : ''}
                                </Grid>
                                {interval.map((statistic, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                                checked={options.interval.includes(statistic.key)}
                                                onChange={handleCheckboxChangeInterval}
                                                name={statistic.key}
                                            />
                                        }
                                        label={statistic.value}
                                    />
                                ))}

                            </Grid>

                            <Grid item xs={12} md={12} sx={{width: "100%", margin: '25px 0 25px auto'}}>
                                <Grid item xs={12} md={12}>
                                    <strong>Filters</strong>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={showPlatform}
                                                    onChange={() => setShowPlatform(!showPlatform)}
                                                    name="platform"
                                                />
                                            }
                                            label="Platform"
                                        />

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={showStatus}
                                                    onChange={() => setShowStatus(!showStatus)}
                                                    name="Status"
                                                />
                                            }
                                            label="Hyper status"
                                        />

                                        {Object.keys(dspCompany).length != 0 ? (<FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={showAdvertiser}
                                                    onChange={() => setShowAdvertiser(!showAdvertiser)}
                                                    name="Advertiser"
                                                />
                                            }
                                            label="Advertiser"
                                        />) : ''}


                                        {Object.keys(managers).length != 0 ? (<FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={showManager}
                                                    onChange={() => setShowManager(!showManager)}
                                                    name="Manager"
                                                />
                                            }
                                            label="Account Managers"
                                        />) : ''}
                                    </Grid>

                                    <Grid item xs={6}>
                                        {showPlatform && (
                                            <SelectMulti
                                                label="Platform"
                                                items={platform}
                                                selectedItems={selectedPlatform}
                                                onChange={(event) => setSelectedPlatform(event.target.value)}
                                                itemLabel="name"
                                                itemKey="id"
                                                itemValue="id"
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {showStatus && (
                                            <SelectMulti
                                                label="Hyper status"
                                                items={status}
                                                selectedItems={selectedStatus}
                                                onChange={(event) => setSelectedStatus(event.target.value)}
                                                itemLabel="name"
                                                itemKey="id"
                                                itemValue="id"
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {showAdvertiser && (
                                            <SelectMulti
                                                label="Advertiser"
                                                items={dspCompany}
                                                selectedItems={selectedAdvertiser}
                                                onChange={(event) => setSelectedAdvertiser(event.target.value)}
                                                itemLabel="value.name"
                                                itemKey="value.id"
                                                itemValue="value.id"
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {showManager && (
                                            <SelectMulti
                                                label="Account Managers"
                                                items={managers}
                                                selectedItems={selectedManager}
                                                onChange={(event) => setSelectedManager(event.target.value)}
                                                itemLabel="value.name"
                                                itemKey="value.id"
                                                itemValue="value.id"
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </AccordionDetails>
            </Accordion>

            <Grid item xs={6} md={6}>
                <Button
                    size={"large"}
                    startIcon={<BackupTableOutlinedIcon fontSize="small"/>}
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{marginLeft: '-15px'}}
                >
                    Generate Report
                </Button>
            </Grid>

            <Grid item xs={6} md={6}>
                <DateRangeSelector
                    initialRange={{ startDate: firstDayPrevMonth, endDate: lastDayPrevMonth, key: 'selection' }}
                    onRangeChange={handleDateChange}
                />
            </Grid>

        </Grid>
    );
}

export default RevenueReportOptionsForm;
