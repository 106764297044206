import React, {useEffect, useLayoutEffect, useRef} from 'react';
import {DataGrid, GridToolbar, useGridApiRef} from '@mui/x-data-grid';
import {styled} from '@mui/system';

const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    '& .MuiDataGrid-row': {
        '&:nth-child(even)': {
            backgroundColor: '#eaf0f5',
        },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
    },
    '& .negative.win_rate_per, .negative.revenue_per, .negative.fill_rate_per, .negative.profit_per': {
        backgroundColor: 'rgba(255,235,235)',
    },
    '& .positive.win_rate_per, .positive.revenue_per, .positive.fill_rate_per, .positive.profit_per': {
        backgroundColor: 'rgba(235,255,235)',
    },
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal !important',
        wordWrap: 'break-word',
    },
    '& .total-row': {
        backgroundColor: 'lightgrey',
        fontWeight: 'bold',
    },
}));

const CustomFooter = ({totalRow}) => {
    return (
        <div style={{backgroundColor: 'white', padding: '0px'}}>
            Total Impressions:
        </div>
    );
};

function formatCurrency(value) {
    if (typeof value !== 'undefined' && value !== null && typeof value === 'number') {
        if (value % 1 === 0) {
            return value.toString().replace(/\d(?=(\d{3})+\b)/g, '$&,');
        } else {
            return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
    } else {
        return '';
    }
}

function DataTable({data}) {
    const gridRef = useRef(null);
    if (!data || data.length === 0) return null;
    const columnNames = Object.keys(data[0].value);


    let totalRow = {id: 'total'};


    let rows = [];

    data.forEach((element, index) => {
        let row = {id: index};

        for (let key in element.value) {
            let value = element.value[key];

            if (!isNaN(parseFloat(value)) && isFinite(value)) {
                value = parseFloat(value);
            }

            row[key] = value;

            if ((/impressions/i.test(key) || /revenue(?:\d+)?$/.test(key) || /responses(?:\d+)?$/.test(key) || /Block_Count(?:\d+)?$/.test(key) || /Incident_Count(?:\d+)?$/.test(key) || /Impression_Count(?:\d+)?$/.test(key)) && typeof value === 'number') {
                if (typeof totalRow[key] !== 'number') {
                    totalRow[key] = 0;
                }
                totalRow[key] += value;
            }
        }

        rows.push(row);
    });

    if (Object.keys(totalRow).length > 1) {
        rows.push(totalRow);
    }

    const columns = columnNames.map((col, index) => {
        let config = {
            field: col,
            headerName: col.charAt(0).toUpperCase() + col.slice(1).replace(/_/g, ' ').replace(/per/g, '%'),
            flex: 1,
            minWidth: 50,
        };

        if ((/impressions/i.test(col) || /revenue(?:\d+)?$/.test(col) || /responses(?:\d+)?$/.test(col) || /Block_Count(?:\d+)?$/.test(col) || /Incident_Count(?:\d+)?$/.test(col) || /Impression_Count(?:\d+)?$/.test(col))) {
            config.valueFormatter = (params) => `${formatCurrency(params.value)}`
        }

        if (['SSP_name'].includes(col)) {
            config.minWidth = 300;

            config.cellClassName = (params) => {
                return 'ssp_name';
            };
        }
        if (['note'].includes(col)) {
            config.minWidth = 300;

            config.cellClassName = (params) => {
                return 'ssp_name';
            };
        }
        if (['updates'].includes(col)) {
            config.minWidth = 300;

            config.cellClassName = (params) => {
                return 'ssp_name';
            };
        }

        if (/id/.test(col)) {
            config.maxWidth = 85;
        }
        if (/ssp_id/.test(col)) {
            config.minWidth = 110;
            config.maxWidth = 120;
        }

        if (['bundle_id'].includes(col)) {
            config.minWidth = 300;

            config.cellClassName = (params) => {
                return 'bundle_id';
            };
        }
        if (['dsp_name'].includes(col)) {
            config.minWidth = 300;

            config.cellClassName = (params) => {
                return 'dsp_name';
            };
        }
        if (['is_blocked'].includes(col)) {
            config.maxWidth = 70;

            config.cellClassName = (params) => {
                return 'is_blocked';
            };
        }

        if (['win_rate_per', 'revenue_per', 'fill_rate_per', 'profit_per'].includes(col)) {
            config.cellClassName = (params) => {
                const value = params.value;
                if (value > 0) {
                    return `positive ${col}`;
                } else if (value < 0) {
                    return `negative ${col}`;
                }
            };
        }

        return config;
    });

    return (
        <div style={{height: 800, width: '100%'}} ref={gridRef}>
            <StyledDataGrid
                //rowHeight={30}
                getRowHeight={() => 'auto'}
                rows={rows}
                columns={columns}
                pageSize={25}
                rowsPerPageOptions={[5]}
                slots={{
                    toolbar: GridToolbar,
                }}
                disableRowSelectionOnClick
            />
        </div>
    );
}


export default DataTable;