import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type appState = {
  appState: string;
  isAdmin: boolean;
};

const initialState: appState = {
  appState: "",
  isAdmin: false
};

// export const appStateSlice = createSlice({
//   name: "appState",
//   initialState,
//   reducers: {
//     setAppState: (state, action: PayloadAction<string>) => {
//       state.appState = action.payload;
//     }
//   }
// });

export const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setAppState: (state, action: PayloadAction<string>) => {
      state.appState = action.payload;
    },
    setAdmin: (state) => {
      state.isAdmin = true;
    },
    setUser: (state) => {
      state.isAdmin = false;
    }
  }
});

// export const {
//   setAppState
// } = appStateSlice.actions;

export const {
  setAppState,
  setAdmin,
  setUser
} = appStateSlice.actions;

export default appStateSlice.reducer;