import React, {useEffect, useState} from 'react';
import {Box, Button, Grid, Typography, } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import "@fontsource/roboto";
import LogControlOptionsForm from "./options/LogControlOptionsForm";
import OutletIcon from "@mui/icons-material/Outlet";
import DataTable from "./components/LogsTable";
import DownloadButton from "../../../components/reports/DownloadButton";
import { format } from 'date-fns';



const AdsLogs = () => {
    const [data, setData] = useState([]);
    const [name, setName] = useState('');

    useEffect(()=>{
        const currentDate = new Date();
        const formattedDate = format(currentDate, 'yyyy-MM-dd');
        setName( `AdsLogs - ${formattedDate}` );
    },[data])

    return (
        <div>
            <Grid container spacing={2}
                  sx={{
                      padding: 2,
                      backgroundColor: 'white',
                      borderRadius: '8px',
                      border: '4px',
                      paddingLeft: 0,
                      paddingTop: 0,
                      marginLeft: '-10px',
                      marginTop: '-5px',
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.08)',
                  }}
            >
                <Grid item xs={12} md={12} sx={{width: "100%"}}>
                    <Accordion sx={{width: "100%"}}>
                        <AccordionSummary
                            expandIcon={<InfoOutlinedIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                                backgroundColor: '#f0f3f5',
                                width: "100%",
                                height: '35px !important',
                                minHeight: '25px !important',
                            }}
                        >
                            Explanations
                        </AccordionSummary>
                        <AccordionDetails sx={{width: "100%"}}>
                            <Box>
                                <Typography variant="subtitle1" sx={{ marginBottom: '1rem' }}>
                                    📊 Report Options and Functionalities
                                </Typography>

                                <Typography variant="subtitle1" sx={{ marginBottom: '0.5rem' }}>
                                    📈 Data Selection:
                                </Typography>
                                <Typography variant="body1" sx={{ marginBottom: '1rem', marginLeft: '1rem' }}>
                                    Similar to data selection, you can also select specific statistics fields for the report by checking the checkboxes provided.
                                    The available statistics fields are also fetched dynamically.
                                    The selected statistics fields will be included in the generated report.
                                </Typography>

                                <Typography variant="subtitle1" sx={{ marginBottom: '0.5rem' }}>
                                    🔍 Filter Selection:
                                </Typography>
                                <Typography variant="body1" sx={{ marginBottom: '1rem', marginLeft: '1rem' }}>
                                    The form provides options to add filters to the report based on various criteria such as Users, Object type.
                                    You can toggle the visibility of each filter option by checking the corresponding checkboxes.
                                </Typography>

                                <Typography variant="subtitle1" sx={{ marginBottom: '0.5rem' }}>
                                    📅 Date Range Selection:
                                </Typography>
                                <Typography variant="body1" sx={{ marginBottom: '1rem', marginLeft: '1rem' }}>
                                    You can select a date range for the report by clicking on the "Date Range" dropdown.
                                    A calendar component will be displayed, allowing you to select the start and end dates for the report.
                                    The selected date range will be reflected in the displayed date range.
                                </Typography>

                                <Typography variant="subtitle1" sx={{ marginBottom: '0.5rem' }}>
                                    🚀 Generate Report:
                                </Typography>
                                <Typography variant="body1" sx={{ marginBottom: '1rem', marginLeft: '1rem' }}>
                                    Once you have made the desired selections and configurations, you can click the "Generate Report" button.
                                    The report generation process involves making API requests and fetching the required data based on the selected options.
                                </Typography>

                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>

            <Grid container spacing={2}
                  sx={{
                      padding:2,
                      backgroundColor: 'white',
                      borderRadius: '8px',
                      border: '4px',
                      paddingLeft:0,
                      marginLeft: '-10px',
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.08)',
                      marginTop: '20px',
                  }}
            >
                <Grid item xs={12} md={12} sx={{ width: "100%" }}>
                    <LogControlOptionsForm onGenerate={setData} />
                </Grid>

                <Grid item xs={12} md={12}>
                    {data.length != 0 && <DownloadButton
                        data={data}
                        label={name}
                        sx={{float: 'right',}}
                    />}
                </Grid>

                <Grid item xs={12} md={12}>
                    {data === false ? (
                        <Typography variant="h4" component="h2" align="center"><OutletIcon
                            fontSize="large"/> NO DATA </Typography>
                    ) : (
                        <DataTable data={data}/>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default AdsLogs;