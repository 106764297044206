import React, {useEffect, useState, useRef} from 'react';
import {DataGrid, GridToolbar, useGridApiRef} from '@mui/x-data-grid';
import {Button} from '@mui/material';
import DeleteWhiteListRowDialog from '../components/DeleteWhiteListRowDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import {styled} from '@mui/system';
import Cookies from 'js-cookie';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 300,
        },
    },
};

const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    '& .MuiDataGrid-row': {
        '&:nth-child(even)': {
            backgroundColor: '#eaf0f5',
        },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
    },
    '& .negative.profit, .negative.profit_percentage': {
        backgroundColor: 'rgba(255,235,235)',
    },
    '& .positive.profit, .positive.profit_percentage': {
        backgroundColor: 'rgba(235,255,235)',
    },
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal !important',
        wordWrap: 'break-word',
    },
    '& .total-row': {
        backgroundColor: '#f8fff9',
        fontWeight: 'bold',
    },
}));

function processData(data) {
    if (!data || !Array.isArray(data) || data.length === 0) return [];

    const processedData = data.map(row => ({
        id: row.value.id,
        ...row.value,
    }));

    if (processedData.length > 1) {
        processedData.forEach((row, index) => {
            if (index === processedData.length - 1) {
                row.total = true;
            }
        });
    }

    return processedData;
}

const BundleWhiteListDataGrid = ({data, onRowEdit}) => {
    const [rows, setRows] = useState(processData(data));
    const [openDelete, setOpenDelete] = useState(false);
    const [editRow, setEditRow] = useState({});
    const gridRef = useRef(null);

    useEffect(() => {
        setRows(processData(data));
    }, [data]);

    const bundle_id = rows.some(row => 'bundle_id' in row);
    const for_sh_id = rows.some(row => 'for_sh_id' in row);
    const for_irtb_id = rows.some(row => 'for_irtb_id' in row);
    const created_at = rows.some(row => 'created_at' in row);

    const userAdmin = Cookies.get('user_admin');

    let columns = [];

    columns.push({field: 'id', headerName: 'ID', width: 80});
    if (bundle_id) columns.push({field: 'bundle_id', headerName: 'Bundle ID', width: 600});
    if (for_sh_id) columns.push({field: 'for_sh_id', headerName: 'SmartHub ID', width: 100});
    if (for_irtb_id) columns.push({field: 'for_irtb_id', headerName: 'For company', width: 200});
    if (created_at) columns.push({field: 'created_at', headerName: 'Created at', width: 200});

    columns.push({
        field: 'action 3',
        headerName: '',
        width: 130,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
            const canEdit = userAdmin === '1';
            return (
                <Button
                    variant="outlined"
                    startIcon={<DeleteIcon/>}
                    color="error"
                    size="medium"
                    onClick={() => handleDeleteButtonClick(params.row)}
                    disabled={!canEdit}
                >
                    Delete
                </Button>
            );
        },
    });

    const handleDeleteButtonClick = (row) => {
        setEditRow(row);
        setOpenDelete(true);
    };
    const handleDeleteClose = () => {
        setOpenDelete(false);
    };
    const handleRowDelete = (deletedRowId) => {
        setRows(rows.filter(row => row.id !== deletedRowId));
    };

    if (data.length === 0) {
        return null;
    } else {

        return (

            <div style={{height: 800, width: '100%'}} ref={gridRef}>
                <StyledDataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={25}
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    disableRowSelectionOnClick
                />

                <DeleteWhiteListRowDialog
                    open={openDelete}
                    handleClose={handleDeleteClose}
                    editRow={editRow}
                    onDeleteSuccess={handleRowDelete}
                />
            </div>
        );
    }
};

export default BundleWhiteListDataGrid;
