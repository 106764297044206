import React from 'react';
import { FormControl, Autocomplete, TextField, Chip, Box } from '@mui/material';

function getNestedValue(obj, path) {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
}

function SelectMulti({ label, items = [], selectedItems, onChange, itemLabel, itemKey = 'value', itemValue = 'value', name }) {
    const handleChange = (event, newValue) => {
        const selectedValues = newValue.map(item => getNestedValue(item, itemValue));
        onChange({ target: { name, value: selectedValues } });
    };

    const selectedObjects = items && items.filter(item => selectedItems && selectedItems.map(String).includes(String(getNestedValue(item, itemValue))));

    return (
        <FormControl fullWidth>
            <Autocomplete
                multiple
                options={items}
                getOptionLabel={(item) => getNestedValue(item, itemLabel)}
                value={selectedObjects}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} label={label} />}
                renderTags={(selected, getTagProps) =>
                    selected.map((option, index) => (
                        <Chip key={getNestedValue(option, itemKey)} label={getNestedValue(option, itemLabel)} {...getTagProps({ index })} />
                    ))
                }
            />
        </FormControl>
    );
}

export default SelectMulti;



