import React from 'react';
import { Outlet } from 'react-router-dom';

type Props = {};

const BidsCubePageLayout = (props: Props) => {
    return (
        <><Outlet /></>
    );
};

export default BidsCubePageLayout;