import React, {useState, useEffect, useRef} from 'react';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import {
    Button,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails, FormControlLabel, Checkbox, CircularProgress, Box, Radio,
} from '@mui/material';
import DateRangeSelector from '../../../components/date/DateRangeSelector';
import StatisticsCheckbox from '../../../components/date/StatisticsCheckbox';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import SelectMulti from '../../../components/select/SelectMulti';
import {fetchDspSmartHub} from '../../../API/DatabaseReport';
import {fetchFields} from '../../../API/BlockedBundlesLogReport';
import useApi from '../../../hooks/useApi';
import Picker from 'react-month-picker'
import {toast} from 'react-toastify';
import Cookies from 'js-cookie';
import axios from "axios";

function BlockedBundlesLogOptions({onSubmit}) {
    const [selectedSmartHub, setSelectedSmartHub] = useState([]);
    const [showSmartHub, setShowSmartHub] = useState(false);

    const {data: smartHub, loading: loadingSmartHub} = useApi(fetchDspSmartHub);
    const {data: statistics, loading: loadingStats} = useApi(fetchFields);

    const today = new Date();
    const firstDayPrevMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    const lastDayPrevMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const [options, setOptions] = useState({
        start_date: firstDayPrevMonth,
        end_date: lastDayPrevMonth,
        stat: [],
        smarthub: [],
    });

    const [selectedRange, setSelectedRange] = useState({
        startDate: new Date(options.start_date),
        endDate: new Date(options.start_date),
        key: 'selection',
    });
    const handleDateChange = (event) => {
        const endDate = new Date(event.endDate);
        endDate.setHours(12, 12, 12, 12);
        const endDateISOString = endDate.toISOString().split('T')[0];

        const startDate = new Date(event.startDate);
        startDate.setHours(12, 12, 12, 12);
        const startDateISOString = startDate.toISOString().split('T')[0];

        setSelectedRange((prevSelectedRange) => ({
            ...prevSelectedRange,
            startDate: new Date(startDateISOString),
            endDate: new Date(endDateISOString),
            key: 'selection',
        }));

        if (endDate && startDate) {
            setOptions((prevOptions) => ({
                ...prevOptions,
                start_date: startDateISOString,
                end_date: endDateISOString,
            }));
        }
    };

    const [showCalendar, setShowCalendar] = useState(false);


    const dropDownRef = useRef(null);
    const pickerRef = useRef(null);

    const handleCheckboxChange = (event) => {
        const {name} = event.target;
        setOptions({...options, fields: name});
    };

    useEffect(() => {
        const storedValue = Cookies.get('BlockedBundlesLog');
        if (storedValue) {
            const parsedValue = JSON.parse(storedValue);
            setOptions(parsedValue);
        }

    }, []);

    const handleCheckboxChangeStat = (event) => {
        const {name, checked} = event.target;
        let stat = [...options.stat];

        if (checked) {
            stat.push(name);
        } else {
            stat = stat.filter((satat) => satat !== name);
        }

        setOptions({...options, stat});
    };

    const handleSubmit = async (event) => {
        onSubmit(options);
    };

    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            smarthub: selectedSmartHub,
        }));
    }, [selectedSmartHub]);


    return (
        <Grid container spacing={2} sx={{width: "100%", marginLeft: '0px',}}>
            <Accordion sx={{width: "100%"}}>
                <AccordionSummary
                    expandIcon={<KeyboardDoubleArrowDownOutlinedIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{backgroundColor: '#f0f3f5', width: "100%"}}
                >
                    Options
                </AccordionSummary>
                <AccordionDetails sx={{width: "100%"}}>
                    <form onSubmit={handleSubmit}>
                        <Grid item xs={12} md={12} sx={{width: "100%", margin: '0px 0 25px auto'}}>
                            <Grid item xs={12} md={12}>
                                {Object.keys(statistics).length != 0 ? (<strong>Data</strong>) : ''}
                            </Grid>
                            {statistics?.map((statistic) => (
                                <StatisticsCheckbox
                                    key={statistic.key}
                                    statistic={statistic}
                                    checked={options.stat.includes(statistic.key)}
                                    onChange={handleCheckboxChangeStat}
                                />
                            ))}
                        </Grid>

                        <Grid item xs={12} md={12} sx={{width: "100%", margin: '25px 0 25px auto'}}>
                            <Grid item xs={12} md={12}>
                                <strong>Filters</strong>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    {Object.keys(smartHub).length != 0 ? (<FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={showSmartHub}
                                                onChange={() => setShowSmartHub(!showSmartHub)}
                                                name="smartHub"
                                            />
                                        }
                                        label="SmartHub"
                                    />) : ''}
                                </Grid>

                                <Grid item xs={6}>
                                    {showSmartHub && (
                                        <SelectMulti
                                            label="SmartHub ID"
                                            items={smartHub}
                                            selectedItems={selectedSmartHub}
                                            onChange={(event) => setSelectedSmartHub(event.target.value)}
                                            itemLabel="value.name"
                                            itemKey="value.id"
                                            itemValue="value.id"
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </AccordionDetails>
            </Accordion>

            <Grid item xs={6} md={6}>
                <Button
                    size={"large"}
                    startIcon={<BackupTableOutlinedIcon fontSize="small"/>}
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{marginLeft: '-15px'}}
                >
                    Generate Report
                </Button>
            </Grid>

            <Grid item xs={6} md={6}>
                <DateRangeSelector
                    initialRange={{ startDate: firstDayPrevMonth, endDate: lastDayPrevMonth, key: 'selection' }}
                    onRangeChange={handleDateChange}
                />
            </Grid>
        </Grid>
    );
}

export default BlockedBundlesLogOptions;
