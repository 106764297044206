import React, {useEffect} from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const QpsTable = ({ data, index }) => {
    const date = new Date().toLocaleTimeString('en-GB', { hour12: false, timeZone: 'UTC' });
    console.log(data[index].value);
    console.log(index);
    return (
        <div>
            <div className="pre-main section">
                <h4>{data[index].value.region} | Check Time: <span>{date}</span> UTC+0</h4>
                <div>
                    <span>Incoming QPS:</span>
                    {data[index].value.limits?.incomingReal || 0} / {data[index].value.limits?.incomingStandart || 60000}
                </div>
                <div>
                    <span>Outgoing QPS:</span>
                    {data[index].value.limits?.outgoingReal || 0} / {data[index].value.limits?.outgoingStandart || 60000}
                </div>
            </div>

            <div className="main-one section">
                <h4>Top 10 SSP Endpoints sending too many QPS ({data[index].value.region})</h4>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>SSP Endpoint Name</TableCell>
                                <TableCell>QPS</TableCell>
                                <TableCell>Revenue</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data[index].value.ssp && Array.isArray(data[index].value.ssp) && data[index].value.ssp
                                .filter(ssp => ssp.qps >= 1000 && ssp.color === 'red')
                                .map((ssp, i) => (
                                    <TableRow key={i} sx={{backgroundColor: '#ffb4b1'}}>
                                        <TableCell>{i + 1}</TableCell>
                                        <TableCell>{ssp.name}</TableCell>
                                        <TableCell>{ssp.qps}</TableCell>
                                        <TableCell>{ssp.revenue}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}

export default QpsTable;