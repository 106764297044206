import React, {useState, useEffect, useRef} from 'react';
import axios from "axios";
import {
    Button,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails, FormControlLabel, Checkbox, CircularProgress, Box, Radio, Alert,
    AlertTitle,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Select,
    MenuItem, FormControl, InputLabel,
} from '@mui/material';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import {
    fetchINAPPStatisticFields, fetchINAPCreateData
} from '../../../API/AdsReport';
import {sendDataToApi} from '../../../API/apiHelper';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import Cookies from 'js-cookie';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { toast } from 'react-toastify';

function INAPPOptionsForm({onGenerate}) {

    const [options, setOptions] = useState({
        stats: [], // 'ssp_name', 'developer', 'bundle_id', 'ads_txt_url'
    });

    const [statistics, setStatistics] = useState([]);

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [openSend, setOpenSend] = useState(false);

    const [isNewFieldOpen, setNewFieldOpen] = useState(false);
    const [isNewFieldPlatformOpen, setNewFieldPlatformOpen] = useState(false);
    const [isNewFieldDeveloperOpen, setNewFieldDeveloperOpen] = useState(false);
    const [isNewFieldBundleOpen, setNewFieldBundleOpen] = useState(false);
    const [isNewFieldCategoryOpen, setNewFieldCategoryOpen] = useState(false);


    const handleNewFieldToggle = () => {
        setNewFieldOpen(!isNewFieldOpen);
    };

    const handleNewPlatformToggle = () => {
        setNewFieldPlatformOpen(!isNewFieldPlatformOpen);
    };

    const handleNewDeveloperToggle = () => {
        setNewFieldDeveloperOpen(!isNewFieldDeveloperOpen);
    };

    const handleNewBundleToggle = () => {
        setNewFieldBundleOpen(!isNewFieldBundleOpen);
    };

    const handleNewCategoryToggle = () => {
        setNewFieldCategoryOpen(!isNewFieldCategoryOpen);
    };

    const [data, setData] = useState({
        ssp: "",
        newSspId: "",
        newSspName: "",
        platform: [],
        newPlatform: "",
        developer: "",
        newDeveloper: "",
        bundle: "",
        newBundle: "",
        relationship: "",
        category: "",
        newCategory: "",
        appName: "",
        appUrl: "",
        adsTxt: ""
    });

    const [fieldsData, setFieldsData] = useState({
        ssps: [],
        platforms: [],
        developers: [],
        bundles: [],
        categories: [],
    });

    const handleOpen = () => setOpen(true);

    const handleChange = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    }

    useEffect(() => {
        const fetchStatistics = async () => {
            const stats = await fetchINAPPStatisticFields();
            setStatistics(stats);

        };
        const fetchCreateData = async () => {
            const createData = await fetchINAPCreateData();
            setFieldsData(prevFieldsData => ({
                ...prevFieldsData,
                ssps: createData.find(item => item.key === 'ssps').value,
                platforms: createData.find(item => item.key === 'platforms').value,
                developers: createData.find(item => item.key === 'developers').value,
                bundles: createData.find(item => item.key === 'bundles').value,
                categories: createData.find(item => item.key === 'categories').value,
            }));
        };

        fetchCreateData();
        fetchStatistics();
    }, []);

    useEffect(() => {
        const storedValue = Cookies.get('INAPPads');
        if (storedValue) {
            const parsedValue = JSON.parse(storedValue);

            if ('stats' in parsedValue) {
                setOptions(prevOptions => ({
                    ...prevOptions,
                    stats: parsedValue.stats
                }));
            }
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            setLoading(true);
            const response = await sendDataToApi('post','/api/report-ads-txt/inapp/report', options, 'INAPPads');
            if (Object.keys(response.data.data).length === 0) {
                onGenerate(false);
            } else {
                const fieldsArray = Object.entries(response.data.data).map(([key, value]) => ({key, value}));
                onGenerate(fieldsArray);
            }
            setLoading(false);
        } catch (err) {
            toast.error(`Oops, there seems to be an error on the server. The technical team has already received information about this. We apologize for the inconvenience and we will let you know when it is fixed.`);
            setLoading(false);
            console.error(err);
            return [];
        }
    };


    const handleCheckboxChange = (event) => {
        const {name} = event.target;
        setOptions({...options, fields: name});
    };


    const handleCheckboxChangeStat = (event) => {
        const {name, checked} = event.target;
        let stats = [...options.stats];

        if (checked) {
            stats.push(name);
        } else {
            stats = stats.filter((satat) => satat !== name);
        }

        setOptions({...options, stats});
    };

    const handleSave = () => {
        const response = sendDataToApi('post','/api/report-ads-txt/inapp/entitycreate', data, false, (data) => {
            setOpen(false);
            openSendSuccess();
        });
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const openSendSuccess = () => {
        setOpenSend(true);
    };

    const handleCloseSend = () => {
        window.location.reload();
        setOpenSend(false);
    };

    return (
        <Grid container spacing={2} sx={{width: "100%", marginLeft: '0px',}}>
            <Accordion sx={{width: "100%"}}>
                <AccordionSummary
                    expandIcon={<KeyboardDoubleArrowDownOutlinedIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{backgroundColor: '#f0f3f5', width: "100%"}}
                >
                    Options
                </AccordionSummary>
                <AccordionDetails sx={{width: "100%"}}>
                    <form onSubmit={handleSubmit}>
                        <Grid item xs={12} md={12} sx={{width: "100%"}}>
                            <Grid item xs={12} md={12} sx={{width: "100%", margin: '25px 0 25px auto'}}>

                                <Grid item xs={12} md={12}>
                                    {Object.keys(statistics).length != 0 ? (<strong>Statistics</strong>) : ''}
                                </Grid>
                                {statistics.map((statistic, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                                checked={options.stats.includes(statistic.key)}
                                                onChange={handleCheckboxChangeStat}
                                                name={statistic.key}
                                            />
                                        }
                                        label={statistic.value}
                                    />
                                ))}

                            </Grid>
                        </Grid>
                    </form>
                </AccordionDetails>
            </Accordion>

            <Grid item xs={6} md={6}>
                <Button
                    size={"large"}
                    startIcon={<BackupTableOutlinedIcon fontSize="small"/>}
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{marginLeft: '-15px'}}
                >
                    Generate Report
                </Button>

                <Button
                    size={"large"}
                    startIcon={<PlaylistAddIcon fontSize="small"/>}
                    onClick={handleClickOpen}
                    type="submit"
                    variant="contained"
                    color="success"
                    sx={{marginLeft: '15px'}}
                >
                    Create row
                </Button>
            </Grid>
            {loading &&
                <Grid item xs={12} md={12}>
                    <Box justifyContent="center" textAlign={"center"} alignItems="center">
                        <CircularProgress size={200}/>
                    </Box>
                </Grid>}

            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogTitle>Edit row</DialogTitle>
                <DialogContent>
                    <Box mb={2}>
                        <FormControl fullWidth>
                            <InputLabel id="ssp-label">SSP</InputLabel>
                            <Select
                                labelId="ssp-label"
                                name="ssp"
                                value={data.ssp}
                                onChange={handleChange}
                                required
                            >
                                <MenuItem value="0">---</MenuItem>
                                {fieldsData.ssps.map((ssp) => (
                                    <MenuItem value={ssp.id}>{ssp.ssp_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mb={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isNewFieldOpen}
                                    onChange={handleNewFieldToggle}
                                    color="primary"
                                />
                            }
                            label="Add new SSP"
                        />
                    </Box>
                    {isNewFieldOpen && (
                        <>
                            <Box mb={2}>
                                <TextField
                                    name="newSspId"
                                    value={data.newSspId}
                                    onChange={handleChange}
                                    label="New SSP ID"
                                    fullWidth
                                />
                            </Box>
                            <Box mb={2}>
                                <TextField
                                    name="newSspName"
                                    value={data.newSspName}
                                    onChange={handleChange}
                                    label="New SSP Name"
                                    fullWidth
                                />
                            </Box>
                        </>
                    )}
                    <Box mb={2}>
                        <FormControl fullWidth>
                            <InputLabel id="platform-label">Platform</InputLabel>
                            <Select
                                labelId="platform-label"
                                name="platform"
                                multiple
                                value={data.platform}
                                onChange={handleChange}
                                renderValue={(selected) => (
                                    selected.map((value) => fieldsData.platforms.find((platform) => platform.id === value)).join(', ')
                                )}
                                required
                            >
                                {fieldsData.platforms.map((platform) => (
                                    <MenuItem value={platform.id}>{platform.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mb={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isNewFieldPlatformOpen}
                                    onChange={handleNewPlatformToggle}
                                    color="primary"
                                />
                            }
                            label="Add new Platform"
                        />
                    </Box>
                    {isNewFieldPlatformOpen && (
                        <>
                            <Box mb={2}>
                                <TextField
                                    name="newPlatform"
                                    value={data.newPlatform}
                                    onChange={handleChange}
                                    label="New Platform"
                                    fullWidth
                                />
                            </Box>
                        </>
                    )}
                    <Box mb={2}>
                        <FormControl fullWidth>
                            <InputLabel id="relationship-label">Direct/Reseller</InputLabel>
                            <Select
                                labelId="relationship-label"
                                name="relationship"
                                value={data.relationship}
                                onChange={handleChange}
                                required
                            >
                                <MenuItem value="DIRECT">DIRECT</MenuItem>
                                <MenuItem value="RESELLER">RESELLER</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mb={2}>
                        <FormControl fullWidth>
                            <InputLabel id="developer-label">Developer</InputLabel>
                            <Select
                                labelId="developer-label"
                                name="developer"
                                value={data.developer}
                                onChange={handleChange}
                                required
                            >
                                <MenuItem value="0">---</MenuItem>
                                {fieldsData.developers.map((developer) => (
                                    <MenuItem value={developer.id}>{developer.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mb={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isNewFieldDeveloperOpen}
                                    onChange={handleNewDeveloperToggle}
                                    color="primary"
                                />
                            }
                            label="Add new Developer"
                        />
                    </Box>
                    {isNewFieldDeveloperOpen && (
                        <>
                            <Box mb={2}>
                                <TextField
                                    name="newDeveloper"
                                    value={data.newDeveloper}
                                    onChange={handleChange}
                                    label="New Developer"
                                    fullWidth
                                />
                            </Box>
                        </>
                    )}
                    <Box mb={2}>
                        <FormControl fullWidth>
                            <InputLabel id="bundle-label">Bundle</InputLabel>
                            <Select
                                labelId="bundle-label"
                                name="bundle"
                                value={data.bundle_id}
                                onChange={handleChange}
                                required
                            >
                                <MenuItem value="0">---</MenuItem>
                                {fieldsData.bundles.map((bundle) => (
                                    <MenuItem value={bundle.id}>{bundle.bundle_id}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mb={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isNewFieldBundleOpen}
                                    onChange={handleNewBundleToggle}
                                    color="primary"
                                />
                            }
                            label="Add new Bundle"
                        />
                    </Box>
                    {isNewFieldBundleOpen && (
                        <>
                            <Box mb={2}>
                                <TextField
                                    name="newBundle"
                                    value={data.newBundle}
                                    onChange={handleChange}
                                    label="New Bundle"
                                    fullWidth
                                />
                            </Box>
                        </>
                    )}
                    <Box mb={2}>
                        <FormControl fullWidth>
                            <InputLabel id="category-label">Category</InputLabel>
                            <Select
                                labelId="category-label"
                                name="category"
                                value={data.category}
                                onChange={handleChange}
                                required
                            >
                                <MenuItem value="0">---</MenuItem>
                                {fieldsData.categories.map((category) => (
                                    <MenuItem value={category.id}>{category.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box mb={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isNewFieldCategoryOpen}
                                    onChange={handleNewCategoryToggle}
                                    color="primary"
                                />
                            }
                            label="Add new Category"
                        />
                    </Box>
                    {isNewFieldCategoryOpen && (
                        <>
                            <Box mb={2}>
                                <TextField
                                    name="newCategory"
                                    value={data.newCategory}
                                    onChange={handleChange}
                                    label="New Category"
                                    fullWidth
                                />
                            </Box>
                        </>
                    )}
                    <Box mb={2}>
                        <TextField
                            name="appName"
                            value={data.appName}
                            onChange={handleChange}
                            label="App Name"
                            fullWidth
                            required
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            name="appUrl"
                            value={data.appUrl}
                            onChange={handleChange}
                            label="App URL"
                            fullWidth
                            required
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            name="adsTxt"
                            value={data.adsTxt}
                            onChange={handleChange}
                            label="App-ads.txt URL"
                            fullWidth
                            required
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSave}>Save</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openSend} onClose={handleCloseSend}>
                <Alert severity="success">
                    <AlertTitle>Success!</AlertTitle>
                    Entity <strong>сreated</strong> successfully!
                </Alert>
            </Dialog>

        </Grid>
    );
}

export default INAPPOptionsForm;
