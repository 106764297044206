import React, {useState} from 'react';
import axios from "axios";
import {
    Button,
    Grid,
    CircularProgress,
    Box,
} from '@mui/material';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {sendDataToApi} from '../../../API/apiHelper';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
function QPSOptionsForm({ onGenerate }) {

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);

            const cacheKey = 'apiData';

            const cachedData = localStorage.getItem(cacheKey);
            if (cachedData) {
                const data = JSON.parse(cachedData);
                setLoading(false);
                onGenerate(data);
                return data;
            }else {
                const response = await sendDataToApi('post','/api/smarthub/qps/report');
                if (Object.keys(response.data).length === 0) {
                    onGenerate(false);
                } else {
                    const fieldsArray = Object.entries(response.data).map(([key, value]) => ({ key, value }));
                    onGenerate(fieldsArray);
                }
                setLoading(false);
            }
        } catch (err) {
            toast.error(`Oops, there seems to be an error on the server. The technical team has already received information about this. We apologize for the inconvenience and we will let you know when it is fixed.`);
            console.error(err);
            setLoading(false);
        }
    };

    return (
        <Grid container spacing={2} sx={{ width: "100%" }}>
            <Grid item xs={12} md={12}>
                <Box justifyContent="center" textAlign={"center"} alignItems="center">
                    <Button
                        size={"large"}
                        startIcon={<BackupTableOutlinedIcon fontSize="small"/>}
                        onClick={handleSubmit}
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        Generate Report
                    </Button>
                </Box>
            </Grid>
            {loading &&
                <Grid item xs={12} md={12}>
                    <Box justifyContent="center" textAlign={"center"} alignItems="center">
                        <CircularProgress size={200}/>
                    </Box>
                </Grid>}
        </Grid>
    );
}

export default QPSOptionsForm;
