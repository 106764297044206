import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';

const PrivateRoute = ({ children }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const jwtToken = Cookies.get('jwt');

        if (!jwtToken) {
            navigate('/login');
            return;
        }

        try {

            const decodedToken = jwtDecode(jwtToken);

            //console.log(decodedToken);
            const secretKey = '5Tr8Ar1rkxU1bJ7xifM6u2nv8KquWUSw7IcdviLCpzLuL3iTDixsGEATdnJLNlGb';
            //const decodedToken = jwt.verify(jwtToken, secretKey);

            const currentTime = Math.floor(Date.now() / 1000);
            if (decodedToken.exp < currentTime) {
                //console.log('Token has expired');
                navigate('/login');
                return;
            }

            //console.log('Token is valid:', decodedToken);
        } catch (error) {
            toast.error(`Error: ${error}`);
            //console.error('Invalid token:', error);
            navigate('/login');
        }

    }, [navigate]);

    return children;
};

export default PrivateRoute;