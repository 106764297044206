import React, {useState} from 'react';
import {
    Button,
    Grid,
    CircularProgress,
    Box,
    InputLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Select,
    MenuItem,
    FormControl,
} from '@mui/material';
import { toast } from 'react-toastify';
import 'react-date-range/dist/styles.css';
import useApi from '../../../hooks/useApi';
import 'react-date-range/dist/theme/default.css';
import {sendDataToApi} from '../../../API/apiHelper';
import {fetchDspSmartHub} from '../../../API/DatabaseReport';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';

function CridsWhiteListOptionsForm({ onSubmit }) {
    const {data: smartHub, loading: loadingSmartHub} = useApi(fetchDspSmartHub);
    const [loading, setLoading] = useState(false);
    const [openEditBundle, setOpenEditBundle] = useState(false);

    const [options, setOptions] = useState({
        stats: [],
    });

    const [editBundle, setEditBundle] = useState([]);

    const handleSubmit = async (event) => {
        onSubmit(options);
    };

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setEditBundle({...editBundle, [name]: value});
    };

    const handleSaveBundle = () => {
        const userId = localStorage.getItem('user_id');

        const updatedBundle = {
            ...editBundle,
            ChangedBy: userId
        };

        const response = sendDataToApi('post', '/api/smarthub/cridswhitelist/create', updatedBundle, false, (data) => {
            if(data.data.success === true){
                toast.success('The bundle has been created!');
                handleSubmit();
                setEditBundle({...editBundle, ['name']: '', ['smarthub_id']: ''});
                handleCloseEditBundle();
            }else{
                toast.error(`The bundle has not created!`);
                toast.error(data.data.error);
            }
        });

    };

    const handleOpenEditBundle = () => {
        setOpenEditBundle(true);
    };

    const handleCloseEditBundle = () => {
        setOpenEditBundle(false);
    };

    return (
        <Grid container spacing={2} sx={{ width: "100%", marginLeft: '0px', }}>
            <Grid item xs={6} md={6}>
                <Button
                    size={"large"}
                    startIcon={<BackupTableOutlinedIcon fontSize="small"  />}
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ marginLeft: '-15px', marginBottom: '15px' }}
                >
                    Generate Report
                </Button>
                <Button
                    size={"large"}
                    startIcon={<PlaylistAddIcon fontSize="small"/>}
                    onClick={handleOpenEditBundle}
                    type="submit"
                    variant="contained"
                    color="success"
                    sx={{ marginLeft: '15px', marginBottom: '15px' }}
                >
                    Create
                </Button>
            </Grid>
            {loading &&
                <Grid item xs={12} md={12}>
                    <Box justifyContent="center" textAlign={"center"} alignItems="center">
                        <CircularProgress size={200}/>
                    </Box>
                </Grid>}

            <Dialog open={openEditBundle} onClose={handleCloseEditBundle}>
                <DialogTitle>Create a white list for a company</DialogTitle>
                <DialogContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '450px',
                        justifyContent: 'space-between',
                        '& > :first-child': {
                            marginTop: '10px',
                            marginBottom: '10px',
                        },
                        '& > :last-child': {
                            marginBottom: '10px',
                        },
                    }}
                >
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="smarthub-label">For Company</InputLabel>
                        <Select
                            labelId="smarthub-label"
                            id="smarthub_id"
                            name="smarthub_id"
                            value={editBundle.smarthub_id}
                            required
                            onChange={handleInputChange}
                            label="Company"
                        >
                            {smartHub.map((hub) => (
                                <MenuItem key={hub.value.id} value={hub.value.id}>
                                    {hub.value.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditBundle}>Cancel</Button>
                    <Button onClick={handleSaveBundle}>Save</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

export default CridsWhiteListOptionsForm;
