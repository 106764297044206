import React, {useEffect, useState, useLayoutEffect, useRef} from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    List,
    ListItem,
    ListItemText
} from '@mui/material';
import {DataGrid, GridToolbar, useGridApiRef} from '@mui/x-data-grid';
import {styled} from '@mui/system';

const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    '& .MuiDataGrid-row': {
        '&:nth-child(even)': {
            backgroundColor: '#eaf0f5',
        },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
    },
    '& .negative.win_rate_per, .negative.revenue_per, .negative.fill_rate_per, .negative.profit_per': {
        backgroundColor: 'rgba(255,235,235)',
    },
    '& .positive.win_rate_per, .positive.revenue_per, .positive.fill_rate_per, .positive.profit_per': {
        backgroundColor: 'rgba(235,255,235)',
    },
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal !important',
        wordWrap: 'break-word',
    },
    '& .total-row': {
        backgroundColor: 'lightgrey',
        fontWeight: 'bold',
    },
}));

function LogsTable({data}) {
    const gridRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState([]);

    if (!data || data.length === 0) return null;
    const columnNames = Object.keys(data[0].value);


    let rows = [];

    data.forEach((element, index) => {
        let row = {id: index};

        for (let key in element.value) {
            let value = element.value[key];

            if (!isNaN(parseFloat(value)) && isFinite(value)) {
                value = parseFloat(value);
            }

            row[key] = value;
        }

        rows.push(row);
    });

    const formatJsonDisplay = (obj, level = 0) => {
        const indentBase = '  ';
        const indent = indentBase.repeat(level);
        const childIndent = indentBase.repeat(level + 1);

        if (Array.isArray(obj)) {
            const arrayContent = obj.map(item => formatJsonDisplay(item, level + 1)).join(',\n');
            return `[\n${arrayContent}\n${indent}]`;
        } else if (typeof obj === 'object' && obj !== null) {
            const objectContent = Object.entries(obj).map(([key, value]) => {
                const formattedValue = formatJsonDisplay(value, level + 1);
                return `${childIndent}${key}: ${formattedValue}`;
            }).join(',\n');
            return `{\n${objectContent}\n${indent}}`;
        } else {
            return JSON.stringify(obj);
        }
    };

    const handleClickOpen = (rowData) => {
        let parsedData;
        try {
            parsedData = JSON.parse(rowData);
        } catch (error) {
            console.error("Error parsing JSON:", error);
            parsedData = {};
        }
        const formattedData = formatJsonDisplay(parsedData);
        setDialogContent(formattedData);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const columns = columnNames.map((col) => {
        let config = {
            field: col,
            headerName: col.charAt(0).toUpperCase() + col.slice(1).replace(/_/g, ' ').replace(/per/g, '%'),
            flex: 1,
            minWidth: 50,

            renderCell: (params) => {
                if (col === 'object' && params.row[col] !== null) {
                    return (
                        <Button variant="outlined" onClick={() => handleClickOpen(params.row[col])}>
                            Object
                        </Button>
                    );
                }
                return params.value;
            },
        };

        if (/id/.test(col)) {
            config.maxWidth = 85;
        }

        if (['user_email'].includes(col)) {
            config.minWidth = 200;

            config.cellClassName = (params) => {
                return 'user_email';
            };
        }

        if (['ip'].includes(col)) {
            config.minWidth = 140;

            config.cellClassName = (params) => {
                return 'user_email';
            };
        }

        return config;
    });

    return (
        <div style={{height: 800, width: '100%'}} ref={gridRef}>
            <StyledDataGrid
                getRowHeight={() => 'auto'}
                rows={rows}
                columns={columns}
                pageSize={25}
                rowsPerPageOptions={[5]}
                slots={{
                    toolbar: GridToolbar,
                }}
                disableRowSelectionOnClick
            />


            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle>Object Details</DialogTitle>
                <DialogContent dividers>
                    <pre style={{whiteSpace: 'pre-wrap', wordWrap: 'break-word'}}>
                        {dialogContent}
                    </pre>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


export default LogsTable;
