import {fetchDataFromApi, fetchStringFromApi} from './apiHelper';

export const fetchStatisticFieldsTotalRevenue = () => fetchDataFromApi('post', '/api/financialtools/totalrevenue/statistics');
export const fetchTotalRevenueReportData = () => fetchDataFromApi('post', '/api/financialtools/totalrevenue/report');
export const fetchDataFieldsTotalRevenue = () => fetchDataFromApi('post', '/api/financialtools/totalrevenue/data');
export const getCsvTemplate = () => fetchStringFromApi('post', '/api/financialtools/totalrevenue/template');
export const fetchDspCompanies = () => fetchDataFromApi('post', '/api/financialtools/totalrevenue/dsp');
export const fetchSspCompanies = () => fetchDataFromApi('post', '/api/financialtools/totalpayout/ssp');

export const fetchStatisticFieldsTotalPayout = () => fetchDataFromApi('post', '/api/financialtools/totalpayout/statistics');
export const fetchTotalPayoutReportData = () => fetchDataFromApi('post', '/api/financialtools/totalpayout/report');
export const fetchDataFieldsTotalPayout = () => fetchDataFromApi('post', '/api/financialtools/totalpayout/data');
export const getPayoutCsvTemplate = () => fetchStringFromApi('post', '/api/financialtools/totalpayout/template');
