import React, {useState} from 'react';
import {Box, Button, createTheme, ThemeProvider} from "@mui/material";
import assets from "../../../assets";

const WelcomePage = () => {

    const theme = createTheme({
        palette: {
            primary: {
                main: '#223d57',
            },
        },
    });

    const [isHovered, setIsHovered] = useState(false);

    const logoStyles = {
        // width: '30%',
        // height: '15%',
        width: '600px',
        height: '150px',
        transition: 'all 0.3s',
        justifyContent: "center",
    };

    const logoHoverStyles = {
        ...logoStyles,
        transition: 'all 0.3s',
        transform: 'scale(1.2)',
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
        console.log(isHovered);

    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        console.log(isHovered);
    };

    const imageStyles = isHovered ? logoHoverStyles : logoStyles;

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Box
                component="nav"
                sx={{
                    width: '100%',
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "20px",
                }}
            >
                <ThemeProvider theme={theme}>
                    <Button sx={{ margin: "20px", marginLeft: "auto"  }} color="primary" href="/login" variant="outlined">LOGIN</Button>
                </ThemeProvider>
            </Box>

            <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                <a href="/login">
                    <img
                        style={imageStyles}
                        src={assets.images.logo}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    />
                </a>
            </Box>
        </Box>
);
};

export default WelcomePage;