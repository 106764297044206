import React, {useState, useEffect, useRef} from 'react';
import axios from "axios";
import {
    Button,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControlLabel,
    Checkbox,
    CircularProgress,
    Box,
    Radio,
    Dialog,
    DialogTitle,
    DialogContent,
    FormControl, InputLabel, Select, MenuItem, TextField, DialogActions, Alert, AlertTitle,
} from '@mui/material';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import {
    fetchEntyStatisticFields, fetchEntriesCreateData
} from '../../../API/AdsReport';
import {sendDataToApi} from '../../../API/apiHelper';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import Cookies from 'js-cookie';
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import {toast} from 'react-toastify';

function EntriesOptionsForm({onGenerate}) {

    const [options, setOptions] = useState({
        stats: [],
    });

    const [statistics, setStatistics] = useState([]);

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [openSend, setOpenSend] = useState(false);

    const [isNewFieldOpen, setNewFieldOpen] = useState(false);
    const [isNewFieldPlatformOpen, setNewFieldPlatformOpen] = useState(false);
    const [isNewFieldDeveloperOpen, setNewFieldDeveloperOpen] = useState(false);
    const [isNewFieldBundleOpen, setNewFieldBundleOpen] = useState(false);
    const [isNewFieldCategoryOpen, setNewFieldCategoryOpen] = useState(false);

    const handleNewFieldToggle = () => {
        setNewFieldOpen(!isNewFieldOpen);
    };

    const handleNewPlatformToggle = () => {
        setNewFieldPlatformOpen(!isNewFieldPlatformOpen);
    };

    const handleNewDeveloperToggle = () => {
        setNewFieldDeveloperOpen(!isNewFieldDeveloperOpen);
    };

    const handleNewBundleToggle = () => {
        setNewFieldBundleOpen(!isNewFieldBundleOpen);
    };

    const handleNewCategoryToggle = () => {
        setNewFieldCategoryOpen(!isNewFieldCategoryOpen);
    };

    const [data, setData] = useState({
        adType: "",
        dsp: "",
        newDspId: "",
        newDspName: "",
        sourceWww: "",
        publisherId: "",
        relationship: "",
        sourceId: "",
        addField: "",
        comment: "",
    });

    const [fieldsData, setFieldsData] = useState({
        dsp: [],
    });

    const handleOpen = () => setOpen(true);

    const handleChange = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    }

    useEffect(() => {
        const fetchStatistics = async () => {
            const stats = await fetchEntyStatisticFields();
            setStatistics(stats);

        };

        const fetchCreateData = async () => {
            const fieldsArray = await fetchEntriesCreateData();

            setFieldsData(prevFieldsData => ({
                ...prevFieldsData,
                dsp: fieldsArray.find(item => item.key === 'dsps').value,
            }));
        };

        fetchCreateData();
        fetchStatistics();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            const response = await sendDataToApi('post', '/api/report-ads-txt/entries/report', options, 'Entriesads');
            if (Object.keys(response.data.data).length === 0) {
                onGenerate(false);
            } else {
                const fieldsArray = Object.entries(response.data.data).map(([key, value]) => ({key, value}));
                onGenerate(fieldsArray);
            }
            setLoading(false);
        } catch (err) {
            toast.error(`Oops, there seems to be an error on the server. The technical team has already received information about this. We apologize for the inconvenience and we will let you know when it is fixed.`);
            setLoading(false);
            console.error(err);
            return [];
        }
    };

    const handleCheckboxChange = (event) => {
        const {name} = event.target;
        setOptions({...options, fields: name});
    };


    const handleCheckboxChangeStat = (event) => {
        const {name, checked} = event.target;
        let stats = [...options.stats];

        if (checked) {
            stats.push(name);
        } else {
            stats = stats.filter((satat) => satat !== name);
        }

        setOptions({...options, stats});
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const openSendSuccess = () => {
        setOpenSend(true);
    };

    const handleCloseSend = () => {
        window.location.reload();
        setOpenSend(false);
    };

    useEffect(() => {
        const storedValue = Cookies.get('Entriesads');
        if (storedValue) {
            const parsedValue = JSON.parse(storedValue);

            if ('stats' in parsedValue) {
                setOptions(prevOptions => ({
                    ...prevOptions,
                    stats: parsedValue.stats
                }));
            }
        }
    }, []);

    const handleSave = () => {
        const response = sendDataToApi('post','/api/report-ads-txt/entries/entitycreate', data, false, (data) => {
            setOpen(false);
            openSendSuccess();
        });
    };

    return (
        <Grid container spacing={2} sx={{width: "100%", marginLeft: '0px',}}>
            <Accordion sx={{width: "100%"}}>
                <AccordionSummary
                    expandIcon={<KeyboardDoubleArrowDownOutlinedIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{backgroundColor: '#f0f3f5', width: "100%"}}
                >
                    Options
                </AccordionSummary>
                <AccordionDetails sx={{width: "100%"}}>
                    <form onSubmit={handleSubmit}>
                        <Grid item xs={12} md={12} sx={{width: "100%"}}>
                            <Grid item xs={12} md={12} sx={{width: "100%", margin: '25px 0 25px auto'}}>

                                <Grid item xs={12} md={12}>
                                    {Object.keys(statistics).length != 0 ? (<strong>Statistics</strong>) : ''}
                                </Grid>
                                {statistics.map((statistic, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                                checked={options.stats.includes(statistic.key)}
                                                onChange={handleCheckboxChangeStat}
                                                name={statistic.key}
                                            />
                                        }
                                        label={statistic.value}
                                    />
                                ))}

                            </Grid>
                        </Grid>
                    </form>
                </AccordionDetails>
            </Accordion>

            <Grid item xs={6} md={6}>
                <Button
                    size={"large"}
                    startIcon={<BackupTableOutlinedIcon fontSize="small"/>}
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{marginLeft: '-15px'}}
                >
                    Generate Report
                </Button>

                <Button
                    size={"large"}
                    startIcon={<PlaylistAddIcon fontSize="small"/>}
                    onClick={handleClickOpen}
                    type="submit"
                    variant="contained"
                    color="success"
                    sx={{marginLeft: '15px'}}
                >
                    Create row
                </Button>
            </Grid>
            {loading &&
                <Grid item xs={12} md={12}>
                    <Box justifyContent="center" textAlign={"center"} alignItems="center">
                        <CircularProgress size={200}/>
                    </Box>
                </Grid>}

            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogTitle>Edit row</DialogTitle>
                <DialogContent>
                    <Box mb={2}>
                        <FormControl fullWidth>
                            <InputLabel id="adType-label">INAPP/CTV</InputLabel>
                            <Select
                                labelId="adType-label"
                                name="adType"
                                value={data.adType}
                                onChange={handleChange}
                                required
                            >
                                <MenuItem value="INAPP">INAPP</MenuItem>
                                <MenuItem value="CTV">CTV</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mb={2}>
                        <FormControl fullWidth>
                            <InputLabel id="dsp-label">DSP</InputLabel>
                            <Select
                                labelId="dsp-label"
                                name="dsp"
                                value={data.dsp}
                                onChange={handleChange}
                                required
                            >
                                {fieldsData.dsp.map((dsps) => (
                                    <MenuItem value={dsps.id}>{dsps.dsp_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mb={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isNewFieldOpen}
                                    onChange={handleNewFieldToggle}
                                    color="primary"
                                />
                            }
                            label="Add new DSP"
                        />
                    </Box>
                    {isNewFieldOpen && (
                        <>
                            <Box mb={2}>
                                <TextField
                                    name="newDspId"
                                    value={data.newDspId}
                                    onChange={handleChange}
                                    label="New DSP ID"
                                    fullWidth
                                />
                            </Box>
                            <Box mb={2}>
                                <TextField
                                    name="newDspName"
                                    value={data.newDspName}
                                    onChange={handleChange}
                                    label="New DSP Name"
                                    fullWidth
                                />
                            </Box>
                        </>
                    )}

                    <Box mb={2}>
                        <TextField
                            name="sourceWww"
                            value={data.sourceWww}
                            onChange={handleChange}
                            label="Source WWW"
                            fullWidth
                            required
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            name="publisherId"
                            value={data.publisherId}
                            onChange={handleChange}
                            label="Publisher ID"
                            fullWidth
                            required
                        />
                    </Box>
                    <Box mb={2}>
                        <FormControl fullWidth>
                            <InputLabel id="relationship-label">Direct/Reseller</InputLabel>
                            <Select
                                labelId="relationship-label"
                                name="relationship"
                                value={data.relationship}
                                onChange={handleChange}
                                required
                            >
                                <MenuItem value="DIRECT">DIRECT</MenuItem>
                                <MenuItem value="RESELLER">RESELLER</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mb={2}>
                        <TextField
                            name="sourceId"
                            value={data.sourceId}
                            onChange={handleChange}
                            label="Source ID"
                            fullWidth
                            required
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            name="addField"
                            value={data.addField}
                            onChange={handleChange}
                            label="Add Field"
                            fullWidth
                            required
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            name="comment"
                            value={data.comment}
                            onChange={handleChange}
                            label="Comment"
                            fullWidth
                            required
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSave}>Save</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openSend} onClose={handleCloseSend}>
                <Alert severity="success">
                    <AlertTitle>Success!</AlertTitle>
                    Entity <strong>сreated</strong> successfully!
                </Alert>
            </Dialog>
        </Grid>
    );
}

export default EntriesOptionsForm;
