import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Alert,
    AlertTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress
} from '@mui/material';

function CreateCompanyDialog({
                                 open,
                                 handleClose,
                                 editCompany,
                                 handleInputCreateChange,
                                 handleSave,
                                 smartHub,
                                 lists,
                             }) {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Create Company</DialogTitle>
            <DialogContent>
                <Alert variant="outlined" severity="info" color="info" sx={{marginBottom: "20px"}}>
                    Please complete this form to create a new company profile<br/>
                </Alert>

                <FormControl fullWidth margin="dense">
                    <InputLabel id="smarthub-label">Company</InputLabel>
                    <Select
                        labelId="smarthub-label"
                        id="smarthub_id"
                        name="smarthub_id"
                        value={editCompany.smarthub_id}
                        required
                        onChange={handleInputCreateChange}
                        label="Company"
                    >
                        {smartHub.map((hub) => (
                            <MenuItem key={hub.value.id} value={hub.value.id}>
                                {hub.value.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {lists.length > 0 && (
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="list-label">Filter List</InputLabel>
                        <Select
                            labelId="list-label"
                            id="list"
                            name="list"
                            value={editCompany.list}
                            required
                            onChange={handleInputCreateChange}
                            label="Filter List"
                        >
                            {lists.map((list) => (
                                <MenuItem key={list.value.id} value={list.value.id}>
                                    {list.value.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateCompanyDialog;