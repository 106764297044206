import React, {useEffect, useState, useRef} from 'react';
import {DataGrid, GridToolbar, useGridApiRef} from '@mui/x-data-grid';
import {
    Checkbox,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Alert,
    AlertTitle,
    FormControlLabel,
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    OutlinedInput,
    Box,
} from '@mui/material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import useApi from '../../../hooks/useApi';
import {styled} from '@mui/system';
import Cookies from 'js-cookie';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 300,
        },
    },
};

const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    '& .MuiDataGrid-row': {
        '&:nth-child(even)': {
            backgroundColor: '#eaf0f5',
        },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
    },
    '& .negative.profit, .negative.profit_percentage': {
        backgroundColor: 'rgba(255,235,235)',
    },
    '& .positive.profit, .positive.profit_percentage': {
        backgroundColor: 'rgba(235,255,235)',
    },
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal !important',
        wordWrap: 'break-word',
    },
    '& .total-row': {
        backgroundColor: '#f8fff9',
        fontWeight: 'bold',
    },
}));

function processData(data) {
    if (!data || !Array.isArray(data) || data.length === 0) return [];

    const processedData = data.map(row => ({
        id: row.value.id,
        ...row.value,
        blockedOriginal: row.value.is_blocked === 1 ? 'True' : 'False',
    }));

    return processedData;
}


const BlockedBundlesLogDataGrid = ({data, onRowEdit}) => {

    const [rows, setRows] = useState(processData(data));
    const gridRef = useRef(null);

    useEffect(() => {
        setRows(processData(data));
    }, [data]);

    const bundle_id = rows.some(row => 'bundle_id' in row);
    const sh_company_id = rows.some(row => 'sh_company_id' in row);
    const list_id = rows.some(row => 'list_id' in row);
    const is_blocked = rows.some(row => 'is_blocked' in row);
    const blocks_count = rows.some(row => 'blocks_count' in row);
    const blocked_date = rows.some(row => 'blocked_date' in row);
    const unblocked_date = rows.some(row => 'unblocked_date' in row);
    const created_at = rows.some(row => 'created_at' in row);

    let columns = [];

    columns.push({field: 'id', headerName: 'ID', width: 50});
    if (bundle_id) columns.push({field: 'bundle_id', headerName: 'Bundle ID', width: 400});
    if (sh_company_id) columns.push({field: 'sh_company_id', headerName: 'Company', width: 200});
    if (list_id) columns.push({field: 'list_id', headerName: 'Block List ID', width: 100});
    if (is_blocked) columns.push({field: 'blockedOriginal', headerName: 'Is Blocked', width: 80});
    if (blocks_count) columns.push({field: 'blocks_count', headerName: 'Blocks Count', width: 100});
    if (blocked_date) columns.push({field: 'blocked_date', headerName: 'Blocked Date', width: 100});
    if (unblocked_date) columns.push({field: 'unblocked_date', headerName: 'Unblocked Date', width: 130});
    if (created_at) columns.push({field: 'created_at', headerName: 'Created At', width: 100});


    if (data.length === 0) {
        return null;
    } else {

        return (

            <div style={{height: 800, width: '100%'}} ref={gridRef}>
                <StyledDataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={25}
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    disableRowSelectionOnClick
                />
            </div>
        );
    }
};

export default BlockedBundlesLogDataGrid;
