import React, {useState, useEffect, useRef} from 'react';
import axios from "axios";
import {
    Button,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControlLabel,
    Checkbox,
    CircularProgress,
    Box,
    InputLabel,
    Radio,
    Alert,
    AlertTitle,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Select,
    MenuItem,
    Chip,
    FormControl,
    OutlinedInput,
} from '@mui/material';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import {
    usersFields, fetchStatisticsData, fetchUsersData
} from '../../../../API/AdminUserControl';
import {fetchLogsStatisticsData} from '../../../../API/Logs';
import {sendDataToApi} from '../../../../API/apiHelper';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import Cookies from 'js-cookie';
import {DateRangePicker} from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { toast } from 'react-toastify';
import useApi from '../../../../hooks/useApi';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 300,
        },
    },
};

function LogControlOptionsForm({onGenerate}) {

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 7);
    const formattedDate = yesterday.toISOString().split('T')[0];

    const today = new Date();
    const formattedDateToday = today.toISOString().split('T')[0];


    const [options, setOptions] = useState({
        stats: [],
        users: [],
        obj: [],
        start_date: formattedDate,
        end_date: formattedDateToday,
    });

    const [loading, setLoading] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedObj, setSelectedObj] = useState([]);
    const dropDownRef = useRef(null);
    const pickerRef = useRef(null);
    const [selectedInterval, setSelectedInterval] = useState('');
    const [selectedRange, setSelectedRange] = useState({
        startDate: new Date(options.start_date),
        endDate: new Date(options.start_date),
        key: 'selection',
    });

    const [showCalendar, setShowCalendar] = useState(false);


    const {data: users, loading: loadingUsers} = useApi(fetchUsersData);
    const {data: statistics, loading: loadingStats} = useApi(fetchLogsStatisticsData);

    const handleClickOutside = (event) => {
        if (pickerRef.current && !pickerRef.current.contains(event.target) && !dropDownRef.current.contains(event.target)) {
            setShowCalendar(false);
        }

    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const handleChangeUser = (event) => {
        setSelectedUsers(event.target.value);
    };

    const handleChangeObj = (event) => {
        setSelectedObj(event.target.value);
    };

    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            users: [selectedUsers],
        }));
    }, [selectedUsers]);

    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            obj: [selectedObj],
        }));
    }, [selectedObj]);

    const toggleCalendar = () => {

        if (showCalendar === false) {
            setShowCalendar(true);
        } else {
            setShowCalendar(false);
        }
    };

    const handleDateChange = (event) => {
        const endDate = new Date(event.selection.endDate);
        endDate.setHours(12, 12, 12, 12);
        const endDateISOString = endDate.toISOString().split('T')[0];

        const startDate = new Date(event.selection.startDate);
        startDate.setHours(12, 12, 12, 12);
        const startDateISOString = startDate.toISOString().split('T')[0];

        setSelectedRange((prevSelectedRange) => ({
            ...prevSelectedRange,
            startDate: new Date(startDateISOString),
            endDate: new Date(endDateISOString),
            key: 'selection',
        }));

        if (endDate && startDate) {
            setOptions((prevOptions) => ({
                ...prevOptions,
                start_date: startDateISOString,
                end_date: endDateISOString,
            }));
        }
    };

    useEffect(() => {
        const storedValue = Cookies.get('adsLogs');
        if (storedValue) {
            const parsedValue = JSON.parse(storedValue);

            if ('stats' in parsedValue) {
                setOptions(prevOptions => ({
                    ...prevOptions,
                    stats: parsedValue.stats
                }));
            }
            if ('start_date' in parsedValue) {
                setOptions(prevOptions => ({
                    ...prevOptions,
                    start_date: parsedValue.start_date
                }));
            }
            if ('end_date' in parsedValue) {
                setOptions(prevOptions => ({
                    ...prevOptions,
                    end_date: parsedValue.end_date
                }));
            }
            if ('users' in parsedValue && Array.isArray(parsedValue.users)) {
                const filterArray = parsedValue.users[0];
                setSelectedUsers(filterArray);
            }

            // if ('obj' in parsedValue && Array.isArray(parsedValue.obj)) {
            //     const filterArray = parsedValue.obj[0];
            //     console.log(filterArray);
            //     setSelectedObj(filterArray);
            // }
        }
    }, []);

    const handleSubmit = async (event) => {

        try {
            setLoading(true);
            const response = await sendDataToApi('post','/api/logs/get', options, 'adsLogs');
            const fieldsArray = Object.entries(response.data).map(([key, value]) => ({key, value}));
            setLoading(false);
            onGenerate(fieldsArray);
        } catch (err) {
            toast.error(`Oops, there seems to be an error on the server. The technical team has already received information about this. We apologize for the inconvenience and we will let you know when it is fixed.`);
            setLoading(false);
            console.error(err);
            return [];
        }
    };


    const handleCheckboxChange = (event) => {
        const {name} = event.target;
        setOptions({...options, fields: name});
    };


    const handleCheckboxChangeStat = (event) => {
        const {name, checked} = event.target;
        let stats = [...options.stats];

        if (checked) {
            stats.push(name);
        } else {
            stats = stats.filter((satat) => satat !== name);
        }

        setOptions({...options, stats});
    };


    return (
        <Grid container spacing={2} sx={{width: "100%", marginLeft: '0px',}}>
            <Accordion sx={{width: "100%"}}>
                <AccordionSummary
                    expandIcon={<KeyboardDoubleArrowDownOutlinedIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{backgroundColor: '#f0f3f5', width: "100%"}}
                >
                    Options
                </AccordionSummary>
                <AccordionDetails sx={{width: "100%"}}>
                    <form onSubmit={handleSubmit}>
                        <Grid item xs={12} md={12} sx={{width: "100%"}}>
                            <Grid item xs={12} md={12} sx={{width: "100%", margin: '25px 0 25px auto'}}>

                                <Grid item xs={12} md={12} sx={{width: "100%", margin: '10px 0 10px auto'}}>
                                    {Object.keys(statistics).length != 0 ? (<strong>Data</strong>) : ''}
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    {statistics.map((statistic, index) => (
                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <Checkbox
                                                    checked={options.stats.includes(statistic.value)}
                                                    onChange={handleCheckboxChangeStat}
                                                    name={statistic.value}
                                                />
                                            }
                                            label={statistic.value}
                                        />
                                    ))}
                                </Grid>

                                <Grid item xs={12} md={12} sx={{width: "100%", margin: '10px 0 10px auto'}}>
                                    <strong>Filters</strong>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <FormControl sx={{m: 1, width: 300}}>
                                        <InputLabel id="demo-multiple-chip-label">User</InputLabel>
                                        <Select
                                            labelId="demo-multiple-chip-label"
                                            id="demo-multiple-chip"
                                            multiple
                                            value={selectedUsers}
                                            onChange={handleChangeUser}
                                            input={<OutlinedInput id="select-multiple-chip" label="User"/>}
                                            renderValue={(selected) => (
                                                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                                    {selected.map((select) => (
                                                        <Chip key={select}
                                                              label={users.find((item) => item.value.id === select)?.value.name}/>
                                                    ))}
                                                </Box>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                            {users.map((item) => (
                                                <MenuItem key={item.value.id} value={item.value.id}>
                                                    {item.value.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    {/*<FormControl sx={{m: 1, width: 300}}>*/}
                                    {/*    <InputLabel id="demo-multiple-chip-label">Object type</InputLabel>*/}
                                    {/*    <Select*/}
                                    {/*        labelId="demo-multiple-chip-label"*/}
                                    {/*        id="demo-multiple-chip"*/}
                                    {/*        multiple*/}
                                    {/*        value={selectedObj}*/}
                                    {/*        onChange={handleChangeObj}*/}
                                    {/*        input={<OutlinedInput id="select-multiple-chip" label="Object type"/>}*/}
                                    {/*        MenuProps={MenuProps}*/}
                                    {/*    >*/}
                                    {/*        <MenuItem key='CTV' value='CTV'>*/}
                                    {/*            CTV*/}
                                    {/*        </MenuItem>*/}
                                    {/*        <MenuItem key='Entries' value='Entries'>*/}
                                    {/*            Entries*/}
                                    {/*        </MenuItem>*/}
                                    {/*        <MenuItem key='INAPP' value='INAPP'>*/}
                                    {/*            INAPP*/}
                                    {/*        </MenuItem>*/}
                                    {/*    </Select>*/}
                                    {/*</FormControl>*/}

                                </Grid>

                            </Grid>
                        </Grid>
                    </form>
                </AccordionDetails>
            </Accordion>

            <Grid item xs={6} md={6}>
                <Button
                    size={"large"}
                    startIcon={<BackupTableOutlinedIcon fontSize="small"/>}
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{marginLeft: '-15px'}}
                >
                    Generate report
                </Button>
            </Grid>


            <Grid item xs={6} md={6}>
                <div>
                    <div
                        ref={dropDownRef}
                        style={{
                            userSelect: 'none',
                            borderRadius: '5px',
                            textAlign: "center",
                            backgroundColor: '#f0f3f5',
                            padding: '10px',
                            cursor: 'pointer',
                            float: 'right',
                            border: '1px solid #dddfe1',
                        }}
                        onClick={toggleCalendar}
                    >
                        <div>Date Range</div>
                        <div>
                            {options.start_date} - {options.end_date}
                        </div>
                    </div>
                    {showCalendar && (
                        <div
                            ref={pickerRef}
                            style={{border: '15px', boxShadow: '15px 15px 20px rgba(0, 0, 0, 0.3)',}}
                        >
                            <DateRangePicker
                                ranges={[selectedRange]}
                                onChange={handleDateChange}
                                moveRangeOnFirstSelection={false}
                                showSelectionPreview={true}
                            />
                        </div>
                    )}
                </div>
            </Grid>

            {loading &&
                <Grid item xs={12} md={12}>
                    <Box justifyContent="center" textAlign={"center"} alignItems="center">
                        <CircularProgress size={200}/>
                    </Box>
                </Grid>}
        </Grid>

    );
}

export default LogControlOptionsForm;
