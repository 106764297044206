import {fetchDataFromApi} from './apiHelper';

export const fetchMissedRowsDataFields = () => fetchDataFromApi('post', '/api/report-ads-txt/get');
export const fetchMissedRowsStatisticFields = () => fetchDataFromApi('post', '/api/report-ads-txt/getstat');
export const fetchINAPPStatisticFields = () => fetchDataFromApi('post', '/api/report-ads-txt/inapp/getstat');
export const fetchCTVStatisticFields = () => fetchDataFromApi('post', '/api/report-ads-txt/ctv/getstat');
export const fetchEntyStatisticFields = () => fetchDataFromApi('post', '/api/report-ads-txt/entries/getstat');
export const fetchINAPCreateData = () => fetchDataFromApi('post', '/api/report-ads-txt/inapp/getcreate');
export const fetchCVTCreateData = () => fetchDataFromApi('post', '/api/report-ads-txt/ctv/getcreate');
export const fetchEntriesCreateData = () => fetchDataFromApi('post', '/api/report-ads-txt/entries/getcreate');
