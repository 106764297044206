import {CssBaseline} from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes
} from 'react-router-dom';
import {Provider} from 'react-redux';
import App from './App';
import {store} from "./redux/store";
import reportWebVitals from './reportWebVitals';
// import ReactGA from 'react-ga';

//import './assets/scss/style.scss';
import * as Sentry from "@sentry/react";


Sentry.init({
    dsn: "https://a50c87688b21674ecc6e72b89e4a84c4@o4505362457493504.ingest.sentry.io/4505623224778752",
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            ),
        }),
        new Sentry.Replay()
    ],

    tracesSampleRate: 1.0,

    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

// ReactGA.initialize('G-JSFBE75KJS');
//
// ReactGA.initialize('G-JSFBE75KJS', {
//     debug: true
// });
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


root.render(
    //<React.StrictMode>
    <Provider store={store}>
        <CssBaseline/>
        <App/>
    </Provider>
    //</React.StrictMode>
);

reportWebVitals();
