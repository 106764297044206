import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import jwtDecode from 'jwt-decode';
import Cookies from "js-cookie";
import axios from "axios";
import {toast} from 'react-toastify';
import {sendDataToApi} from '../../../API/apiHelper';
import {GoogleOAuthProvider} from '@react-oauth/google';


const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function GoogleLoginButton() {
    const [initialized, setInitialized] = useState(false);
    const routerHistory = useNavigate();


    useEffect(() => {
        if (!window.google && !initialized) {
            const script = document.createElement('script');
            script.src = 'https://accounts.google.com/gsi/client';
            script.async = true;
            script.defer = true;
            script.onload = () => {
                setInitialized(true);
            };
            document.body.appendChild(script);
        }
    }, [initialized]);

    useEffect(() => {
        if (initialized) {
            window.google.accounts.id.initialize({
                client_id: CLIENT_ID,
                callback: handleCredentialResponse
            });
            window.google.accounts.id.renderButton(
                document.getElementById("buttonDiv"),
                {theme: 'outline', size: 'large', width: '100%'}
            );
            window.google.accounts.id.prompt();
        }
    }, [initialized]);

    function generatePassword(length) {
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let password = "";
        for (let i = 0, n = charset.length; i < length; ++i) {
            password += charset[Math.floor(Math.random() * n)];
        }
        return password;
    }

    function handleCredentialResponse(response) {

        const decodedToken = jwtDecode(response.credential);

        const userName = decodedToken.name;
        const userEmail = decodedToken.email;
        const userPicture = decodedToken.picture;


        window.localStorage.setItem('userGoogleName', JSON.stringify(userName));
        window.localStorage.setItem('userGoogleMail', JSON.stringify(userEmail));
        window.localStorage.setItem('userGooglePicture', JSON.stringify(userPicture));


        const params = {token: response.credential};

        const userAuth = sendDataToApi('post', '/api/user/google', params, false, (answer) => {
            if (answer.data.message) {
                toast.error(answer.data.message);
            } else {
                Cookies.set('jwt', answer.data.token, {expires: 1});
                const decodedJwt = jwtDecode(answer.data.token);
                window.localStorage.setItem('user_id', decodedJwt.sub);

                if (answer.data.admin === 1) {Cookies.set('user_admin', 1, {expires: 1});}
                if (answer.data.ac_manager === 1) {Cookies.set('user_manager', 1, {expires: 1})};
                if (answer.data.pub_manager === 1) {Cookies.set('user_pub_manager', 1, {expires: 1})};
                if (answer.data.boss_ac_manager === 1) {Cookies.set('boss_ac_managers', 1, {expires: 1})};
                if (answer.data.boss_pub_manager === 1) {Cookies.set('boss_pub_managers', 1, {expires: 1})};
                routerHistory('/');
                window.location.reload();
            }
        }, false);
    }

    return (
        <div id="buttonDiv">
            <GoogleOAuthProvider clientId={CLIENT_ID}>...</GoogleOAuthProvider>;
        </div>
    );
}

export default GoogleLoginButton;
