import { Outlet } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import Sidebar from "../common/Sidebar";
import Topbar from "../common/Topbar";
import { useEffect, useState } from "react";

const MainLayout = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const [selectedMenuItem, setSelectedMenuItem] = useState(null);

    const handleDrawerToggle = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const handleMenuItemClick = (item) => {
        setSelectedMenuItem(item.sidebarProps.displayText);
    };

    return (
        <Box sx={{ display: "flex" }}>
            <Topbar onMenuClick={handleDrawerToggle} isOpen={isDrawerOpen} selectedMenuItem={selectedMenuItem} />
            <Box
                component="nav"
                sx={{
                    width: isDrawerOpen ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.minWidth,
                    flexShrink: 0,
                }}
            >
                <Sidebar isOpen={isDrawerOpen} handleDrawerOpen={handleDrawerToggle} onMenuItemClick={handleMenuItemClick} />
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 0,
                    p: 3,
                    width: `calc(100% - ${isDrawerOpen ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.minWidth})`,
                    minHeight: "100vh",
                    backgroundColor: colorConfigs.mainBg,
                }}
            >
                <Toolbar />
                <Outlet />
            </Box>
        </Box>
    );
};

export default MainLayout;
