import React, {useEffect, useState} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Alert,
} from '@mui/material';
import {toast} from 'react-toastify';
import axios from "axios";

function DeleteRowDialog({
                             open,
                             handleClose,
                             editRow,
                             onDeleteSuccess,
                         }) {

    const [endpoint, setEndpoint] = useState('/api/smarthub/bundlesmanager/delete');
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const sendToServer = async () => {

        try {
            const userId = localStorage.getItem('user_id');
            const formData = new FormData();

            if (editRow.id) {
                formData.append('id', editRow.id);
                formData.append('user_id', userId);

                const response = await axios.post(`${BASE_URL}${endpoint}`, formData, {
                    headers: {'Content-Type': 'multipart/form-data'},
                });

                if (response.data.success) {
                    toast.success('The row has been deleted!');
                    onDeleteSuccess(editRow.id);
                    handleClose();
                } else {
                    toast.error('Error: ' + response.data.message);
                }
            } else {
                toast.error('System error: This row without ID.');
            }


        } catch (error) {
            toast.error(`An error occurred while sending data: ${error.message}`);
            console.error('An error occurred while sending data:', error);
        }
    };


    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Delete a row with ID {editRow.id}</DialogTitle>
            <DialogContent>
                <Alert variant="outlined" severity="error" color="error" sx={{marginBottom: "20px"}}>
                    This row will be deleted. Are you sure?
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={sendToServer} color="error">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteRowDialog;