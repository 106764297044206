import React, {useEffect, useState} from "react";
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import AutoAwesomeMosaicOutlinedIcon from '@mui/icons-material/AutoAwesomeMosaicOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import FinancialToolsPageLayout from "../pages/FinancialTools/FinancialToolsPageLayout";
import SwitchAccountOutlinedIcon from '@mui/icons-material/SwitchAccountOutlined';
import ManageHistoryOutlinedIcon from '@mui/icons-material/ManageHistoryOutlined';
import RevenueReport from "../pages/FinancialReports/RevenueReport/RevenueReport";
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import PayoutReport from "../pages/FinancialReports/PayoutReport/PayoutReport";
import SmartButtonOutlinedIcon from '@mui/icons-material/SmartButtonOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import TotalRevenue from "../pages/FinancialTools/TotalRevenue/TotalRevenue";
import PowerInputOutlinedIcon from '@mui/icons-material/PowerInputOutlined';
import BundleBlockingManager from "../pages/SmartHub/BundleBlockingManager";
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import TotalPayout from "../pages/FinancialTools/TotalPayout/TotalPayout";
import UserHierarchy from "../pages/admin/UserHierarchy/UserHierarchy";
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import SmartHubPageLayout from "../pages/SmartHub/SmartHubPageLayout";
import DatabasePageLayout from "../pages/Database/DatabasePageLayout";
import PixalatePageLayout from "../pages/Pixalate/PixalatePageLayout";
import BidsCubePageLayout from "../pages/BidsCube/BidsCubePageLayout";
import BidsCompareReport from "../pages/BidsCube/BidsCompareReport";
import AlertControl from "../pages/admin/AlertControl/AlertControl";
import BlockedBundlesLog from "../pages/SmartHub/BlockedBundlesLog";
import UserControl from "../pages/admin/UserControl/UserControl";
import BundleWhiteList from "../pages/SmartHub/BundleWhiteList";
import CridsWhiteList from "../pages/SmartHub/CridsWhiteList";
import CompareReport from "../pages/SmartHub/CompareReport";
import BundleReport from "../pages/SmartHub/BundleReport";
import BundleCheck from "../pages/SmartHub/BundleCheck"
import ADSPageLayout from "../pages/ADS/ADSPageLayout";
import BundleLogs from "../pages/SmartHub/BundleLogs";
import StorageIcon from '@mui/icons-material/Storage';
import AdsLogs from "../pages/admin/AdsLogs/AdsLogs";
import MainReport from "../pages/GeoEdge/MainReport";
import QPSLimits from "../pages/SmartHub/QPSLimits";
import IVTReport from "../pages/Pixalate/IVTReport";
import MissedRows from "../pages/ADS/MissedRows";
import DatabaseSSP from "../pages/Database/SSP";
import DatabaseDSP from "../pages/Database/DSP";
import HomePage from "../pages/home/HomePage";
import Entries from "../pages/ADS/Entries";
import SSP from "../pages/SmartHub/SSP";
import DSP from "../pages/SmartHub/DSP";
import INAPP from "../pages/ADS/INAPP";
import CTV from "../pages/ADS/CTV";
import {RouteType} from "./config";
import Cookies from 'js-cookie';

import {useSelector} from "react-redux";

const appRoutes: RouteType[] = [
    {
        index: true,
        element: <HomePage/>,
        state: "home"
    },
    {
        path: "/SmartHub",
        element: <SmartHubPageLayout/>,
        state: "SmartHub",
        sidebarProps: {
            displayText: "SmartHub",
            icon: <ArticleOutlinedIcon/>
        },
        child: [
            {
                path: "/SmartHub/Compare-Report",
                element: <CompareReport/>,
                state: "SmartHub.CompareReport",
                sidebarProps: {
                    displayText: "Compare Report",
                    icon: <PowerInputOutlinedIcon fontSize="small"/>
                },
            },
            // ...(Cookies.get('user_admin') === '1' ?
            //     [
            //         {
            //             path: "/SmartHub/Bundle-Report",
            //             element: <BundleReport/>,
            //             state: "SmartHub.BundleReport",
            //             sidebarProps: {
            //                 displayText: "Bundle Report",
            //                 icon: <PowerInputOutlinedIcon fontSize="small"/>
            //             }
            //         },
            //
            //         {
            //             path: "/SmartHub/Bundle-Check",
            //             element: <BundleCheck/>,
            //             state: "SmartHub.BundleCheck",
            //             sidebarProps: {
            //                 displayText: "Bundle Check",
            //                 icon: <PowerInputOutlinedIcon fontSize="small"/>
            //             }
            //         },
            //         {
            //             path: "/SmartHub/Bundle-Logs",
            //             element: <BundleLogs/>,
            //             state: "SmartHub.BundleLogs",
            //             sidebarProps: {
            //                 displayText: "Bundle Logs",
            //                 icon: <PowerInputOutlinedIcon fontSize="small"/>
            //             }
            //         },
            //
            //     ]
            //     : []),
            // {
            //     path: "/SmartHub/SSP",
            //     element: <SSP/>,
            //     state: "SmartHub.SSP",
            //     sidebarProps: {
            //         displayText: "SSP",
            //         icon: <PowerInputOutlinedIcon fontSize="small"/>
            //     }
            // },
            // {
            //     path: "/SmartHub/DSP",
            //     element: <DSP/>,
            //     state: "SmartHub.DSP",
            //     sidebarProps: {
            //         displayText: "DSP",
            //         icon: <PowerInputOutlinedIcon fontSize="small"/>
            //     }
            // },
            {
                path: "/SmartHub/SSP-wasteful-QPS-in-RT",
                element: <QPSLimits/>,
                state: "SmartHub.QPSLimits",
                sidebarProps: {
                    displayText: "SSP wasteful QPS in RT",
                    icon: <PowerInputOutlinedIcon fontSize="small"/>
                }
            },
            {
                path: "/SmartHub",
                element: <SmartHubPageLayout />,
                state: "SmartHub",
                sidebarProps: {
                    displayText: "RCPM ",
                    icon: <SmartButtonOutlinedIcon/>
                },
                child: [
                    {
                        path: "/SmartHub/Bundle-Blocking-Manager",
                        element: <BundleBlockingManager />,
                        state: "SmartHub.BundleBlockingManager",
                        sidebarProps: {
                            displayText: "Blocking Manager",
                            icon: <PowerInputOutlinedIcon fontSize="small" />
                        }
                    },
                    {
                        path: "/SmartHub/Bundle-White-List",
                        element: <BundleWhiteList />,
                        state: "SmartHub.BundleWhiteList",
                        sidebarProps: {
                            displayText: "Bundle White List",
                            icon: <PowerInputOutlinedIcon fontSize="small" />
                        }
                    },
                    {
                        path: "/SmartHub/Blocked-Bundles-Log",
                        element: <BlockedBundlesLog />,
                        state: "SmartHub.BlockedBundlesLog",
                        sidebarProps: {
                            displayText: "Blocked Bundles Log",
                            icon: <PowerInputOutlinedIcon fontSize="small" />
                        }
                    },
                ]
            },
            {
                path: "/SmartHub",
                element: <SmartHubPageLayout />,
                state: "SmartHub",
                sidebarProps: {
                    displayText: "CRID",
                    icon: <SmartButtonOutlinedIcon/>
                },
                child: [
                    {
                        path: "/SmartHub/Crids-White-List",
                        element: <CridsWhiteList />,
                        state: "SmartHub.CridsWhiteList",
                        sidebarProps: {
                            displayText: "CRID White List",
                            icon: <PowerInputOutlinedIcon fontSize="small" />
                        }
                    },
                ]
            }
            // {
            //     path: "/SmartHub/RCPM",
            //     element: <SmartHubPageLayout/>,
            //     state: "SmartHub.RCPM",
            //     sidebarProps: {
            //         displayText: "RCPM Automation",
            //         icon: <InsertChartOutlinedRoundedIcon/>
            //     },
            //     subchild: [
            //         {
            //             path: "/SmartHub/RCPM/Bundle-Blocking-Manager",
            //             element: <BundleBlockingManager/>,
            //             state: "SmartHub.RCPM.BundleBlockingManager",
            //             sidebarProps: {
            //                 displayText: "Blocking Manager",
            //                 icon: <PowerInputOutlinedIcon fontSize="small"/>
            //             }
            //         },
            //         {
            //             path: "/SmartHub/RCPM/Bundle-White-List",
            //             element: <BundleWhiteList/>,
            //             state: "SmartHub.RCPM.BundleWhiteList",
            //             sidebarProps: {
            //                 displayText: "Bundle White List",
            //                 icon: <PowerInputOutlinedIcon fontSize="small"/>
            //             }
            //         },
            //         {
            //             path: "/SmartHub/RCPM/Blocked-Bundles-Log",
            //             element: <BlockedBundlesLog/>,
            //             state: "SmartHub.RCPM.BlockedBundlesLog",
            //             sidebarProps: {
            //                 displayText: "Blocked Bundles Log",
            //                 icon: <PowerInputOutlinedIcon fontSize="small"/>
            //             }
            //         },
            //     ]
            // },
        ]
    },
    // ...(Cookies.get('user_admin') === '1' ?
    //     [
    {
        path: "/Pixalate",
        element: <PixalatePageLayout/>,
        state: "Pixalate",
        sidebarProps: {
            displayText: "Pixalate",
            icon: <InsertChartOutlinedRoundedIcon/>
        },
        child: [
            {
                path: "/Pixalate/IVT-Report",
                element: <IVTReport/>,
                state: "Pixalate.IVTReport",
                sidebarProps: {
                    displayText: "IVT Report",
                    icon: <PowerInputOutlinedIcon fontSize="small"/>
                },
            },
        ]
    },
    {
        path: "/GeoEdge",
        element: <PixalatePageLayout/>,
        state: "GeoEdge",
        sidebarProps: {
            displayText: "GeoEdge",
            icon: <AutoAwesomeMosaicOutlinedIcon/>
        },
        child: [
            {
                path: "/GeoEdge/GeoEdge-Report",
                element: <MainReport/>,
                state: "GeoEdge.Report",
                sidebarProps: {
                    displayText: "Main Report",
                    icon: <PowerInputOutlinedIcon fontSize="small"/>
                },
            },
        ]
    },
    // ]
    // : [] ),
    {
        path: "/BidsCube",
        element: <BidsCubePageLayout/>,
        state: "BidsCube",
        sidebarProps: {
            displayText: "BidsCube",
            icon: <AccountTreeOutlinedIcon/>
        },
        child: [
            {
                path: "/BidsCube/Compare-Report",
                element: <BidsCompareReport/>,
                state: "BidsCube.BidsCompareReport",
                sidebarProps: {
                    displayText: "Compare Report",
                    icon: <PowerInputOutlinedIcon fontSize="small"/>
                },
            },
        ]
    },
    ...(Cookies.get('user_admin') === '1'
        ? [

            {
                path: "/ADS",
                element: <ADSPageLayout/>,
                state: "ADS",
                sidebarProps: {
                    displayText: "ADS.txt",
                    icon: <WidgetsOutlinedIcon/>
                },
                child: [
                    {
                        path: "/ADS/Missed-Rows",
                        element: <MissedRows/>,
                        state: "ADS.MissedRows",
                        sidebarProps: {
                            displayText: "Missed Rows",
                            icon: <PowerInputOutlinedIcon fontSize="small"/>
                        },
                    },
                    ...(Cookies.get('user_admin') === '1' ?
                        [
                            {
                                path: "/ADS/INAPP",
                                element: <INAPP/>,
                                state: "ADS.INAPP",
                                sidebarProps: {
                                    displayText: "INAPP",
                                    icon: <PowerInputOutlinedIcon fontSize="small"/>
                                },
                            },
                            {
                                path: "/ADS/CTV",
                                element: <CTV/>,
                                state: "ADS.CTV",
                                sidebarProps: {
                                    displayText: "CTV",
                                    icon: <PowerInputOutlinedIcon fontSize="small"/>
                                },
                            },
                            {
                                path: "/ADS/Entries",
                                element: <Entries/>,
                                state: "ADS.Entries",
                                sidebarProps: {
                                    displayText: "Entries",
                                    icon: <PowerInputOutlinedIcon fontSize="small"/>
                                },
                            },
                        ]
                        : []),
                ]
            },
        ]
        : []),
    {
        path: "/Database",
        element: <DatabasePageLayout/>,
        state: "Database",
        sidebarProps: {
            displayText: "Database",
            icon: <StorageIcon/>
        },
        child: [
            {
                path: "/Database/DSP",
                element: <DatabaseDSP/>,
                state: "Database.DSP",
                sidebarProps: {
                    displayText: "DSP",
                    icon: <PowerInputOutlinedIcon fontSize="small"/>
                },
            },
            {
                path: "/Database/SSP",
                element: <DatabaseSSP/>,
                state: "Database.SSP",
                sidebarProps: {
                    displayText: "SSP",
                    icon: <PowerInputOutlinedIcon fontSize="small"/>
                },
            },
        ]
    },
    ...(Cookies.get('user_admin') === '1' || Cookies.get('user_manager') === '1' || Cookies.get('user_pub_manager') === '1'
        ? [
            {
                path: "/FinancialTools",
                element: <FinancialToolsPageLayout/>,
                state: "FinancialTools",
                sidebarProps: {
                    displayText: "Financial Tools",
                    icon: <RequestQuoteOutlinedIcon/>
                },
                child: [
                    ...(Cookies.get('user_admin') === '1' || Cookies.get('user_manager') === '1'
                        ? [
                            {
                                path: "/FinancialTools/Total-Revenue",
                                element: <TotalRevenue/>,
                                state: "FinancialTools.TotalRevenue",
                                sidebarProps: {
                                    displayText: "Total Revenue",
                                    icon: <PowerInputOutlinedIcon fontSize="small"/>
                                },
                            }
                        ] : []),
                    ...(Cookies.get('user_admin') === '1' || Cookies.get('user_manager') === '1' || Cookies.get('user_pub_manager') === '1'
                        ? [
                            {
                                path: "/FinancialTools/Total-Payout",
                                element: <TotalPayout/>,
                                state: "FinancialTools.TotalPayout",
                                sidebarProps: {
                                    displayText: "Total Payout",
                                    icon: <PowerInputOutlinedIcon fontSize="small"/>
                                },
                            }
                        ] : []),
                ]
            },
        ] : []),
    ...(Cookies.get('user_admin') === '1'
        ? [
            {
                path: "/FinancialReports",
                element: <FinancialToolsPageLayout/>,
                state: "FinancialReports",
                sidebarProps: {
                    displayText: "Financial Reports",
                    icon: <SummarizeOutlinedIcon/>
                },
                child: [
                    {
                        path: "/FinancialReports/Revenue-Report",
                        element: <RevenueReport/>,
                        state: "FinancialReports.RevenueReport",
                        sidebarProps: {
                            displayText: "Revenue Report",
                            icon: <PowerInputOutlinedIcon fontSize="small"/>
                        },
                    },
                    {
                        path: "/FinancialReports/Payout-Report",
                        element: <PayoutReport/>,
                        state: "FinancialReports.PayoutReport",
                        sidebarProps: {
                            displayText: "Payout Report",
                            icon: <PowerInputOutlinedIcon fontSize="small"/>
                        },
                    },
                ],
            },
        ] : []),
    ...(Cookies.get('user_admin') === '1'
        ? [
            {
                path: "/Admin",
                element: <ADSPageLayout/>,
                state: "Admin",
                sidebarProps: {
                    displayText: "Admin",
                    icon: <AdminPanelSettingsOutlinedIcon/>,
                },
                child: [
                    {
                        path: "/Admin/User-Control",
                        element: <UserControl/>,
                        state: "Admin.userControl",
                        sidebarProps: {
                            displayText: "User Control",
                            icon: <SupervisorAccountOutlinedIcon fontSize="small"/>,
                        },
                    },
                    // {
                    //     path: "/Admin/Alert-Control",
                    //     element: <AlertControl/>,
                    //     state: "Admin.alertControl",
                    //     sidebarProps: {
                    //         displayText: "Alert Control",
                    //         icon: <ManageHistoryOutlinedIcon fontSize="small"/>,
                    //     },
                    // },
                    {
                        path: "/Admin/User-Hierarchy",
                        element: <UserHierarchy/>,
                        state: "Admin.UserHierarchy",
                        sidebarProps: {
                            displayText: "User Hierarchy",
                            icon: <SwitchAccountOutlinedIcon fontSize="small"/>,
                        },
                    },
                    {
                        path: "/Admin/Logs",
                        element: <AdsLogs/>,
                        state: "Admin.Logs",
                        sidebarProps: {
                            displayText: "Logs",
                            icon: <TextSnippetOutlinedIcon fontSize="small"/>,
                        },
                    },
                ],
            },
        ]
        : []),
];

export default appRoutes;

