import React, {useState, useEffect, useRef} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Alert,
    AlertTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress
} from '@mui/material';
import SelectMulti from '../../../../components/select/SelectMulti';
import MonthPickerComponent from '../../../../components/date/MonthPickerComponent';
import {toast} from 'react-toastify';
import axios from "axios";

function CreateRowDialog({
                             open,
                             handleClose,
                             dsp,
                             onSuccess,
                         }) {

    useEffect(() => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
        setMonth(`${year}-${month}`);
    }, []);

    const [month, setMonth] = useState('');
    const [platform, setPlatform] = useState('');
    const [advertiser, setAdvertiser] = useState('');
    const [sys_revenue, setSys_revenue] = useState('');
    const [fin_revenue, setFin_revenue] = useState('');
    const [file_link, setFile_link] = useState('');
    const [file, setFile] = useState(null);
    const [comment, setComment] = useState('');
    const [cost, setCost] = useState('');
    const [confirmationOpen, setConfirmationOpen] = useState(false);

    const [endpoint, setEndpoint] = useState('/api/financialtools/totalrevenue/create');
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const handleMonthChange = (selectedMonth) => {
        const periodString = `${selectedMonth.year}-${selectedMonth.month}`;
        setMonth(periodString);
    };

    const handleSetFile = (event) => {
        setFile(event.target.files[0]);
    };
    const handleSetPlatform = (event) => {
        setPlatform(event.target.value);
    };
    const handleSetAdvertiser = (event) => {
        setAdvertiser(event.target.value);
    };
    const handleSetSys_revenue = (event) => {
        setSys_revenue(event.target.value);
    };
    const handleSetFin_revenue = (event) => {
        setFin_revenue(event.target.value);
    };
    const handleSetFile_link = (event) => {
        setFile_link(event.target.value);
    };
    const handleSetComment = (event) => {
        setComment(event.target.value);
    };
    const handleSetCost = (event) => {
        setCost(event.target.value);
    };


    const sendToServer = async () => {

        if (!platform ||
            !advertiser ||
            !sys_revenue ||
            !fin_revenue ||
            !cost
        )
        {
            toast.error('Fill required fields first!');
            return;
        }

        const difference = Math.abs(sys_revenue - fin_revenue);
        const threshold = 0.5 * Math.max(sys_revenue, fin_revenue);

        if (difference > threshold) {
            setConfirmationOpen(true)
            return;
        }

        await submitData();
    };

    const submitData = async () => {

        const userId = localStorage.getItem('user_id');

        const formData = new FormData();
        if(file){
            formData.append('file', file);
        }

        if(userId){
            formData.append('user_id', userId);
        }

        if(platform){
            formData.append('platform', platform);
        }

        if(advertiser){
            formData.append('advertiser', advertiser);
        }

        if(sys_revenue){
            formData.append('sys_revenue', sys_revenue);
        }

        if(fin_revenue){
            formData.append('fin_revenue', fin_revenue);
        }

        if(file_link){
            formData.append('file_link', file_link);
        }

        if(month){
            formData.append('month', month);
        }

        if(cost){
            formData.append('cost', cost);
        }

        if(comment){
            formData.append('comment', comment);
        }


        axios.post(`${BASE_URL}${endpoint}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response) => {
                if (response.data.success) {
                    toast.success(response.data.message);
                    setFile(null);
                    setMonth('');
                    setPlatform('');
                    setAdvertiser('');
                    setSys_revenue('');
                    setFin_revenue('');
                    setFile_link('');
                    setComment('');
                    setCost('');
                    onSuccess();
                    handleClose();
                    setConfirmationOpen(false);
                } else {
                    setConfirmationOpen(false);
                    toast.error('Error: ' + response.data.message);
                }
            })
            .catch((error) => {
                setConfirmationOpen(false);
                toast.error('An error occurred while sending a file:', error.message);
                console.error('An error occurred while sending a file:', error.message);
            });
    }



    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Create a new row</DialogTitle>
            <DialogContent>
                <Alert variant="outlined" severity="info" color="info" sx={{marginBottom: "20px"}}>
                    Please complete this form to create a new row.<br/>
                </Alert>

                <div
                    style={{
                        userSelect: 'none',
                        borderRadius: '5px',
                        textAlign: "center",
                        backgroundColor: '#f0f3f5',
                        padding: '2px',
                        cursor: 'pointer',
                        border: '1px solid #dddfe1',
                    }}
                >
                    <div>
                        <InputLabel id="advertiser-label">Choose a period</InputLabel>
                    </div>
                    <div>
                        <MonthPickerComponent onMonthChange={handleMonthChange}/>
                    </div>
                </div>

                <FormControl fullWidth margin="dense">
                    <InputLabel id="platform-label">Platform</InputLabel>
                    <Select
                        labelId="platform-label"
                        id="platform"
                        name="platform"
                        value={platform}
                        onChange={handleSetPlatform}
                        required = {true}
                        label="Platform"
                    >
                        <MenuItem value={'smarthub'}>SmartHub</MenuItem>
                        <MenuItem value={'bidscube'}>BidsCube</MenuItem>
                    </Select>
                </FormControl>

                <FormControl fullWidth margin="dense">
                    <InputLabel id="advertiser-label">Advertiser</InputLabel>
                    <Select
                        labelId="advertiser-label"
                        id="advertiser_id"
                        name="advertiser_id"
                        value={advertiser}
                        onChange={handleSetAdvertiser}
                        required = {true}
                        label="Advertiser"
                    >
                        {dsp.map((dsp) => (
                            <MenuItem key={dsp.value.id} value={dsp.value.id}>
                                {dsp.value.name} (ID: {dsp.value.id})
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    margin="dense"
                    id="cost"
                    label="Cost"
                    type="number"
                    fullWidth
                    name="cost"
                    value={cost}
                    onChange={handleSetCost}
                    required = {true}
                />

                <TextField
                    margin="dense"
                    id="sys_revenue"
                    label="Revenue From The System"
                    type="number"
                    fullWidth
                    name="sys_revenue"
                    value={sys_revenue}
                    onChange={handleSetSys_revenue}
                    required = {true}
                />

                <TextField
                    margin="dense"
                    id="fin_revenue"
                    label="Final Revenue"
                    type="number"
                    fullWidth
                    name="fin_revenue"
                    value={fin_revenue}
                    onChange={handleSetFin_revenue}
                    required = {true}
                />

                <TextField
                    margin="dense"
                    id="file_link"
                    label="File Link"
                    type="text"
                    fullWidth
                    name="file_link"
                    value={file_link}
                    onChange={handleSetFile_link}
                />

                <div>
                    <InputLabel id="advertiser-label">Or choose a file</InputLabel>
                </div>
                <div>
                    <input
                        type="file"
                        id="file"
                        name="file"
                        onChange={handleSetFile}
                    />
                </div>

                <TextField
                    margin="dense"
                    id="comment"
                    label="Revision Comment"
                    type="text"
                    fullWidth
                    name="comment"
                    value={comment}
                    onChange={handleSetComment}
                    multiline
                    rows={3}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={sendToServer} color="primary">
                    Save
                </Button>
            </DialogActions>

            <Dialog
                open={confirmationOpen}
                onClose={() => setConfirmationOpen(false)}
            >
                <DialogTitle>Confirmation Required</DialogTitle>
                <DialogContent>
                    The difference between System Revenue and Final Revenue is more than 50%. Are you sure you want to proceed?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmationOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={submitData} color="success">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Dialog>
    );
}

export default CreateRowDialog;