import React, {useEffect, useState, useRef} from 'react';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Alert,
    AlertTitle,
    FormControl,
} from '@mui/material';
import {fetchDspSmartHub, fetchFreeDspSmartHub} from '../../../API/DatabaseReport';
import {fetchAccManagers} from '../../../API/AdminUserControl';
import DeleteRowDialog from '../components/DeleteRowDialog';
import {sendDataToApi} from '../../../API/apiHelper';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import useApi from '../../../hooks/useApi';
import {styled} from '@mui/system';
import Cookies from 'js-cookie';

const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    '& .MuiDataGrid-row': {
        '&:nth-child(even)': {
            backgroundColor: '#eaf0f5',
        },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
    },
    '& .negative.win_rate_per, .negative.revenue_per, .negative.fill_rate_per, .negative.profit_per': {
        backgroundColor: 'rgba(255,235,235)',
    },
    '& .positive.win_rate_per, .positive.revenue_per, .positive.fill_rate_per, .positive.profit_per': {
        backgroundColor: 'rgba(235,255,235)',
    },
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal !important',
        wordWrap: 'break-word',
    },
    '& .total-row': {
        backgroundColor: 'lightgrey',
        fontWeight: 'bold',
    },
}));

function processData(data) {
    if (!data || !Array.isArray(data) || data.length === 0) return [];
    return data.map(row => ({
        id: row.value.id,
        ...row.value,
        smarthubDisplay: row.value.smarthub ? row.value.smarthub.map(sh => sh.name).join(", ") : "",
        smarthubOriginal: row.value.smarthub ? row.value.smarthub.map(sh => sh.id) : [],

    }));
}

const BundleBlockingManagerDataGrid = ({data, onRowEdit}) => {
    const gridRef = useRef(null);
    const [rows, setRows] = useState(processData(data));
    const [open, setOpen] = useState(false);
    const [openSend, setOpenSend] = useState(false);
    const [editRow, setEditRow] = useState({});
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [smartHubItems, setSmartHubItems] = useState([]);
    const [openDelete, setOpenDelete] = useState(false);

    const {data: users, loading: loadingUsers} = useApi(fetchAccManagers);
    const {data: smartHub, loading: loadingSmartHub} = useApi(fetchDspSmartHub);
    const {data: smartHubFree, loading: loadingSmartHubFree} = useApi(fetchFreeDspSmartHub);

    const id = rows.some(row => 'id' in row);
    const name = rows.some(row => 'name' in row);
    const company_id = rows.some(row => 'company_id' in row);
    const list_id = rows.some(row => 'list_id' in row);
    const list_name = rows.some(row => 'list_name' in row);
    const irtb_id = rows.some(row => 'irtb_id' in row);

    useEffect(() => {
        const loadData = async () => {
            try {

                if (editRow.smarthub && Array.isArray(editRow.smarthub)) {
                    const smartHubMap = new Map(smartHub.map(item => [item.value.id.toString(), item]));

                    const updatedSmartHubItems = editRow.smarthub.reduce((acc, id) => {
                        const item = smartHubMap.get(id);
                        if (item) {
                            const exists = acc.some(existingItem => existingItem.value.id === item.value.id);
                            if (!exists) {
                                acc.push(item);
                            }
                        }
                        return acc;
                    }, [...smartHubFree]);

                    setSmartHubItems(updatedSmartHubItems);
                }

            } catch (error) {
                console.error('Еrror smartHubFree:', error);
            }
        };

        loadData();
    }, [editRow]);

    useEffect(() => {
        setRows(processData(data));
    }, [data]);

    let columns = [];
    if (id) columns.push({field: 'id', headerName: 'ID', width: 80});
    if (name) columns.push({field: 'name', headerName: 'Company Name', width: 200});
    if (company_id) columns.push({field: 'company_id', headerName: 'SmartHub ID', width: 150});
    if (list_id) columns.push({field: 'list_id', headerName: 'Block List ID', width: 150});
    if (list_name) columns.push({field: 'list_name', headerName: 'Block List Name', width: 400});
    if (irtb_id) columns.push({field: 'irtb_id', headerName: 'iRTB ID', width: 100});

    const userAdmin = Cookies.get('user_admin');
    const userManager = Cookies.get('user_manager');
    
    columns.push({
        field: 'action',
        headerName: '',
        width: 100,
        renderCell: (params) => {
            const canEdit = (userAdmin === '1');
            return (
                <Button
                    variant="outlined"
                    startIcon={<EditIcon/>}
                    color="primary"
                    size="medium"
                    onClick={() => handleButtonClick(params.row)}
                    disabled={!canEdit}
                >
                    Edit
                </Button>
            )
        },
    });

    columns.push({
        field: 'action 2',
        headerName: '',
        width: 130,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
            const canEdit = (userAdmin === '1');
            return (
                <Button
                    variant="outlined"
                    startIcon={<DeleteIcon/>}
                    color="error"
                    size="medium"
                    onClick={() => handleDeleteButtonClick(params.row)}
                    disabled={!canEdit}
                >
                    Delete
                </Button>
            );
        },
    });

    const handleButtonClick = (row) => {
        setOpen(true);
        setEditRow({
            ...row,
            smarthub: row.smarthubOriginal,
        });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseSend = () => {
        setOpenSend(false);
    };

    const openSendSuccess = () => {
        setOpenSend(true);
    };

    const handleDeleteButtonClick = (row) => {
        setEditRow(row);
        setOpenDelete(true);
    };

    const handleDeleteClose = () => {
        setOpenDelete(false);
    };

    const handleRowDelete = (deletedRowId) => {
        setRows(rows.filter(row => row.id !== deletedRowId));
    };

    const handleSave = () => {
        const userId = localStorage.getItem('user_id');
        const updatedCompany = {
            ...editRow,
            ChangedBy: userId,
        };


        const response = sendDataToApi('post', '/api/smarthub/bundlesmanager/update', updatedCompany, false, (data) => {
            const updatedArray = rows.map(item => {
                if (item.id === editRow.id) {
                    return {...editRow};
                }
                return item;
            });
            setRows(updatedArray);
            setOpen(false);
            openSendSuccess();
            onRowEdit();
        });
    };

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setEditRow({...editRow, [name]: value});
    };

    const handleChangeUser = (id, name) => {
        setEditRow(prev => ({...prev, manager_id: id, manager_name: name}));
    };

    if (data.length === 0) {
        return null;
    } else {

        return (

            <div style={{height: 800, width: '100%'}} ref={gridRef}>
                <StyledDataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={25}
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    disableRowSelectionOnClick
                />

                <Dialog open={openSend} onClose={handleCloseSend}>
                    <Alert severity="success">
                        <AlertTitle>Success</AlertTitle>
                        Company changed <strong>successfully!</strong>
                    </Alert>
                </Dialog>

                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Edit {editRow.name}</DialogTitle>
                    <DialogContent
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: '400px',
                            justifyContent: 'space-between',
                            '& > :first-child': {
                                marginTop: '10px',
                                marginBottom: '30px',
                            },
                            '& > :last-child': {
                                marginBottom: '10px',
                            },
                        }}
                    >
                        <FormControl fullWidth required sx={{width: "100%", marginTop: '30px'}}>
                            <TextField
                                margin="dense"
                                id="name"
                                label="Filter List ID"
                                type="text"
                                fullWidth
                                name="list_id"
                                value={editRow.list_id}
                                onChange={handleInputChange}
                            />
                        </FormControl>

                        <FormControl fullWidth required sx={{width: "100%"}}>
                            <TextField
                                margin="dense"
                                id="name"
                                label="Filter List Name"
                                type="text"
                                fullWidth
                                name="list_name"
                                value={editRow.list_name}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleSave}>Save</Button>
                    </DialogActions>
                </Dialog>

                <DeleteRowDialog
                    open={openDelete}
                    handleClose={handleDeleteClose}
                    editRow={editRow}
                    onDeleteSuccess={handleRowDelete}
                />
            </div>
        );
    }
};

export default BundleBlockingManagerDataGrid;
