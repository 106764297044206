import React from 'react';
import {Grid, Typography} from "@mui/material";

const HomePage = () => {

  return (
      <Grid container spacing={2}
            sx={{
              padding: 4,
              backgroundColor: 'white',
              borderRadius: '10px',
              border: '4px',
              transform: '0.3',
            }}
      >
          <Grid item xs={12} md={12}>
          <Typography variant="h5" component="h1" align="center"> Welcome to iRTB </Typography>
          </Grid>

      </Grid>
  );
};

export default HomePage;