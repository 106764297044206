import { Button } from '@mui/material';
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import React from "react";

function DownloadButton({ data, label = 'Report' }) {
    const handleDownload = () => {

        const fileName = `${label}.csv`;

        if (!data || data.length === 0) return;

        const keys = Object.keys(data[0].value);
        const headerRow = keys.join(',');

        const dataRows = data.map((row, index) => {
            const rowDataWithId = { id: index, ...row.value };
            console.log(row);
            const csvRow = keys.map(key => rowDataWithId[key]).join(',');
            return csvRow;
        });

        const csvData = [headerRow, ...dataRows].join('\n');

        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Button
            onClick={handleDownload}
            variant="outlined"
            color="success"
            size={"small"}
            sx={{
                float: 'right',
                marginRight: '15px',
            }}
            startIcon={<UploadFileOutlinedIcon fontSize="small"  />}
        >
            Download CSV
        </Button>
    );
}

export default DownloadButton;
