import React, {useState, useEffect} from 'react';
import {Box, Button, Grid, Typography, CircularProgress } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import "@fontsource/roboto";
import TotalRevenueOptionsForm from "./options/TotalRevenueOptionsForm";
import DownloadButton from "../../../components/reports/DownloadButton";
import TotalRevenueDataGrid from "./components/TotalRevenueDataGrid";
import {sendJsonToApi} from '../../../API/apiHelper';
import OutletIcon from "@mui/icons-material/Outlet";
import {toast} from 'react-toastify';
import { format } from 'date-fns';

const TotalRevenue = () => {

    const [data, setData] = useState([]);
    const [dataForCsv, setDataForCsv] = useState([]);
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [cacheOptions, setCacheOptions] = useState('');

    useEffect(()=>{
        const clonedData = data;
        if(clonedData != false){
            clonedData.forEach(item => {
                if (item.value) {
                    item.value.advertiser = item.value.advertiser ? item.value.advertiser.name : null;
                    item.value.account_manager = item.value.account_manager ? item.value.account_manager.name : null;
                    delete item.value.is_active;
                    delete item.value.hyper_item_id;
                    delete item.value.deleted_by;
                    delete item.value.created_at;
                    delete item.value.updated_at;

                    if (item.value.revision_comment) {
                        item.value.revision_comment = item.value.revision_comment.replace(/[\r\n,]/g, ' ');
                    }
                }
            });

            setDataForCsv(clonedData)
        }


        const currentDate = new Date();
        const formattedDate = format(currentDate, 'yyyy-MM-dd');
        setName( `TotalRevenue - ${formattedDate}` );
    },[data])

    const handleSubmit = async (options) => {
        try {
            setCacheOptions(options);
            setLoading(true);
            const response = await sendJsonToApi('post', '/api/financialtools/totalrevenue/report', options, 'TotalRevenue');
            if (Object.keys(response.data).length === 0) {
                setData(false);
            } else {
                const fieldsArray = Object.entries(response.data).map(([key, value]) => ({key, value}));
                setData(fieldsArray);
            }
            setLoading(false);
        } catch (err) {
            toast.error(`Oops, there seems to be an error on the server. The technical team has already received information about this. We apologize for the inconvenience and we will let you know when it is fixed.`);
            console.error(err);
            setLoading(false);
        }
    };

    const handleRowEdit = (newData) => {
        handleSubmit(cacheOptions);
    };



    return (
        <div>
            <Grid container spacing={2}
                  sx={{
                      padding: 2,
                      backgroundColor: 'white',
                      borderRadius: '8px',
                      border: '4px',
                      paddingLeft: 0,
                      paddingTop: 0,
                      marginLeft: '-10px',
                      marginTop: '-5px',
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.08)',
                  }}
            >
                <Grid item xs={12} md={12} sx={{width: "100%"}}>
                    <Accordion sx={{width: "100%"}}>
                        <AccordionSummary
                            expandIcon={<InfoOutlinedIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                                backgroundColor: '#f0f3f5',
                                width: "100%",
                                height: '35px !important',
                                minHeight: '25px !important',
                            }}
                        >
                            Explanations
                        </AccordionSummary>
                        <AccordionDetails sx={{width: "100%"}}>
                            <Box>
                                <Typography variant="subtitle1" sx={{ marginBottom: '1rem' }}>
                                    📊 Report Options and Functionalities
                                </Typography>

                                <Typography variant="subtitle1" sx={{ marginBottom: '0.5rem' }}>
                                    ⚙️ Data Selection:
                                </Typography>
                                <Typography variant="body1" sx={{ marginBottom: '1rem', marginLeft: '1rem' }}>
                                    You can select specific data fields for the report by checking the corresponding checkboxes provided.
                                    The available data fields are dynamically fetched and displayed in the form.
                                    The selected data fields will be included in the generated report.
                                </Typography>

                                <Typography variant="subtitle1" sx={{ marginBottom: '0.5rem' }}>
                                    📈 Statistics Selection:
                                </Typography>
                                <Typography variant="body1" sx={{ marginBottom: '1rem', marginLeft: '1rem' }}>
                                    Similar to data selection, you can also select specific statistics fields for the report by checking the checkboxes provided.
                                    The available statistics fields are also fetched dynamically.
                                    The selected statistics fields will be included in the generated report.
                                </Typography>

                                <Typography variant="subtitle1" sx={{ marginBottom: '0.5rem' }}>
                                    🔍 Filter Selection:
                                </Typography>
                                <Typography variant="body1" sx={{ marginBottom: '1rem', marginLeft: '1rem' }}>
                                    The form provides options to add filters to the report based on various criteria such as Sellers ID, Bundles ID, Platform, and Publishers ID.
                                    You can toggle the visibility of each filter option by checking the corresponding checkboxes.
                                    For each selected filter option, an autocomplete input field is displayed where you can enter specific values for the filter.
                                </Typography>

                                <Typography variant="subtitle1" sx={{ marginBottom: '0.5rem' }}>
                                    📅 Date Selection:
                                </Typography>
                                <Typography variant="body1" sx={{ marginBottom: '1rem', marginLeft: '1rem' }}>
                                    You can select a date  for the report by clicking on the "Choose Month" dropdown.
                                    A calendar component will be displayed, allowing you to select the start and end dates for the report.
                                    The selected date range will be reflected in the displayed date range.
                                </Typography>

                                <Typography variant="subtitle1" sx={{ marginBottom: '0.5rem' }}>
                                    📝 Create New Record:
                                </Typography>
                                <Typography variant="body1" sx={{ marginBottom: '1rem', marginLeft: '1rem' }}>
                                    You can create a new entry by clicking the "Create" button. This allows you to fill in the fields and add a new record to the report.
                                </Typography>

                                <Typography variant="subtitle1" sx={{ marginBottom: '0.5rem' }}>
                                    📁 Upload CSV:
                                </Typography>
                                <Typography variant="body1" sx={{ marginBottom: '1rem', marginLeft: '1rem' }}>
                                    If you have records stored in a CSV file, you can upload them by clicking the "Upload CSV" button. Follow the instructions provided to import records from the CSV file seamlessly.
                                </Typography>

                                <Typography variant="subtitle1" sx={{ marginBottom: '0.5rem' }}>
                                    🚀 Generate Report:
                                </Typography>
                                <Typography variant="body1" sx={{ marginBottom: '1rem', marginLeft: '1rem' }}>
                                    Once you have made the desired selections and configurations, you can click the "Generate Report" button.
                                    The report generation process involves making API requests and fetching the required data based on the selected options.
                                </Typography>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>

            <Grid container spacing={2}
                  sx={{
                      padding:2,
                      backgroundColor: 'white',
                      borderRadius: '8px',
                      border: '4px',
                      paddingLeft:0,
                      marginLeft: '-10px',
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.08)',
                      marginTop: '20px',
                  }}
            >
                <Grid item xs={12} md={12} sx={{ width: "100%" }}>
                    <TotalRevenueOptionsForm onSubmit={handleSubmit}/>
                </Grid>
                <Grid item xs={12} md={12}>
                    {dataForCsv != false && <DownloadButton
                        data={dataForCsv ?? ''}
                        label={name}
                        sx={{float: 'right',}}
                    />}
                </Grid>
                <Grid item xs={12} md={12} >
                    {
                        loading &&
                        <Grid item xs={12} md={12}>
                            <Box justifyContent="center" textAlign={"center"} alignItems="center">
                                <CircularProgress size={200}/>
                            </Box>
                        </Grid>
                    }
                    {data === false ? (
                        <Typography variant="h4" component="h2" align="center"><OutletIcon
                            fontSize="large"/> NO DATA </Typography>
                    ) : (
                        <TotalRevenueDataGrid data={data} onRowEdit={handleRowEdit}/>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default TotalRevenue;