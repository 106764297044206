import React, {useState, useEffect} from 'react';
import {Grid, Typography} from "@mui/material";
import QPSOptionsForm from "./options/QPSOptionsForm";
import QpsTable from "./components/QpsTable"
import OutletIcon from "@mui/icons-material/Outlet";
import {fetchBLogStatisticFields} from "../../API/SmartHubReport";

const QPSLimits = () => {
    const [data, setData] = useState([]);

    return (
        <div>
            <Grid container spacing={2}
                  sx={{
                      padding: 2,
                      backgroundColor: 'white',
                      borderRadius: '8px',
                      border: '4px',
                      paddingLeft: 0,
                      marginLeft: '-10px',
                  }}
            >
                <Grid item xs={12} md={12} sx={{width: "100%"}}>
                    <QPSOptionsForm onGenerate={setData}/>
                </Grid>
                <Grid item xs={12} md={12}>
                    {data != false && (
                        data.map((_, i) =>
                        <div className="row" key={i}>
                            <div className="col-md-8 bl1">
                                <QpsTable data={data} index={i}/>
                            </div>
                            <div className="col-md-4"></div>
                        </div>
                    )
                    )}
                </Grid>
                <Grid item xs={12} md={12}>
                    {data === false && (
                        <Typography variant="h4" component="h2" align="center"><OutletIcon
                            fontSize="large"/> NO DATA </Typography>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default QPSLimits;