import React, {useEffect, useState} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@mui/material';
import {styled} from '@mui/system';

const Footer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1em',
    borderTop: '1px solid #ccc',
    background: '#f8fff9',
    fontWeight: 'bold',
});

const formatKey = (key) => {
    const cleanedKey = key.replace(/^total_/, '');

    return cleanedKey
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
        .replace(/(\d+)/g, ' $1');
};

const FooterTotals = ({totals}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (totals) {

            const formattedData = Object.keys(totals).map((key) => ({
                name: formatKey(key),
                value: totals[key],
            }));
            console.log(formattedData);
            setData(formattedData);
        }
    }, [totals]);

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            style={{fontSize: '0.8rem'}}
                        >
                            Total
                        </TableCell>
                        {data.map((item, index) => (
                            <React.Fragment key={index}>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    align="left"
                                    key={`${item.name}-${index}`}
                                    style={{fontSize: '0.8rem', whiteSpace: 'nowrap'}}
                                >
                                    <strong>{item.name}:</strong> {item.value}
                                </TableCell>
                            </React.Fragment>
                        ))}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default FooterTotals;
