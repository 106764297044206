import React, {useEffect, useState, useRef} from 'react';
import {DataGrid, GridToolbar, useGridApiRef} from '@mui/x-data-grid';
import {
    Checkbox,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Alert,
    AlertTitle,
    FormControlLabel,
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    OutlinedInput,
    Box,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {styled} from '@mui/system';
import DeleteRowDialog from '../components/DeleteRowDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import {fetchPubManagers} from '../../../API/AdminUserControl';
import {sendDataToApi} from '../../../API/apiHelper';
import Cookies from 'js-cookie';
import SelectMulti from '../options/SelectMulti';
import useApi from '../../../hooks/useApi';
import {
    fetchSspComanies, fetchSspSmartHub, fetchSspBidscube, fetchFreeSspSmartHub, fetchFreeSspBidscube
} from '../../../API/DatabaseReport';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 300,
        },
    },
};

const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    '& .MuiDataGrid-row': {
        '&:nth-child(even)': {
            backgroundColor: '#eaf0f5',
        },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
    },
    '& .negative.win_rate_per, .negative.revenue_per, .negative.fill_rate_per, .negative.profit_per': {
        backgroundColor: 'rgba(255,235,235)',
    },
    '& .positive.win_rate_per, .positive.revenue_per, .positive.fill_rate_per, .positive.profit_per': {
        backgroundColor: 'rgba(235,255,235)',
    },
    '& .MuiDataGrid-cell': {
        lineHeight: 'normal !important',
        maxHeight: 'none !important',
        whiteSpace: 'normal !important',
        wordWrap: 'break-word !important',
    },
    '& .total-row': {
        backgroundColor: 'lightgrey',
        fontWeight: 'bold',
    },
}));

function processData(data) {
    if (!data || !Array.isArray(data) || data.length === 0) return [];
    return data.map(row => ({
        id: row.value.id,
        ...row.value,
        smarthubDisplay: row.value.smarthub ? row.value.smarthub.map(sh => sh.name).join(", ") : "",
        smarthubOriginal: row.value.smarthub ? row.value.smarthub.map(sh => sh.id) : [],
        bidscubeDisplay: row.value.bidscube ? row.value.bidscube.map(sh => sh.name).join(", ") : "",
        bidscubeOriginal: row.value.bidscube ? row.value.bidscube.map(sh => sh.id) : [],

    }));
}

const DspDataGrid = ({data, onRowEdit}) => {
    const gridRef = useRef(null);
    const [rows, setRows] = useState(processData(data));
    const [open, setOpen] = useState(false);
    const [openSend, setOpenSend] = useState(false);
    const [editRow, setEditRow] = useState({});
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [smartHubItems, setSmartHubItems] = useState([]);
    const [bidscubeItems, setBidscubeItems] = useState([]);
    const [openDelete, setOpenDelete] = useState(false);

    const {data: users, loading: loadingUsers} = useApi(fetchPubManagers);
    const {data: smartHub, loading: loadingSmartHub} = useApi(fetchSspSmartHub);
    const {data: smartHubFree, loading: loadingSmartHubFree} = useApi(fetchFreeSspSmartHub);
    const {data: bidscube, loading: loadingBidscube} = useApi(fetchSspBidscube);
    const {data: bidscubeFree, loading: loadingBidscubeFree} = useApi(fetchFreeSspBidscube);


    const hasDspCompanyName = rows.some(row => 'name' in row);
    const hasIsPremium = rows.some(row => 'is_premium' in row);
    const hasIsManager = rows.some(row => 'manager_name' in row);
    const hasIsManagerEmail = rows.some(row => 'manager_email' in row);
    const hasIsiRTBid = rows.some(row => 'id' in row);
    const hasIsHyper = rows.some(row => 'hyper' in row);
    const hasIsHyperNew = rows.some(row => 'new_hyper' in row);
    const hasIsiSmartHub = rows.some(row => 'smarthub' in row);
    const hasIsiBidscube = rows.some(row => 'bidscube' in row);

    useEffect(() => {
        setRows(processData(data));
    }, [data]);

    useEffect(() => {
        console.log(smartHubFree)
        const loadData = async () => {
            try {

                if (editRow.smarthub && Array.isArray(editRow.smarthub)) {
                    const smartHubMap = new Map(smartHub.map(item => [item.value.id.toString(), item]));

                    const updatedSmartHubItems = editRow.smarthub.reduce((acc, id) => {
                        const item = smartHubMap.get(id);
                        if (item) {
                            const exists = acc.some(existingItem => existingItem.value.id === item.value.id);
                            if (!exists) {
                                acc.push(item);
                            }
                        }
                        return acc;
                    }, [...smartHubFree]);

                    setSmartHubItems(updatedSmartHubItems);
                }

                if (editRow.bidscube && Array.isArray(editRow.bidscube)) {
                    const bidscubeMap = new Map(bidscube.map(item => [item.value.id.toString(), item]));

                    const updatedBidsItems = editRow.bidscube.reduce((acc, id) => {
                        const item = bidscubeMap.get(id);
                        if (item) {
                            const exists = acc.some(existingItem => existingItem.value.id === item.value.id);
                            if (!exists) {
                                acc.push(item);
                            }
                        }
                        return acc;
                    }, [...bidscubeFree]);

                    setBidscubeItems(updatedBidsItems);
                }

            } catch (error) {
                console.error('Еrror smartHubFree:', error);
            }
        };

        loadData();
    }, [editRow]);

    let columns = [];
    if (hasIsiRTBid) columns.push({field: 'id', headerName: 'iRTB ID', width: 100});
    if (hasIsHyper) columns.push({field: 'hyper', headerName: 'Old Hyper ID', width: 100});
    if (hasIsHyperNew) columns.push({field: 'new_hyper', headerName: 'Hyper ID', width: 100});
    if (hasIsiSmartHub) columns.push({field: 'smarthubDisplay', headerName: 'SmartHub ID', width: 250});
    if (hasIsiBidscube) columns.push({field: 'bidscubeDisplay', headerName: 'Bidscube ID', width: 250});
    if (hasDspCompanyName) columns.push({field: 'name', headerName: 'Company Name', width: 200});
    if (hasIsPremium) columns.push({
        field: 'is_premium',
        headerName: 'Premium',
        width: 100,
        renderCell: (params) => (
            params.value === 1 ? <span>&#128081; Yes</span> : <span style={{padding: "0 20px"}}> No</span>
        ),
    });
    if (hasIsManager) columns.push({field: 'manager_name', headerName: 'Manager Name', width: 150});
    if (hasIsManagerEmail) columns.push({field: 'manager_email', headerName: 'Manager Email', width: 200});

    const userAdmin = Cookies.get('user_admin');
    const userManager = Cookies.get('user_manager');

    if (userAdmin === '1' || userManager === '1') {
        columns.push({
            field: 'action 1',
            headerName: '',
            width: 100,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    startIcon={<EditIcon/>}
                    color="primary"
                    size="medium"
                    onClick={() => handleButtonClick(params.row)}
                >
                    Edit
                </Button>
            ),
        });

        columns.push({
            field: 'action 2',
            headerName: '',
            width: 130,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Button
                        variant="outlined"
                        startIcon={<DeleteIcon/>}
                        color="error"
                        size="medium"
                        onClick={() => handleDeleteButtonClick(params.row)}
                    >
                        Delete
                    </Button>
                );
            },
        });
    }

    const handleButtonClick = (row) => {
        setOpen(true);
        setEditRow({
            ...row,
            smarthub: row.smarthubOriginal,
            bidscube: row.bidscubeOriginal,
        });
        console.log('Row data:', row);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseSend = () => {
        setOpenSend(false);
    };

    const openSendSuccess = () => {
        setOpenSend(true);
    };

    const handleSave = () => {
        const userId = localStorage.getItem('user_id');
        const updatedCompany = {
            ...editRow,
            ChangedBy: userId,
            type: 'SSP',
        };


        const response = sendDataToApi('post', '/api/database/company/update', updatedCompany, false, (data) => {
            const updatedArray = rows.map(item => {
                if (item.id === editRow.id) {
                    return {...editRow};
                }
                return item;
            });
            setRows(updatedArray);
            setOpen(false);
            openSendSuccess();
            onRowEdit();
        });
    };

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setEditRow({...editRow, [name]: value});
    };

    const handleChangeUser = (id, name) => {
        setEditRow(prev => ({...prev, manager_id: id, manager_name: name}));
    };

    const handleDeleteButtonClick = (row) => {
        setEditRow(row);
        setOpenDelete(true);
    };

    const handleDeleteClose = () => {
        setOpenDelete(false);
    };

    const handleRowDelete = (deletedRowId) => {
        setRows(rows.filter(row => row.id !== deletedRowId));
    };

    if (data.length === 0) {
        return null;
    } else {

        return (

            <div style={{height: 800, width: '100%'}} ref={gridRef}>
                <StyledDataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={25}
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    disableRowSelectionOnClick
                />

                <DeleteRowDialog
                    open={openDelete}
                    handleClose={handleDeleteClose}
                    editRow={editRow}
                    onDeleteSuccess={handleRowDelete}
                />

                <Dialog open={openSend} onClose={handleCloseSend}>
                    <Alert severity="success">
                        <AlertTitle>Success</AlertTitle>
                        DSP changed <strong>successfully!</strong>
                    </Alert>
                </Dialog>

                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Edit {editRow.name}</DialogTitle>
                    <DialogContent
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            '& > :first-child': {
                                marginTop: '10px',
                                marginBottom: '30px',
                            },
                            '& > :last-child': {
                                marginBottom: '10px',
                            },
                        }}
                    >
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Company Name"
                            type="text"
                            fullWidth
                            name="name"
                            value={editRow.name}
                            onChange={handleInputChange}
                        />

                        <FormControl fullWidth required>
                            <InputLabel id="is_premium-label">Premium</InputLabel>
                            <Select
                                value={editRow.is_premium}
                                onChange={handleInputChange}
                                name="is_premium"
                                label="Premium"
                                fullWidth
                            >
                                <MenuItem value={0}>No</MenuItem>
                                <MenuItem value={1}>Yes</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth required sx={{width: "100%", marginTop: '30px'}}>
                            <InputLabel id="demo-multiple-chip-label">Manager</InputLabel>
                            <Select
                                labelId="manager_id"
                                id="manager_id"
                                name="manager_id"
                                value={editRow.manager_id}
                                input={<OutlinedInput id="manager_id" label="Manager"/>}
                                MenuProps={MenuProps}
                            >
                                {users.map((item) => (
                                    <MenuItem
                                        key={item.value.id}
                                        value={item.value.id}
                                        onClick={() => handleChangeUser(item.value.id, item.value.name)}
                                    >
                                        {item.value.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth required sx={{width: "100%", marginTop: '30px'}}>
                            <TextField
                                margin="dense"
                                id="name"
                                label="Hyper ID"
                                type="text"
                                fullWidth
                                name="new_hyper"
                                value={editRow.new_hyper}
                                onChange={handleInputChange}
                            />
                        </FormControl>

                        <FormControl fullWidth sx={{width: "100%", marginTop: '30px'}}>
                            <SelectMulti
                                label="SmartHub ID"
                                id="smarthub"
                                name="smarthub"
                                items={smartHubItems}
                                selectedItems={editRow.smarthub}
                                onChange={handleInputChange}
                                itemLabel="value.name"
                                itemKey="value.id"
                                itemValue="value.id"
                            />
                        </FormControl>

                        <FormControl fullWidth sx={{width: "100%", marginTop: '30px'}}>
                            <SelectMulti
                                label="Bidscube ID"
                                id="bidscube"
                                name="bidscube"
                                items={bidscubeItems}
                                selectedItems={editRow.bidscube}
                                onChange={handleInputChange}
                                itemLabel="value.name"
                                itemKey="value.id"
                                itemValue="value.id"
                            />
                        </FormControl>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleSave}>Save</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
};

export default DspDataGrid;
