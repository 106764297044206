import React, {useState, useRef} from 'react';
import Picker from 'react-month-picker';
import 'react-month-picker/css/month-picker.css';

const MonthBox = ({value, onClick}) => (
    <div className="month-box" onClick={onClick}
         style={{
             cursor: 'pointer',
             border: '1px solid #ccc',
             padding: '5px',
             display: 'inline-block',
         }}
    >
        {value}
    </div>
);

const MonthPickerComponent = ({onMonthChange, initialMonthYear}) => {
    const initialDate = initialMonthYear ? new Date(initialMonthYear) : new Date();
    const [selectedMonth, setSelectedMonth] = useState({
        year: initialDate.getFullYear(),
        month: initialDate.getMonth() + 1,
    });
    const pickerRef = useRef();
    const [isPickerActive, setIsPickerActive] = useState(false);

    const handleAMonthChange = (year, month) => {
        setSelectedMonth({year, month});
        onMonthChange({year, month});
    };

    const pickerLang = {
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        from: 'From', to: 'To',
    }

    const makeText = (m) => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        if (m && m.year && m.month) return `${months[m.month - 1]} ${m.year}`;
        return 'Select Month';
    };

    const adjustPosition = () => {
        window.requestAnimationFrame(() => {
            setTimeout(() => {
                const pickerElement = document.querySelector('.rmp-popup');
                const triggerElement = document.querySelector('.month-box');

                if (pickerElement && triggerElement) {
                    const triggerRect = triggerElement.getBoundingClientRect();

                    pickerElement.style.position = 'absolute';
                    pickerElement.style.left = `-250px`;
                }
            }, 10);
        });
    };

    const handleClickMonthBox = () => {
        setIsPickerActive(true);
        pickerRef.current.show();
        window.requestAnimationFrame(adjustPosition);
    };
    const handleAMonthDismiss = () => {
        setIsPickerActive(false);
    };


    return (
        <Picker
            ref={pickerRef}
            years={{min: 2020, max: new Date().getFullYear()}}
            value={selectedMonth}
            lang={pickerLang.months}
            theme="light"
            onChange={handleAMonthChange}
            onDismiss={handleAMonthDismiss}
        >
            <MonthBox value={makeText(selectedMonth)} onClick={handleClickMonthBox}/>
        </Picker>

    );
};

export default MonthPickerComponent;