import React, {useState, useEffect} from 'react';
import {
    Pagination,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Select,
    MenuItem,
    Box,
    Alert,
    AlertTitle,
    FormControl, InputLabel, FormControlLabel, Checkbox
} from '@mui/material';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Cookies from 'js-cookie';
import {fetchINAPCreateData, fetchINAPPStatisticFields} from "../../../API/AdsReport";
import {sendDataToApi, fetchDataFromApi} from '../../../API/apiHelper';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';

function formatString(str) {
    let newStr = str.replace(/_/g, ' ');
    newStr = newStr.toLowerCase().replace(/\b[a-z]/g, function(letter) {
        return letter.toUpperCase();
    });

    return newStr;
}


const CvtTable = ({data}) => {

    const StyledTableHeadCell = styled(TableCell)({
        height: '50px',
        padding: '4px 10px',
    });

    const StyledTableCell = styled(TableCell)({
        height: '20px',
        padding: '4px 10px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    });

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const handleChange = (event, value) => {
        setPage(value);
    };

    const [entitys, setEntitys] = useState([]);
    const [editEntity, setEditEntity] = useState({
        ssp: '',
        newSspId: "",
        newSspName: "",
        platform: [],
        newPlatform: "",
        developer: "",
        newDeveloper: "",
        bundle: "",
        newBundle: "",
        relationship: "",
        category: "",
        newCategory: "",
        appName: "",
        appUrl: "",
        adsTxt: ""
    });
    const [deleteEntity, setDeleteEntity] = useState([]);
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [tittle, setTittle] = useState([]);
    const [keys, setKeys] = useState([]);

    const [openSend, setOpenSend] = useState(false);

    const [openUpdate, setOpenUpdate] = useState(false);
    const [isNewFieldOpen, setNewFieldOpen] = useState(false);

    const [isNewFieldPlatformOpen, setNewFieldPlatformOpen] = useState(false);
    const [isNewFieldDeveloperOpen, setNewFieldDeveloperOpen] = useState(false);
    const [isNewFieldBundleOpen, setNewFieldBundleOpen] = useState(false);
    const [isNewFieldCategoryOpen, setNewFieldCategoryOpen] = useState(false);


    const handleNewFieldToggle = () => {
        setNewFieldOpen(!isNewFieldOpen);
    };

    const handleNewPlatformToggle = () => {
        setNewFieldPlatformOpen(!isNewFieldPlatformOpen);
    };

    const handleNewDeveloperToggle = () => {
        setNewFieldDeveloperOpen(!isNewFieldDeveloperOpen);
    };

    const handleNewBundleToggle = () => {
        setNewFieldBundleOpen(!isNewFieldBundleOpen);
    };

    const handleNewCategoryToggle = () => {
        setNewFieldCategoryOpen(!isNewFieldCategoryOpen);
    };

    const [fields, setFileds] = useState({
        ssp: "",
        newSspId: "",
        newSspName: "",
        platform: [],
        newPlatform: "",
        usd: "",
        bundle: "",
        newBundle: "",
        relationship: "",
        category: "",
        newCategory: "",
        country: "",
        appName: "",
        appUrl: "",
        adsTxt: ""
    });

    const resetEditEntity = () => {
        setEditEntity({
            ssp: "",
            newSspId: "",
            newSspName: "",
            platform: [],
            newPlatform: "",
            usd: "",
            bundle: "",
            newBundle: "",
            relationship: "",
            category: "",
            newCategory: "",
            country: "",
            appName: "",
            appUrl: "",
            adsTxt: ""
        });
    };

    const [fieldsData, setFieldsData] = useState({
        ssps: [],
        platforms: [],
        country: [],
        bundles: [],
        categories: [],
    });

    useEffect(() => {
        if (data.length) {
            const longestItem = data.reduce((prev, current) => (Object.keys(prev.value).length > Object.keys(current.value).length) ? prev : current);
            setKeys(Object.keys(longestItem.value));
            const formattedKeys = Object.keys(longestItem.value).map(key => formatString(key));
            setTittle(formattedKeys);

            setEntitys(data);
        }
    }, [data]);

    useEffect(() => {
        const fetchCreateData = async () => {
            const fieldsArray = await fetchDataFromApi('post','/api/report-ads-txt/ctv/getcreate');
            setFieldsData(prevFieldsData => ({
                ...prevFieldsData,
                ssps: fieldsArray.find(item => item.key === 'ssps').value,
                platforms: fieldsArray.find(item => item.key === 'platforms').value,
                country: fieldsArray.find(item => item.key === 'countries').value,
                bundles: fieldsArray.find(item => item.key === 'bundles').value,
                categories: fieldsArray.find(item => item.key === 'categories').value,
            }));
        };

        fetchCreateData();
    }, []);

    if (!data || data.length === 0) {
        return <div></div>;
    }

    const handleClickOpen = (fields) => {
        console.log(fields);
        if (fields.value.ssp_name) {
            const ssp = fieldsData.ssps.find(item => item.ssp_name === fields.value.ssp_name);
            fields.value.ssp = ssp ? ssp.ssp_id : '';
        }

        if (fields.value.direct_reseller) {
            fields.value.relationship = fields.value.direct_reseller;
            delete fields.value.direct_reseller;
        }

        if (fieldsData.bundles) {
            const bundle = fieldsData.bundles.find(item => item.bundle_id === fields.value.bundle_id);
            fields.value.bundle_id = bundle ? bundle.id : '';
        }
        fields.value.platform = [];
        if (fieldsData.country) {
            const country = fieldsData.country.find(item => item.name === fields.value.country);
            fields.value.country = country ? country.id : '';
        }

        setEditEntity(fields.value);
        setOpen(true);
    };

    const handleClickDeleteOpen = (item) => {
        setDeleteEntity(item.value);
        setDeleteOpen(true);
    };

    const handleCloseDelete = () => {
        setDeleteOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const openSendSuccess = () => {
        setOpenSend(true);
    };

    const openUpdateSuccess = () => {
        setOpenUpdate(true);
    };

    const handleCloseSend = () => {
        window.location.reload();
        setOpenSend(false);
    };
    const handleCloseUpdate = () => {
        window.location.reload();
        setOpenUpdate(false);
    };

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setEditEntity({...editEntity, [name]: value});
    };

    const handleSave = () => {
        const response = sendDataToApi('post','/api/report-ads-txt/ctv/update', editEntity, false, (data) => {
            resetEditEntity();
            setOpen(false);
            openUpdateSuccess();
        });
    };

    const handleDelete = () => {
        const response = sendDataToApi('post','/api/report-ads-txt/ctv/delete', deleteEntity, false, (data) => {
            setDeleteOpen(false);
            openSendSuccess();
        });
    };


    return (
        <>
            <TableContainer component={Paper} sx={{
                padding: 2,
                backgroundColor: 'white',//'white',
                borderRadius: '8px',
                border: '6px',
                paddingLeft: 0,
                paddingTop: 0,
                marginLeft: '0px',
                marginTop: '10px',
                boxShadow: '0 0 1px rgba(0, 0, 0, 0.2)',
            }}>
                <Table>
                    <TableHead sx={{backgroundColor: '#f0f3f5',}}>
                        <TableRow>
                            {tittle.map((key) => (
                                <StyledTableHeadCell key={key}>{key}</StyledTableHeadCell>
                            ))}
                            <StyledTableHeadCell key="edit-header"></StyledTableHeadCell>
                            <StyledTableHeadCell key="delete-header"></StyledTableHeadCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {entitys.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((row) => (
                            <TableRow key={row.key}>
                                {keys.map((key) => (
                                    <StyledTableCell key={key}>
                                        {
                                            row.value[key]
                                        }
                                    </StyledTableCell>
                                ))}
                                <StyledTableCell>
                                    <Button variant="outlined" startIcon={<EditIcon/>} color="primary" size="medium"
                                            onClick={() => handleClickOpen(row)}>
                                        Edit
                                    </Button>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Button variant="outlined" startIcon={<DeleteOutlineIcon/>} color="error"
                                            size="medium"
                                            onClick={() => handleClickDeleteOpen(row)}>
                                        Delete
                                    </Button>
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Pagination count={Math.ceil(entitys.length / rowsPerPage)} page={page} onChange={handleChange}/>
            </TableContainer>


            <Dialog open={openUpdate} onClose={handleCloseUpdate}>
                <Alert severity="success">
                    <AlertTitle>Row <strong>updated</strong> successfully!</AlertTitle>
                </Alert>
            </Dialog>

            <Dialog open={openSend} onClose={handleCloseSend}>
                <Alert severity="success">
                    <AlertTitle>Row <strong>deleted</strong> successfully!</AlertTitle>
                </Alert>
            </Dialog>

            <Dialog open={deleteOpen} onClose={handleCloseDelete}>
                <Alert severity="error"
                >
                    <AlertTitle>Delete row with ID <strong>{deleteEntity.id}</strong>?</AlertTitle>
                    <Button sx={{margin: '0 10px'}} variant="outlined" color="error"
                            onClick={handleCloseDelete}>No</Button>
                    <Button variant="contained" color="error" onClick={handleDelete}>Yes</Button>
                </Alert>
            </Dialog>

            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogTitle>Edit row</DialogTitle>
                <DialogContent>
                    <Box mb={2}>
                        <FormControl fullWidth>
                            <InputLabel id="ssp-label">SSP</InputLabel>
                            <Select
                                labelId="ssp-label"
                                name="ssp"
                                value={editEntity.ssp}
                                onChange={handleInputChange}
                                required
                            >
                                <MenuItem value="0">---</MenuItem>
                                {fieldsData.ssps.map((ssp) => (
                                    <MenuItem value={ssp.id}>{ssp.ssp_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mb={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isNewFieldOpen}
                                    onChange={handleNewFieldToggle}
                                    color="primary"
                                />
                            }
                            label="Add new SSP"
                        />
                    </Box>
                    {isNewFieldOpen && (
                        <>
                            <Box mb={2}>
                                <TextField
                                    name="newSspId"
                                    value={editEntity.newSspId}
                                    onChange={handleInputChange}
                                    label="New SSP ID"
                                    fullWidth
                                />
                            </Box>
                            <Box mb={2}>
                                <TextField
                                    name="newSspName"
                                    value={editEntity.newSspName}
                                    onChange={handleInputChange}
                                    label="New SSP Name"
                                    fullWidth
                                />
                            </Box>
                        </>
                    )}
                    <Box mb={2}>
                        <FormControl fullWidth>
                            <InputLabel id="platform-label">Platform</InputLabel>
                            <Select
                                labelId="platform-label"
                                name="platform"
                                multiple
                                value={editEntity.platform}
                                onChange={handleInputChange}
                                renderValue={(selected) => (
                                    selected.map((value) => fieldsData.platforms.find((platform) => platform.id === value)).join(', ')
                                )}
                                required
                            >
                                {fieldsData.platforms.map((platform) => (
                                    <MenuItem value={platform.id}>{platform.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mb={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isNewFieldPlatformOpen}
                                    onChange={handleNewPlatformToggle}
                                    color="primary"
                                />
                            }
                            label="Add new Platform"
                        />
                    </Box>
                    {isNewFieldPlatformOpen && (
                        <>
                        <Box mb={2}>
                            <TextField
                                name="newPlatform"
                                value={editEntity.newPlatform}
                                onChange={handleInputChange}
                                label="New Platform"
                                fullWidth
                            />
                        </Box>
                        </>
                    )}
                    <Box mb={2}>
                        <TextField
                            name="usd"
                            value={editEntity.usd}
                            onChange={handleInputChange}
                            label="USD Delta"
                            fullWidth
                            required
                        />
                    </Box>
                    <Box mb={2}>
                        <FormControl fullWidth>
                            <InputLabel id="relationship-label">Direct/Reseller</InputLabel>
                            <Select
                                labelId="relationship-label"
                                name="relationship"
                                value={editEntity.relationship}
                                onChange={handleInputChange}
                                required
                            >
                                <MenuItem value="DIRECT">DIRECT</MenuItem>
                                <MenuItem value="RESELLER">RESELLER</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mb={2}>
                        <FormControl fullWidth>
                            <InputLabel id="country-label">Country</InputLabel>
                            <Select
                                labelId="country-label"
                                name="country"
                                value={editEntity.country}
                                onChange={handleInputChange}
                                required
                            >
                                <MenuItem value="0">---</MenuItem>
                                {fieldsData.country.map((geo) => (
                                    <MenuItem value={geo.id}>{geo.three_char_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mb={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isNewFieldDeveloperOpen}
                                    onChange={handleNewDeveloperToggle}
                                    color="primary"
                                />
                            }
                            label="Add new Developer"
                        />
                    </Box>
                    {isNewFieldDeveloperOpen && (
                        <>
                        <Box mb={2}>
                            <TextField
                                name="newDeveloper"
                                value={editEntity.newDeveloper}
                                onChange={handleInputChange}
                                label="New Developer"
                                fullWidth
                            />
                        </Box>
                        </>
                    )}
                    <Box mb={2}>
                        <FormControl fullWidth>
                            <InputLabel id="bundle-label">Bundle</InputLabel>
                            <Select
                                labelId="bundle-label"
                                name="bundle"
                                value={editEntity.bundle_id}
                                onChange={handleInputChange}
                                required
                            >
                                <MenuItem value="0">---</MenuItem>
                                {fieldsData.bundles.map((bundle) => (
                                    <MenuItem value={bundle.id}>{bundle.bundle_id}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mb={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isNewFieldBundleOpen}
                                    onChange={handleNewBundleToggle}
                                    color="primary"
                                />
                            }
                            label="Add new Bundle"
                        />
                    </Box>
                    {isNewFieldBundleOpen && (
                        <>
                        <Box mb={2}>
                            <TextField
                                name="newBundle"
                                value={editEntity.newBundle}
                                onChange={handleInputChange}
                                label="New Bundle"
                                fullWidth
                            />
                        </Box>
                        </>
                    )}
                    <Box mb={2}>
                        <FormControl fullWidth>
                            <InputLabel id="category-label">Category</InputLabel>
                            <Select
                                labelId="category-label"
                                name="category"
                                value={editEntity.category}
                                onChange={handleInputChange}
                                required
                            >
                                <MenuItem value="0">---</MenuItem>
                                {fieldsData.categories.map((category) => (
                                    <MenuItem value={category.id}>{category.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box mb={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isNewFieldCategoryOpen}
                                    onChange={handleNewCategoryToggle}
                                    color="primary"
                                />
                            }
                            label="Add new Category"
                        />
                    </Box>
                    {isNewFieldCategoryOpen && (
                        <>
                        <Box mb={2}>
                            <TextField
                                name="newCategory"
                                value={editEntity.newCategory}
                                onChange={handleInputChange}
                                label="New Category"
                                fullWidth
                            />
                        </Box>
                        </>
                    )}
                    <Box mb={2}>
                        <TextField
                            name="appName"
                            value={editEntity.appName}
                            onChange={handleInputChange}
                            label="App Name"
                            fullWidth
                            required
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            name="appUrl"
                            value={editEntity.appUrl}
                            onChange={handleInputChange}
                            label="App URL"
                            fullWidth
                            required
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            name="adsTxt"
                            value={editEntity.adsTxt}
                            onChange={handleInputChange}
                            label="App-ads.txt URL"
                            fullWidth
                            required
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSave}>Save</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openSend} onClose={handleCloseSend}>
                <Alert severity="success">
                    <AlertTitle>Success!</AlertTitle>
                    Entity <strong>updated</strong> successfully!
                </Alert>
            </Dialog>
        </>
    );
};

export default CvtTable;
