import React, {useState, useEffect, useRef} from 'react';
import {
    Button,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControlLabel,
    Checkbox,
    CircularProgress,
    Box,
    InputLabel,
    Select,
    MenuItem,
    Chip,
    FormControl,
    OutlinedInput,
} from '@mui/material';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import {
    fetchStatFields, fetchDataFields, fetchPublisherFields, fetchDSPFields
} from '../../../API/GeoEdge';
import {sendDataToApi} from '../../../API/apiHelper';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import Cookies from 'js-cookie';
import {toast} from 'react-toastify';
import {DateRangePicker} from 'react-date-range';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 350,
        },
    },
};

function MainReportOptionsForm({onGenerate, getOptions}) {

    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];

    const [options, setOptions] = useState({
        stats: [],
        start_date: formattedDate,
        end_date: formattedDate,
        timeInterval: [],
        filterPub: [],
        filterDsp: [],
    });

    const [loading, setLoading] = useState(false);
    const [statistics, setStatistics] = useState([]);
    const [entitys, setEntitys] = useState([]);

    const [dspFilter, setDspFilter] = useState([]);
    const [selectedDspFilter, setSelectedDspFilter] = useState([]);

    const [pubFilter, setPubFilter] = useState([]);
    const [selectedPubFilter, setSelectedPubFilter] = useState([]);

    const [showPublishers, setShowPublishers] = useState(false);
    const [showDsp, setShowDsp] = useState(false);

    const [timeInterval, setTimeInterval] = useState([]);
    const [selectedInterval, setSelectedInterval] = useState('');
    const dropDownRef = useRef(null);
    const pickerRef = useRef(null);
    const [selectedRange, setSelectedRange] = useState({
        startDate: new Date(options.start_date),
        endDate: new Date(options.start_date),
        key: 'selection',
    });

    const [showCalendar, setShowCalendar] = useState(false);
    const handleClickOutside = (event) => {
        if (pickerRef.current && !pickerRef.current.contains(event.target) && !dropDownRef.current.contains(event.target)) {
            setShowCalendar(false);
        }

    };

    useEffect(() => {
        const storedValue = Cookies.get('ProfitReport');
        if (storedValue) {
            const parsedValue = JSON.parse(storedValue);

            if ('stats' in parsedValue) {
                setOptions(prevOptions => ({
                    ...prevOptions,
                    stats: parsedValue.stats
                }));
            }
            if ('start_date' in parsedValue) {
                setOptions(prevOptions => ({
                    ...prevOptions,
                    start_date: parsedValue.start_date
                }));
            }
            if ('end_date' in parsedValue) {
                setOptions(prevOptions => ({
                    ...prevOptions,
                    end_date: parsedValue.end_date
                }));
            }
            if ('timeInterval' in parsedValue) {
                setTimeInterval(parsedValue.timeInterval);
                setSelectedInterval(parsedValue.timeInterval);
                setOptions(prevOptions => ({
                    ...prevOptions,
                    timeInterval: parsedValue.timeInterval
                }));
            }
            if ('filter' in parsedValue && Array.isArray(parsedValue.filter)) {
                const filterArray = parsedValue.filter[0];

                if (filterArray && filterArray.length > 0) {
                    setSelectedDspFilter(prevFilters => ([...filterArray]));
                }
            }
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleCheckboxChange = (event) => {
        const {name} = event.target;
        setOptions({...options, fields: name});
    };

    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            filterDsp: [selectedDspFilter],
        }));
    }, [selectedDspFilter]);

    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            filterPub: [selectedPubFilter],
        }));
    }, [selectedPubFilter]);

    const handleIntervalChange = (event) => {
        const {name} = event.target;

        if (selectedInterval === name) {
            setSelectedInterval('');
            setOptions((prevOptions) => ({
                ...prevOptions,
                timeInterval: [],
            }));
        } else {
            setSelectedInterval(name);
            setOptions((prevOptions) => ({
                ...prevOptions,
                timeInterval: [name],
            }));
        }
    };

    useEffect(() => {
        const fetchStatistics = async () => {
            const stats = await fetchStatFields();
            setStatistics(stats);

        };

        const fetchEntitys = async () => {
            const data = await fetchDataFields();
            setEntitys(data);
        };

        const fetchDSP = async () => {
            const dsp = await fetchDSPFields();
            const modifiedArray = dsp.map((item) => {
                return {
                    key: item.value.adv_id,
                    value: item.value.adv_name
                };
            });
            setDspFilter(modifiedArray);
        };

        const fetchPub = async () => {
            const pub = await fetchPublisherFields();
            const modifiedArray = pub.map((item) => {
                return {
                    key: item.value.publisher_id,
                    value: item.value.publisher_name
                };
            });
            setPubFilter(modifiedArray);
        };

        fetchPub();
        fetchDSP();
        fetchEntitys();
        fetchStatistics();
    }, []);

    const handleCheckboxChangeStat = (event) => {
        const {name, checked} = event.target;
        let stats = [...options.stats];

        if (checked) {
            stats.push(name);
        } else {
            stats = stats.filter((satat) => satat !== name);
        }

        setOptions({...options, stats});
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);

            const response = await sendDataToApi('post', '/api/geoedge/main/report', options, 'MainReport');
            console.log(response);
            if (Object.keys(response.data).length === 0) {
                onGenerate(false);
            } else {
                const fieldsArray = Object.entries(response.data).map(([key, value]) => ({key, value}));
                onGenerate(fieldsArray);
            }
            setLoading(false);
        } catch (err) {
            toast.error(`Oops, there seems to be an error on the server. The technical team has already received information about this. We apologize for the inconvenience and we will let you know when it is fixed.`);
            console.error(err);
            setLoading(false);
        }
    };
    const handleChangeName = (event) => {
        setSelectedDspFilter(event.target.value);
    };

    const handleChangePub = (event) => {
        setSelectedPubFilter(event.target.value);
    };

    const toggleCalendar = () => {

        if (showCalendar === false) {
            setShowCalendar(true);
        } else {
            setShowCalendar(false);
        }
    };

    const handleDateChange = (event) => {
        const endDate = new Date(event.selection.endDate);
        endDate.setHours(12, 12, 12, 12);
        const endDateISOString = endDate.toISOString().split('T')[0];

        const startDate = new Date(event.selection.startDate);
        startDate.setHours(12, 12, 12, 12);
        const startDateISOString = startDate.toISOString().split('T')[0];

        setSelectedRange((prevSelectedRange) => ({
            ...prevSelectedRange,
            startDate: new Date(startDateISOString),
            endDate: new Date(endDateISOString),
            key: 'selection',
        }));

        if (endDate && startDate) {
            setOptions((prevOptions) => ({
                ...prevOptions,
                start_date: startDateISOString,
                end_date: endDateISOString,
            }));
        }
    };

    return (
        <Grid container spacing={2} sx={{width: "100%", marginLeft: '0px',}}>
            <Accordion sx={{width: "100%"}}>
                <AccordionSummary
                    expandIcon={<KeyboardDoubleArrowDownOutlinedIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{backgroundColor: '#f0f3f5', width: "100%"}}
                >
                    Options
                </AccordionSummary>
                <AccordionDetails sx={{width: "100%"}}>
                    <form onSubmit={handleSubmit}>
                        <Grid item xs={12} md={12} sx={{width: "100%"}}>
                            <Grid item xs={12} md={12} sx={{width: "100%", margin: '25px 0 25px auto'}}>
                                <Grid item xs={12} md={12} sx={{margin: '25px 0 25px auto'}}>
                                    <Grid item xs={12} md={12}>
                                        {Object.keys(statistics).length != 0 ? (<strong>Data</strong>) : ''}
                                    </Grid>
                                    {entitys.map((statistic, index) => (
                                        (
                                            <FormControlLabel
                                                key={index}
                                                control={
                                                    <Checkbox
                                                        checked={options.stats.includes(statistic.key)}
                                                        onChange={handleCheckboxChangeStat}
                                                        name={statistic.key}
                                                    />
                                                }
                                                label={statistic.value.replace(/_/g, " ")}
                                            />)
                                    ))}
                                </Grid>
                                <Grid item xs={12} md={12} sx={{margin: '25px 0 25px auto'}}>
                                    <Grid item xs={12} md={12}>
                                        {Object.keys(statistics).length != 0 ? (<strong>Statistics</strong>) : ''}
                                    </Grid>
                                    {statistics.map((statistic, index) => (
                                        (
                                            <FormControlLabel
                                                key={index}
                                                control={
                                                    <Checkbox
                                                        checked={options.stats.includes(statistic.key)}
                                                        onChange={handleCheckboxChangeStat}
                                                        name={statistic.key}
                                                    />
                                                }
                                                label={statistic.value.replace(/_/g, " ")}
                                            />)
                                    ))}
                                </Grid>

                                <Grid item xs={12} md={12} sx={{margin: '25px 0 25px auto'}}>
                                    <Grid item xs={12} md={12}>
                                        <strong>Time Interval</strong>
                                    </Grid>
                                    {['day', 'week', 'month'].map((interval, index) => (
                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <Checkbox
                                                    //checked={timeInterval.includes(interval)}
                                                    checked={selectedInterval === interval}
                                                    onChange={handleIntervalChange}
                                                    name={interval}
                                                />
                                            }
                                            label={interval.charAt(0).toUpperCase() + interval.slice(1)}
                                        />
                                    ))}
                                </Grid>

                                {Object.keys(pubFilter).length != 0 && Object.keys(dspFilter).length != 0 ? (
                                    <Grid item xs={12} md={12} sx={{width: "100%", margin: '10px 0 10px auto'}}>
                                        <strong>Filters</strong>
                                    </Grid>
                                ) : ''}

                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        {Object.keys(pubFilter).length != 0 ? (<FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={showPublishers}
                                                    onChange={() => setShowPublishers(!showPublishers)}
                                                    name="publishers"
                                                />
                                            }
                                            label="Publishers"
                                        />) : ''}

                                        {Object.keys(dspFilter).length != 0 ? (<FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={showDsp}
                                                    onChange={() => setShowDsp(!showDsp)}
                                                    name="DSP"
                                                />
                                            }
                                            label="DSP"
                                        />) : ''}
                                    </Grid>


                                    <Grid item xs={6}>
                                        {showPublishers && (
                                            <FormControl sx={{m: 1, width: 510}}>
                                                <InputLabel id="demo-multiple-chip-label">Publisher</InputLabel>
                                                <Select
                                                    labelId="demo-multiple-chip-label"
                                                    id="demo-multiple-chip"
                                                    multiple
                                                    value={selectedPubFilter}
                                                    onChange={handleChangePub}
                                                    input={<OutlinedInput id="select-multiple-chip" label="User"/>}
                                                    renderValue={(selected) => (
                                                        <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                                            {selected.map((select) => (
                                                                <Chip key={select}
                                                                      label={pubFilter.find((item) => item.value === select)?.value}/>
                                                            ))}
                                                        </Box>
                                                    )}
                                                    MenuProps={MenuProps}
                                                >
                                                    {pubFilter.map((item) => (
                                                        <MenuItem key={item.value} value={item.value}>
                                                            {item.value}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                    </Grid>

                                    <Grid item xs={6}>
                                        {showDsp && (
                                            <FormControl sx={{m: 1, width: 510}}>
                                                <InputLabel id="demo-multiple-chip-label">DSP</InputLabel>
                                                <Select
                                                    labelId="demo-multiple-chip-label"
                                                    id="demo-multiple-chip"
                                                    multiple
                                                    value={selectedDspFilter}
                                                    onChange={handleChangeName}
                                                    input={<OutlinedInput id="select-multiple-chip" label="User"/>}
                                                    renderValue={(selected) => (
                                                        <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                                            {selected.map((select) => (
                                                                <Chip key={select}
                                                                      label={dspFilter.find((item) => item.value === select)?.value}/>
                                                            ))}
                                                        </Box>
                                                    )}
                                                    MenuProps={MenuProps}
                                                >
                                                    {dspFilter.map((item) => (
                                                        <MenuItem key={item.value} value={item.value}>
                                                            {item.value}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </AccordionDetails>
            </Accordion>

            <Grid item xs={6} md={6}>
                <Button
                    size={"large"}
                    startIcon={<BackupTableOutlinedIcon fontSize="small"/>}
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{marginLeft: '-15px'}}
                >
                    Generate Report
                </Button>
            </Grid>


            <Grid item xs={6} md={6}>
                <div>
                    <div
                        ref={dropDownRef}
                        style={{
                            userSelect: 'none',
                            borderRadius: '5px',
                            textAlign: "center",
                            backgroundColor: '#f0f3f5',
                            padding: '10px',
                            cursor: 'pointer',
                            float: 'right',
                            border: '1px solid #dddfe1',
                        }}
                        onClick={toggleCalendar}
                    >
                        <div>Date Range</div>
                        <div>
                            {options.start_date} - {options.end_date}
                        </div>
                    </div>
                    {showCalendar && (
                        <div
                            ref={pickerRef}
                            style={{border: '15px', boxShadow: '15px 15px 20px rgba(0, 0, 0, 0.3)',}}
                        >
                            <DateRangePicker
                                ranges={[selectedRange]}
                                onChange={handleDateChange}
                                moveRangeOnFirstSelection={false}
                                showSelectionPreview={true}
                            />
                        </div>
                    )}
                </div>
            </Grid>

            {loading &&
                <Grid item xs={12} md={12}>
                    <Box justifyContent="center" textAlign={"center"} alignItems="center">
                        <CircularProgress size={200}/>
                    </Box>
                </Grid>}
        </Grid>
    );
}

export default MainReportOptionsForm;
