import React, {useState, useEffect} from 'react';
import {
    Button,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControlLabel,
    Checkbox,
    CircularProgress,
    Box,
    InputLabel,
    Radio,
    Alert,
    AlertTitle,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Select,
    MenuItem,
    Chip,
    FormControl,
    OutlinedInput,
    InputAdornment,
    FormHelperText,
} from '@mui/material';
import useApi from '../../../../hooks/useApi';
import '@nosferatu500/react-sortable-tree/style.css';
import SortableTree from '@nosferatu500/react-sortable-tree';
import {
    fetchUsersTree, fetchUsers
} from '../../../../API/UserHierarchy';
import {toast} from 'react-toastify';
import {sendJsonToApi} from '../../../../API/apiHelper';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SelectMulti from '../../../../components/select/SelectMulti';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import EditUserDialog from "../../../../components/dialog/EditUserDialog";


function UserHierarchyTree() {

    const [treeData, setTreeData] = useState([]);
    const [editUser, setEditUser] = useState([]);
    const [editNode, setEditNode] = useState([]);
    const [filterUsers, setFilterUsers] = useState([]);
    const [oldTreeData, setOldTreeData] = useState([]);
    const [options, setOptions] = useState({users: []});
    const [moveTreeData, setMoveTreeData] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [initialEditUser, setInitialEditUser] = useState({});

    const [openSend, setOpenSend] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openEditUser, setOpenEditUser] = useState(false);
    const [openAddAction, setOpenAddAction] = useState(false);
    const [openMoveAction, setOpenMoveAction] = useState(false);

    const {data: users, loading: loadingUsers} = useApi(fetchUsers);
    const {data: initialTreeData, loading: loadingStats} = useApi(fetchUsersTree);

    const handleSubmit = async (newTreeData) => {
        try {
            const updatedTreeData = {
                tree: newTreeData,
                ChangedBy: localStorage.getItem('user_id')
            };

            const response = sendJsonToApi('post', '/api/users/tree/update', updatedTreeData);
        } catch (err) {
            toast.error(`Oops, there seems to be an error on the server. The technical team has already received information about this. We apologize for the inconvenience and we will let you know when it is fixed.`);
            console.error(err);
        }
    };

    const handleTreeChange = newTreeData => {
        setOldTreeData(treeData);
        setTreeData(newTreeData);
    }

    const handleNodeMove = moveTreeData => {
        handleOpenMoveAction(true);
        setMoveTreeData(moveTreeData.treeData);
    }

    const handleOpenMoveAction = () => {
        setOpenMoveAction(true);
    };

    const handleCloseMoveAction = () => {
        setTreeData(oldTreeData);
        setOpenMoveAction(false);
    };

    const handleSaveMoveAction = () => {
        setTreeData(moveTreeData);
        handleSubmit(moveTreeData);
        setOpenMoveAction(false);
    };

    const handleEditUserOpen = (user) => {
        setInitialEditUser(user);
        setOpenEditUser(true);
    };

    const handleEditUserClose = () => {
        setOpenEditUser(false);
    };

    const replaceItemById = (treeData, updatedUser) => {
        return treeData.map(node => {
            if (node.id === updatedUser.id) {
                return updatedUser;
            } else if (node.children) {
                const updatedChildren = replaceItemById(node.children, updatedUser);
                return {
                    ...node,
                    children: updatedChildren,
                };
            }
            return node;
        });
    };

    const handleSaveUser = (updatedUser) => {
        const updatedTreeData = [...treeData];
        const updatedTreeDataWithReplacement = replaceItemById(updatedTreeData, updatedUser);
        setTreeData(updatedTreeDataWithReplacement);

        openSendSuccess();
    };

    const openSendSuccess = () => {
        setOpenSend(true);
    };

    const handleCloseSend = () => {
        setOpenSend(false);
    };

    const handleOpenDelete = (user) => {
        setEditUser(user);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const removeItemById = (arr, idToRemove) => {
        function recursiveRemove(currentArr) {
            const newArr = [];
            for (const item of currentArr) {
                if (item.id === idToRemove) {
                    continue;
                }
                if (Array.isArray(item.children) && item.children.length > 0) {
                    item.children = recursiveRemove(item.children);
                }
                newArr.push(item);
            }
            return newArr;
        }

        return recursiveRemove(arr);
    }

    const handleSaveDelete = () => {

        const updatedUser = {
            ...editUser,
        };

        setTreeData(removeItemById(treeData , updatedUser.id));

        const updatedSentUser = {
            request: updatedUser,
            ChangedBy: localStorage.getItem('user_id')
        };

        const response = sendJsonToApi('post', '/api/users/tree/user/delete', updatedSentUser, false, (data) => {
            handleUpdateTree();
            openSendSuccess();
        });

        setOpenDelete(false);
    };

    const handleCloseAddAction = () => {
        setOpenAddAction(false);
    };

    const handleOpenAddAction = (node) => {
        const flattenedIds = [];
        const flattenChildren = (children) => {
            if (Array.isArray(children)) {
                children.forEach((child) => {
                    if (child.id) {
                        flattenedIds.push(child.id);
                    }
                });
            }
        };
        flattenChildren(node.children);
        setSelectedUsers(flattenedIds);

        setEditNode(node);
        setOpenAddAction(true);
    };

    const handleSaveAddAction = () => {
        const newUsersToNode = selectedUsers.map((user) => ({
            child: user,
            parent: editNode.id,
        }));

        const updatedNode = {
            newNode:newUsersToNode,
            ChangedBy: localStorage.getItem('user_id')
        };

        const response = sendJsonToApi('post', '/api/users/tree/user/add', updatedNode, false, (data) => {
            handleUpdateTree();
            openSendSuccess();
        });
        setOpenAddAction(false);
    };

    const handleUpdateTree = async () => {
        const updatedData = await fetchUsersTree();

        if (updatedData && updatedData.length > 0) {
            const valuesFromTreeData = updatedData.map(item => item.value);
            setTreeData(valuesFromTreeData);
        }
        const updatedUsers = await fetchUsers();

        if (updatedUsers && updatedUsers.length > 0) {
            setFilterUsers(updatedUsers);
        }
    }

    useEffect(() => {
        if (initialTreeData && initialTreeData.length > 0) {
            const valuesFromTreeData = initialTreeData.map(item => item.value);
            setTreeData(valuesFromTreeData);
        }
    }, [initialTreeData]);

    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            users: [selectedUsers],
        }));
    }, [selectedUsers]);

    useEffect(() => {
        if (users && users.length > 0) {
            setFilterUsers(users);
        }
    }, [users]);

    return (
        <Grid container spacing={2} sx={{width: "100%", marginLeft: '0px',}}>
            <Grid item xs={12} md={12}>
                <div style={{height: 500}} className="animated fadeIn">
                    {treeData ? (
                        <SortableTree
                            treeData={treeData}
                            onChange={handleTreeChange}
                            onMoveNode={handleNodeMove}
                            canDrop={({ node, nextParent, prevPath, nextPath }) => {
                                if (!nextParent) {
                                    return false;
                                }
                                return true;
                            }}
                            generateNodeProps={rowInfo => ({
                                buttons: [
                                    <a
                                        style={{textDecoration: 'none', color: 'inherit', marginRight: '10px'}}
                                        href='#1'
                                        onClick={() => {
                                            handleOpenDelete(rowInfo.node);
                                        }}
                                    >
                                        <i style={{color: '#F86C6B'}} className="fa fa-times-circle"></i>
                                        <DeleteOutlinedIcon
                                            fontSize="small"/>
                                    </a>,
                                    <a
                                        style={{textDecoration: 'none', color: 'inherit', marginRight: '5px'}}
                                        href='#2'
                                        onClick={() => {
                                            handleOpenAddAction(rowInfo.node);
                                        }}
                                    >
                                        <i style={{color: '#4DBD74'}} className="fa fa-plus"></i> <GroupAddOutlinedIcon
                                        fontSize="small"/>
                                    </a>
                                ],
                                title: (
                                    <span
                                        style={{textDecoration: 'none', color: 'inherit', display: 'block'}}
                                        onClick={() => {
                                            handleEditUserOpen(rowInfo.node)
                                        }}
                                    >
                                    {rowInfo.node.name}
                                </span>
                                ),
                                subtitle: (
                                    <span
                                        style={{textDecoration: 'none', color: 'inherit'}}
                                    >
                                    {rowInfo.node.position}
                                </span>
                                ),
                            })}
                        />
                    ) : ''}
                </div>
            </Grid>

            <Dialog open={openMoveAction} onClose={handleCloseMoveAction}>
                <Alert variant="outlined" severity="info" color="info">
                    <DialogContent sx={{textAlign: "center"}}>
                        <AlertTitle>You're moving tree item</AlertTitle>
                        <strong>Are you sure?</strong>
                    </DialogContent>

                    <DialogActions sx={{justifyContent: "center"}}>
                        <Button onClick={handleCloseMoveAction} variant="outlined">Decline</Button>
                        <Button onClick={handleSaveMoveAction} variant="outlined" color="success">
                            Save
                        </Button>
                    </DialogActions>

                </Alert>
            </Dialog>

            <Dialog open={openDelete} onClose={handleCloseDelete}>
                <Alert variant="outlined" severity="error" color="error">
                    <DialogContent sx={{textAlign: "center"}}>
                        <AlertTitle>Do you want remove User from hierarchy?</AlertTitle>
                        <strong>Are you sure?</strong>
                    </DialogContent>

                    <DialogActions sx={{justifyContent: "center"}}>
                        <Button onClick={handleCloseDelete} variant="outlined">Decline</Button>
                        <Button onClick={handleSaveDelete} color="error" variant="outlined">
                            Yes
                        </Button>
                    </DialogActions>

                </Alert>
            </Dialog>

            <EditUserDialog
                initialEditUser={initialEditUser}
                onClose={handleEditUserClose}
                openEditUser={openEditUser}
                onSave={handleSaveUser}
            />

            <Dialog open={openSend} onClose={handleCloseSend}>
                <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    User has updated <strong>successfully!</strong>
                </Alert>
            </Dialog>

            <Dialog open={openAddAction} onClose={handleCloseAddAction}>
                <DialogTitle>Add childs to {editNode.name}</DialogTitle>
                <DialogContent
                    sx={{
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        minHeight: '100px',
                        minWidth: '450px',
                        display: 'flex',
                        '& > :first-child': {
                            marginBottom: '30px',
                            marginTop: '10px',
                        },
                        '& > :last-child': {
                            marginBottom: '10px',
                        },
                    }}
                >
                    <SelectMulti
                        onChange={(event) => setSelectedUsers(event.target.value)}
                        selectedItems={selectedUsers}
                        itemLabel="value.name"
                        itemValue="value.id"
                        itemKey="value.id"
                        label="Users"
                        items={filterUsers}
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseAddAction}>Cancel</Button>
                    <Button onClick={handleSaveAddAction}>Save</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

export default UserHierarchyTree;