import React, {useState, useEffect, useRef} from 'react';
import {
    Button,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails, FormControlLabel, Checkbox, CircularProgress, LinearProgress, Box, svgIconClasses,TextField
} from '@mui/material';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import {DateRangePicker} from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {useLocation, useNavigate} from 'react-router-dom';

import AutocompleteWrapper from '../UI/autocomplete/AutocompleteWrapper';
import {
    fetchDataFields,
    fetchStatisticFields,
    fetchSellers,
    fetchBundles,
    fetchPlatforms,
    fetchPublishers,
} from '../../API/PixalaleReport';
import {sendDataToApi} from '../../API/apiHelper';
import axios from "axios";
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import Cookies from 'js-cookie';
import {toast} from 'react-toastify';

function ReportOptionsForm({onGenerate, getOptions}) {
    const location = useLocation();
    const navigate = useNavigate();

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 2);
    const formattedDate = yesterday.toISOString().split('T')[0];

    const queryParams = new URLSearchParams(location.search);

    const [options, setOptions] = useState({
        start_date: queryParams.get('start_date') || formattedDate,
        end_date: queryParams.get('end_date') || formattedDate,
        fields: queryParams.get('fields') ? queryParams.get('fields').split(',') : ['sellerId', 'IVT'],
        filters: queryParams.get('filters') ? JSON.parse(queryParams.get('filters')) : [],
        timeInterval: queryParams.get('timeInterval') || [],
    });

    const [fields, setFields] = useState([]);
    const [statistics, setStatistics] = useState([]);
    const [sellers, setSellers] = useState([]);
    const [bundles, setBundles] = useState([]);
    const [platform, setPlatforms] = useState([]);
    const [publishers, setPublishers] = useState([]);

    const [timeInterval, setTimeInterval] = useState([]);

    const [showSellers, setShowSellers] = useState(false);
    const [showBundles, setShowBundles] = useState(false);
    const [showPlatform, setShowPlatform] = useState(false);
    const [showPublishers, setShowPublishers] = useState(false);
    const [showImpressions, setShowImpressions] = useState(false);

    const [showCalendar, setShowCalendar] = useState(false);
    const [selectedRange, setSelectedRange] = useState({
        startDate: new Date(options.start_date),
        endDate: new Date(options.start_date),
        key: 'selection',
    });
    const [filters, setFilters] = useState([]);
    const [loading, setLoading] = useState(false);

    const dropDownRef = useRef(null);
    const pickerRef = useRef(null);
    const [selectedInterval, setSelectedInterval] = useState('');

    const handleClickOutside = (event) => {
        if (pickerRef.current && !pickerRef.current.contains(event.target) && !dropDownRef.current.contains(event.target)) {
            setShowCalendar(false);
        }

    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleReportGenerate = () => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            filters: filters,
        }));

        try {
            const searchParams = new URLSearchParams();
            searchParams.set('start_date', options.start_date);
            searchParams.set('end_date', options.end_date);
            searchParams.set('fields', options.fields.join(','));
            searchParams.set('timeInterval', options.timeInterval.join(','));

            options.filters.forEach((filter, index) => {
                searchParams.set(`filter_${index}`, JSON.stringify(filter));
            });
            navigate(`/Pixalate/IVTReport?${searchParams.toString()}`);
        } catch (err) {
            toast.error(`Oops, there seems to be an error on the server. The technical team has already received information about this. We apologize for the inconvenience and we will let you know when it is fixed.`);
            console.error(err);
        }
    }


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);

        const timeIntervalParam = queryParams.get('timeInterval');
        const filters = [];

        queryParams.forEach((value, key) => {
            if (key.startsWith('filter_')) {
                const filterIndex = key.replace('filter_', '');
                const filterValue = JSON.parse(value);
                filters[filterIndex] = filterValue;
            }
        });

        const filterNames = [];

        queryParams.forEach((value, key) => {
            if (key.startsWith('filter_')) {
                const filter = JSON.parse(value);
                filterNames.push(filter.name);
            }
        });

        setShowSellers(filterNames.includes('Sellers ID'));
        setShowBundles(filterNames.includes('Bundles ID'));
        setShowPlatform(filterNames.includes('Platform'));
        setShowPublishers(filterNames.includes('Publishers ID'));
        setShowImpressions(filterNames.includes('Impressions'));

        setTimeInterval(timeIntervalParam ? timeIntervalParam.split(',') : []);
        setFilters(filters);
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);

        const startDateParam = queryParams.get('start_date');
        const endDateParam = queryParams.get('end_date');
        const fieldsParam = queryParams.get('fields');
        const timeIntervalParam = queryParams.get('timeInterval');

        const filters = [];
        queryParams.forEach((value, key) => {
            if (key.startsWith('filter_')) {
                const filterIndex = key.replace('filter_', '');
                const filterValue = JSON.parse(value);
                filters[filterIndex] = filterValue;
            }
        });

        setOptions((prevOptions) => ({
            ...prevOptions,
            start_date: startDateParam || prevOptions.start_date,
            end_date: endDateParam || prevOptions.end_date,
            fields: fieldsParam ? fieldsParam.split(',') : prevOptions.fields,
            filters: filters.length > 0 ? filters : prevOptions.filters,
            timeInterval: timeIntervalParam ? timeIntervalParam.split(',') : prevOptions.timeInterval,
        }));

    }, [location.search]);

    useEffect(() => {
        const fetchData = async () => {
            const fields = await fetchDataFields();
            setFields(fields);
        };

        const fetchStatistics = async () => {
            const stats = await fetchStatisticFields();
            setStatistics(stats);
        };

        const fetchSellerData = async () => {
            const sellerData = await fetchSellers();
            setSellers(sellerData);
        };

        const fetchBundleData = async () => {
            const bundleData = await fetchBundles();
            setBundles(bundleData);
        };

        const fetchPlatformData = async () => {
            const platformData = await fetchPlatforms();
            setPlatforms(platformData);
        };

        const fetchPublisherData = async () => {
            const publisherData = await fetchPublishers();
            setPublishers(publisherData);
        };

        fetchData();
        fetchStatistics();
        fetchSellerData();
        fetchBundleData();
        fetchPlatformData();
        fetchPublisherData();
    }, []);

    const handleCheckboxChange = (event) => {
        const {name, checked} = event.target;
        let fields = [...options.fields];

        if (checked) {
            fields.push(name);
        } else {
            fields = fields.filter((field) => field !== name);
        }

        setOptions({...options, fields});
    };

    const handleIntervalChange = (event) => {
        const { name } = event.target;

        if (selectedInterval === name) {
            setSelectedInterval('');
            setOptions((prevOptions) => ({
                ...prevOptions,
                timeInterval: [],
            }));
        } else {
            setSelectedInterval(name);
            setOptions((prevOptions) => ({
                ...prevOptions,
                timeInterval: [name],
            }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const searchParams = new URLSearchParams();
        searchParams.set('generate', 'true');


        try {
            setLoading(true);
            const nameReplacements = {
                'Sellers ID': 'sellerId',
                'Sellers Name': 'sellerName',
                'Platform': 'partnerId',
                'Bundles ID': 'kv18',
                'Publishers ID': 'publisherId',
            };

            const updatedFilters = filters.map(filter => {
                const {name, values} = filter;
                const newName = nameReplacements[name] || name;
                return {name: newName, values};
            });

            const filtersJsonString = JSON.stringify(updatedFilters);
            const updatedOptions = {...options, filters: filtersJsonString};
            getOptions(updatedOptions);
            const response = await sendDataToApi('post','/api/pixalate/ivtreport', updatedOptions);
            if (Object.keys(response.data).length === 0) {
                onGenerate(false);
            } else {
                const fieldsArray = Object.entries(response.data).map(([key, value]) => ({key, value}));
                onGenerate(fieldsArray);
            }
            setLoading(false);

        } catch (err) {
            toast.error(`Oops, there seems to be an error on the server. The technical team has already received information about this. We apologize for the inconvenience and we will let you know when it is fixed.`);
            console.error(err);
            setLoading(false);
        }
    };

    const handleDateChange = (event) => {
        const endDate = new Date(event.selection.endDate);
        endDate.setHours(12, 12, 12, 12);
        const endDateISOString = endDate.toISOString().split('T')[0];

        const startDate = new Date(event.selection.startDate);
        startDate.setHours(12, 12, 12, 12);
        const startDateISOString = startDate.toISOString().split('T')[0];

        setSelectedRange((prevSelectedRange) => ({
            ...prevSelectedRange,
            startDate: new Date(startDateISOString),
            endDate: new Date(endDateISOString),
            key: 'selection',
        }));

        if (endDate && startDate) {
            setOptions((prevOptions) => ({
                ...prevOptions,
                start_date: startDateISOString,
                end_date: endDateISOString,
            }));
        }
    };

    const toggleCalendar = () => {

        if (showCalendar === false) {
            setShowCalendar(true);
        } else {
            setShowCalendar(false);
        }
    };

    const handleInputChange = (name, selectedOptions) => {
        const selectedValues = selectedOptions.map((option) =>
            typeof option === 'string' ? option : option.value
        );

        setFilters((prevFilters) => {
            const filterIndex = prevFilters.findIndex((filter) => filter.name === name);

            if (filterIndex !== -1) {
                const newFilters = [...prevFilters];
                newFilters[filterIndex] = {
                    ...newFilters[filterIndex],
                    values: Array.from(new Set([...newFilters[filterIndex].values, ...selectedValues])),
                };
                return newFilters;
            } else {
                const filter = {
                    name: name,
                    values: Array.from(new Set(selectedValues)),
                };
                return [...prevFilters, filter];
            }
        });
    };

    const handleDelete = (name, valueToDelete) => {
        setFilters((prevFilters) => {
            const filterIndex = prevFilters.findIndex((filter) => filter.name === name);

            if (filterIndex !== -1) {
                const newFilters = [...prevFilters];
                newFilters[filterIndex] = {
                    ...newFilters[filterIndex],
                    values: newFilters[filterIndex].values.filter((value) => value !== valueToDelete),
                };
                return newFilters;
            } else {
                return prevFilters;
            }
        });
    };

    useEffect(() => {
        if (JSON.stringify(options.filters) !== JSON.stringify(filters)) {
            setOptions((prevOptions) => ({
                ...prevOptions,
                filters: filters,
            }));
        }

        const queryParams = new URLSearchParams();

        queryParams.set('start_date', options.start_date);
        queryParams.set('end_date', options.end_date);
        queryParams.set('fields', options.fields.join(','));
        queryParams.set('timeInterval', options.timeInterval);

        options.filters.forEach((filter, index) => {
            queryParams.set(`filter_${index}`, JSON.stringify(filter));
        });

        navigate(`?${queryParams.toString()}`);
    }, [filters, options, navigate]);

    const isOptionSelected = (option) => {
        for (let filter of filters) {
            if (filter.values.includes(option.value)) {
                return true;
            }
        }
        return false;
    };

    const handleInputImpressionsChange = (event) => {
        const { name, value } = event.target;

        setFilters((prevFilters) => {
            const newFilters = prevFilters.map((filter) =>
                filter.name === name
                    ? { ...filter, values: value }
                    : filter
            );

            return newFilters.some(filter => filter.name === name)
                ? newFilters
                : [...newFilters, { name, values: value }];
        });
    };


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);

        const timeIntervalParam = queryParams.get('timeInterval');

        setSelectedInterval(timeIntervalParam);
    }, []);

    return (
        <Grid container spacing={2} sx={{width: "100%", marginLeft: '0px',}}>
            <Accordion sx={{width: "100%"}}>
                <AccordionSummary
                    expandIcon={<KeyboardDoubleArrowDownOutlinedIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{backgroundColor: '#f0f3f5', width: "100%"}}
                >
                    Options
                </AccordionSummary>
                <AccordionDetails sx={{width: "100%"}}>
                    <form onSubmit={handleSubmit}>
                        <Grid item xs={12} md={12} sx={{width: "100%"}}>
                            <Grid item xs={12} md={12} sx={{width: "100%"}}>
                                <Grid item xs={12} md={12}>
                                    {Object.keys(fields).length != 0 ? (<strong>Data</strong>) : ''}
                                </Grid>
                                {fields.map((field, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                                checked={options.fields.includes(field.key)}
                                                onChange={handleCheckboxChange}
                                                name={field.key}
                                            />
                                        }
                                        label={field.value.replace(/_/g, " ")}
                                    />
                                ))}
                            </Grid>
                            <Grid item xs={12} md={12} sx={{margin: '25px 0 25px auto'}}>

                                <Grid item xs={12} md={12}>
                                    {Object.keys(statistics).length != 0 ? (<strong>Statistics</strong>) : ''}
                                </Grid>
                                {statistics.map((statistic, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                                checked={options.fields.includes(statistic.key)}
                                                onChange={handleCheckboxChange}
                                                name={statistic.key}
                                            />
                                        }
                                        label={statistic.value.replace(/_/g, " ")}
                                    />
                                ))}

                            </Grid>
                            <Grid item xs={12} md={12} sx={{margin: '25px 0 25px auto'}}>
                                <Grid item xs={12} md={12}>
                                    <strong>Time Interval</strong>
                                </Grid>
                                {['day', 'week', 'month', 'year'].map((interval, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                                checked={selectedInterval === interval}
                                                onChange={handleIntervalChange}
                                                name={interval}
                                            />
                                        }
                                        label={interval.charAt(0).toUpperCase() + interval.slice(1)}
                                    />
                                ))}
                            </Grid>

                            <Grid item xs={12} md={12}>
                                {Object.keys(sellers).length != 0 ||
                                Object.keys(bundles).length != 0 ||
                                Object.keys(platform).length != 0 ||
                                Object.keys(publishers).length != 0 ? (<strong>Filters</strong>) : ''}
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    {Object.keys(sellers).length != 0 ? (<FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={showSellers}
                                                onChange={() => setShowSellers(!showSellers)}
                                                name="sellers"
                                            />
                                        }
                                        label="Sellers ID"
                                    />) : ''}
                                    {Object.keys(bundles).length != 0 ? (<FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={showBundles}
                                                onChange={() => setShowBundles(!showBundles)}
                                                name="bundles"
                                            />
                                        }
                                        label="Bundles ID"
                                    />) : ''}
                                    {Object.keys(platform).length != 0 ? (<FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={showPlatform}
                                                onChange={() => setShowPlatform(!showPlatform)}
                                                name="platform"
                                            />
                                        }
                                        label="Platform"
                                    />) : ''}
                                    {Object.keys(publishers).length != 0 ? (<FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={showPublishers}
                                                onChange={() => setShowPublishers(!showPublishers)}
                                                name="publishers"
                                            />
                                        }
                                        label="Publishers ID"
                                    />) : ''}
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={showImpressions}
                                                onChange={() => setShowImpressions(!showImpressions)}
                                                name="publishers"
                                            />
                                        }
                                        label="Impressions"
                                    />
                                </Grid>


                                <Grid item xs={6}>
                                    {showSellers && (
                                        <AutocompleteWrapper
                                            optionName="Sellers ID"
                                            options={sellers}
                                            handleInputChange={handleInputChange}
                                            handleDelete={handleDelete}
                                            isOptionSelected={isOptionSelected}
                                            currentFilter={filters.find((f) => f.name === 'Sellers ID')}
                                        />)}
                                </Grid>
                                <Grid item xs={6}>
                                    {showBundles && (
                                        <AutocompleteWrapper
                                            optionName="Bundles ID"
                                            options={bundles}
                                            handleInputChange={handleInputChange}
                                            handleDelete={handleDelete}
                                            isOptionSelected={isOptionSelected}
                                            currentFilter={filters.find((f) => f.name === 'Bundles ID')}
                                        />)}
                                </Grid>
                                <Grid item xs={6}>
                                    {showPlatform && (
                                        <AutocompleteWrapper
                                            optionName="Platform"
                                            options={platform}
                                            handleInputChange={handleInputChange}
                                            handleDelete={handleDelete}
                                            isOptionSelected={isOptionSelected}
                                            currentFilter={filters.find((f) => f.name === 'Platform')}
                                        />)}
                                </Grid>
                                <Grid item xs={6}>
                                    {showPublishers && (
                                        <AutocompleteWrapper
                                            optionName="Publishers ID"
                                            options={publishers}
                                            handleInputChange={handleInputChange}
                                            handleDelete={handleDelete}
                                            isOptionSelected={isOptionSelected}
                                            currentFilter={filters.find((f) => f.name === 'Publishers ID')}
                                        />)}
                                </Grid>
                                <Grid item xs={6}>
                                    {showImpressions && (
                                        <TextField
                                            margin="dense"
                                            id="impressions"
                                            label="Minimum Impressions"
                                            type="number"
                                            fullWidth
                                            name="impressions"
                                            value={filters.impressions}
                                            onChange={handleInputImpressionsChange}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </AccordionDetails>
            </Accordion>

            <Grid item xs={6} md={6}>
                <Button
                    size={"large"}
                    startIcon={<BackupTableOutlinedIcon fontSize="small"/>}
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{marginLeft: '-15px'}}
                >
                    Generate Report
                </Button>
            </Grid>

            <Grid item xs={6} md={6}>
                <div>
                    <div
                        ref={dropDownRef}
                        style={{
                            userSelect: 'none',
                            borderRadius: '5px',
                            textAlign: "center",
                            backgroundColor: '#f0f3f5',
                            padding: '10px',
                            cursor: 'pointer',
                            float: 'right',
                            border: '1px solid #dddfe1',
                        }}
                        onClick={toggleCalendar}
                    >
                        <div>Date Range</div>
                        <div>
                            {options.start_date} - {options.end_date}
                        </div>
                    </div>
                    {showCalendar && (
                        <div
                            ref={pickerRef}
                            style={{border: '15px', boxShadow: '15px 15px 20px rgba(0, 0, 0, 0.3)',}}
                        >
                            <DateRangePicker
                                ranges={[selectedRange]}
                                onChange={handleDateChange}
                                moveRangeOnFirstSelection={false}
                                showSelectionPreview={true}
                            />
                        </div>
                    )}
                </div>
            </Grid>
            {loading &&
                <Grid item xs={12} md={12}>
                    <Box justifyContent="center" textAlign={"center"} alignItems="center">
                        <CircularProgress size={200}/>
                    </Box>
                </Grid>}
        </Grid>
    );
}

export default ReportOptionsForm;
