import {fetchDataFromApi, fetchStringFromApi} from './apiHelper';

export const fetchStatisticFieldsRevenue = () => fetchDataFromApi('post', '/api/financialreports/revenue/statistics');
export const fetchIntervalsRevenue = () => fetchDataFromApi('post', '/api/financialreports/revenue/intervals');
export const fetchRevenueReportData = () => fetchDataFromApi('post', '/api/financialreports/revenue/report');
export const fetchDataFieldsRevenue = () => fetchDataFromApi('post', '/api/financialreports/revenue/data');


export const fetchStatisticFieldsPayout = () => fetchDataFromApi('post', '/api/financialreports/payout/statistics');
export const fetchIntervalsPayout = () => fetchDataFromApi('post', '/api/financialreports/payout/intervals');
export const fetchPayoutReportData = () => fetchDataFromApi('post', '/api/financialreports/payout/report');
export const fetchDataFieldsPayout = () => fetchDataFromApi('post', '/api/financialreports/payout/data');
