import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

function StatisticsCheckbox({ statistic, checked, onChange }) {
    return (
        <FormControlLabel
            control={<Checkbox checked={checked} onChange={onChange} name={statistic.key} />}
            label={statistic.value}
        />
    );
}

export default StatisticsCheckbox;
