import { useState, useEffect } from 'react';

function useApi(apiFunction) {
    const [data, setData] = useState([]); 
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                const result = await apiFunction();
                if (isMounted) {
                    setData(result);
                }
            } catch (error) {
                console.error("Data loading error:", error);
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [apiFunction]);

    return { data, loading };
}

export default useApi;