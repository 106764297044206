import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    DialogActions
} from '@mui/material';

function FileDialog({
                        open,
                        handleClose,
                        firstLink,
                        secondLink,
                    }) {
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" >
            <DialogTitle>Files</DialogTitle>
            <DialogContent>
                {firstLink && (
                    <>
                        <Button
                            variant="contained"
                            color="primary"
                            component="a"
                            href={firstLink}
                            download
                            target="_blank"
                            style={{ margin: '10px' }}
                        >
                            Download File
                        </Button>
                        <br/>
                    </>
                )}
                {secondLink && (
                    <Button
                        variant="contained"
                        color="secondary"
                        component="a"
                        href={secondLink}
                        download
                        target="_blank"
                        style={{ margin: '10px' }}
                    >
                        Download AWS File
                    </Button>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default FileDialog;
