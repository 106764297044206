import React, {useState, useEffect, useRef} from 'react';
import axios from "axios";
import {
    Button,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails, FormControlLabel, Checkbox, CircularProgress, Box, Radio,
} from '@mui/material';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import {DateRangePicker} from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {
    fetchCompareDataFields, fetchCompareStatisticFields
} from '../../../API/BidsCubeReport';
import {sendDataToApi} from '../../../API/apiHelper';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import Cookies from 'js-cookie';
import {toast} from 'react-toastify';

function CompareOptionsForm({onGenerate}) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const formattedDate2 = yesterday.toISOString().split('T')[0];

    yesterday.setDate(yesterday.getDate() - 1);
    const formattedDate1 = yesterday.toISOString().split('T')[0];

    const dropDownRef1 = useRef(null);
    const dropDownRef2 = useRef(null);
    const pickerRef1 = useRef(null);
    const pickerRef2 = useRef(null);

    const [statistics, setStatistics] = useState([]);

    const [options, setOptions] = useState({
        start_date1: formattedDate1,
        end_date1: formattedDate1,
        start_date2: formattedDate2,
        end_date2: formattedDate2,
        fields: [],
        entity: [],
        stats: [],
        csv: 'no',
    });

    const [fields, setFields] = useState([]);

    const [showCalendar1, setShowCalendar1] = useState(false);
    const [showCalendar2, setShowCalendar2] = useState(false);
    const [selectedRange1, setSelectedRange1] = useState({
        startDate: new Date(options.start_date1),
        endDate: new Date(options.start_date1),
        key: 'selection',
    });

    const [selectedRange2, setSelectedRange2] = useState({
        startDate: new Date(options.start_date2),
        endDate: new Date(options.start_date2),
        key: 'selection',
    });

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const fields = await fetchCompareDataFields();
            setFields(fields);
            console.log(fields);
        };

        const fetchStatistics = async () => {
            const stats = await fetchCompareStatisticFields();
            setStatistics(stats);

        };

        fetchStatistics();
        fetchData();
    }, []);

    const handleCheckboxChange = (event) => {
        const {name} = event.target;
        setOptions({...options, entity: name});
    };

    useEffect(() => {
        const storedValue = Cookies.get('CompareReportBC');
        if (storedValue) {
            const parsedValue = JSON.parse(storedValue);

            if ('stats' in parsedValue) {
                setOptions(prevOptions => ({
                    ...prevOptions,
                    stats: parsedValue.stats
                }));
            }

            if ('entity' in parsedValue) {
                setOptions(prevOptions => ({
                    ...prevOptions,
                    entity: parsedValue.entity
                }));
            }

            if ('start_date1' in parsedValue) {
                setOptions(prevOptions => ({
                    ...prevOptions,
                    start_date1: parsedValue.start_date1
                }));
            }
            if ('end_date1' in parsedValue) {
                setOptions(prevOptions => ({
                    ...prevOptions,
                    end_date1: parsedValue.end_date1
                }));
            }

            if ('start_date2' in parsedValue) {
                setOptions(prevOptions => ({
                    ...prevOptions,
                    start_date2: parsedValue.start_date2
                }));
            }
            if ('end_date2' in parsedValue) {
                setOptions(prevOptions => ({
                    ...prevOptions,
                    end_date2: parsedValue.end_date2
                }));
            }


        } else {
            if (fields.length > 0) {
                var first = fields[0];
                setOptions({...options, entity: first.value});
            }
        }
    }, [fields]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            const response = await sendDataToApi('post', '/api/bidscube/compare/report', options, 'CompareReportBC');
            const fieldsArray = Object.entries(response.data.compareData).map(([key, value]) => ({key, value}));

            setLoading(false);

            onGenerate(fieldsArray);
        } catch (err) {
            toast.error(`Oops, there seems to be an error on the server. The technical team has already received information about this. We apologize for the inconvenience and we will let you know when it is fixed.`);
            console.error(err);
            setLoading(false);
        }
    };

    const handleDateChange1 = (event) => {
        const endDate = new Date(event.selection.endDate);
        endDate.setHours(12, 12, 12, 12);
        const endDateISOString = endDate.toISOString().split('T')[0];

        const startDate = new Date(event.selection.startDate);
        startDate.setHours(12, 12, 12, 12);
        const startDateISOString = startDate.toISOString().split('T')[0];

        setSelectedRange1((prevSelectedRange) => ({
            ...prevSelectedRange,
            startDate: new Date(startDateISOString),
            endDate: new Date(endDateISOString),
            key: 'selection',
        }));

        if (endDate && startDate) {
            setOptions((prevOptions) => ({
                ...prevOptions,
                start_date1: startDateISOString,
                end_date1: endDateISOString,
            }));
        }
    };

    const handleDateChange2 = (event) => {
        const endDate = new Date(event.selection.endDate);
        endDate.setHours(12, 12, 12, 12);
        const endDateISOString = endDate.toISOString().split('T')[0];

        const startDate = new Date(event.selection.startDate);
        startDate.setHours(12, 12, 12, 12);
        const startDateISOString = startDate.toISOString().split('T')[0];

        setSelectedRange2((prevSelectedRange) => ({
            ...prevSelectedRange,
            startDate: new Date(startDateISOString),
            endDate: new Date(endDateISOString),
            key: 'selection',
        }));

        if (endDate && startDate) {
            setOptions((prevOptions) => ({
                ...prevOptions,
                start_date2: startDateISOString,
                end_date2: endDateISOString,
            }));
        }
    };

    const toggleCalendar1 = () => {

        if (showCalendar1 === false) {
            setShowCalendar1(true);
        } else {
            setShowCalendar1(false);
        }
    };

    const toggleCalendar2 = () => {

        if (showCalendar2 === false) {
            setShowCalendar2(true);
        } else {
            setShowCalendar2(false);
        }
    };

    const handleClickOutside = (event) => {
        if (pickerRef1.current && !pickerRef1.current.contains(event.target) && !dropDownRef1.current.contains(event.target)) {
            setShowCalendar1(false);
        }
        if (pickerRef2.current && !pickerRef2.current.contains(event.target) && !dropDownRef2.current.contains(event.target)) {
            setShowCalendar2(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleCheckboxChangeStat = (event) => {
        const {name, checked} = event.target;
        let stats = [...options.stats];

        if (checked) {
            stats.push(name);
        } else {
            stats = stats.filter((satat) => satat !== name);
        }

        setOptions({...options, stats});
    };

    return (
        <Grid container spacing={2} sx={{width: "100%", marginLeft: '0px',}}>
            <Accordion sx={{width: "100%"}}>
                <AccordionSummary
                    expandIcon={<KeyboardDoubleArrowDownOutlinedIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{backgroundColor: '#f0f3f5', width: "100%"}}
                >
                    Options
                </AccordionSummary>
                <AccordionDetails sx={{width: "100%"}}>
                    <form onSubmit={handleSubmit}>
                        <Grid item xs={12} md={12} sx={{width: "100%"}}>
                            <Grid item xs={12} md={12} sx={{width: "100%"}}>
                                <Grid item xs={12} md={12}>
                                    {Object.keys(fields).length != 0 ? (<strong>Data</strong>) : ''}
                                </Grid>
                                {fields.map((field, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Radio
                                                checked={options.entity === field.value}
                                                onChange={handleCheckboxChange}
                                                name={field.value}
                                            />
                                        }
                                        label={field.value}
                                    />
                                ))}
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={12} sx={{width: "100%", margin: '25px 0 25px auto'}}>

                            <Grid item xs={12} md={12}>
                                {Object.keys(statistics).length != 0 ? (<strong>Statistics</strong>) : ''}
                            </Grid>
                            {statistics.map((statistic, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={options.stats.includes(statistic.key)}
                                            onChange={handleCheckboxChangeStat}
                                            name={statistic.key}
                                        />
                                    }
                                    label={statistic.value}
                                />
                            ))}

                        </Grid>
                    </form>
                </AccordionDetails>
            </Accordion>

            <Grid item xs={6} md={6}>
                <Button
                    size={"large"}
                    startIcon={<BackupTableOutlinedIcon fontSize="small"/>}
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{marginLeft: '-15px'}}
                >
                    Generate Report
                </Button>
            </Grid>

            <Grid item xs={6} md={6}> {/*   container spacing={2}*/}
                <div>
                    <div
                        ref={dropDownRef2}
                        style={{
                            userSelect: 'none',
                            borderRadius: '5px',
                            textAlign: "center",
                            backgroundColor: '#f0f3f5',
                            padding: '10px',
                            cursor: 'pointer',
                            float: 'right',
                            border: '1px solid #dddfe1',

                        }}
                        onClick={toggleCalendar2}
                    >
                        <div>Period II</div>
                        <div>
                            {options.start_date2} - {options.end_date2}
                        </div>
                    </div>
                    {showCalendar2 && (
                        <div
                            ref={pickerRef2}
                            style={{border: '15px', boxShadow: '15px 15px 20px rgba(0, 0, 0, 0.3)',}}
                        >
                            <DateRangePicker
                                ranges={[selectedRange2]}
                                onChange={handleDateChange2}
                                moveRangeOnFirstSelection={false}
                                showSelectionPreview={true}
                            />
                        </div>
                    )}
                </div>

                <div>
                    <div
                        ref={dropDownRef1}
                        style={{
                            userSelect: 'none',
                            borderRadius: '5px',
                            textAlign: "center",
                            backgroundColor: '#f0f3f5',
                            padding: '10px',
                            cursor: 'pointer',
                            float: 'right',
                            border: '1px solid #dddfe1',
                            marginRight: '15px',
                        }}
                        onClick={toggleCalendar1}
                    >
                        <div>Period I</div>
                        <div>
                            {options.start_date1} - {options.end_date1}
                        </div>
                    </div>
                    {showCalendar1 && (
                        <div
                            ref={pickerRef1}
                            style={{border: '15px', boxShadow: '15px 15px 20px rgba(0, 0, 0, 0.3)',}}
                        >
                            <DateRangePicker
                                ranges={[selectedRange1]}
                                onChange={handleDateChange1}
                                moveRangeOnFirstSelection={false}
                                showSelectionPreview={true}
                            />
                        </div>
                    )}
                </div>
            </Grid>
            {loading &&
                <Grid item xs={12} md={12}>
                    <Box justifyContent="center" textAlign={"center"} alignItems="center">
                        <CircularProgress size={200}/>
                    </Box>
                </Grid>}
        </Grid>
    );
}

export default CompareOptionsForm;
