import axios from 'axios';
import {toast} from 'react-toastify';
import Cookies from 'js-cookie';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const handleApiError = (err) => {
    if (err.response && err.response.status === 401) {
        window.location = '/login';
    } else {
        toast.error(`Oops, there seems to be an error on the API server. The technical team has already received information about this. We apologize for the inconvenience and we will let you know when it is fixed.`);
        console.error(err);
    }
};

export const fetchDataFromApi = async (method, endpoint) => {
    try {
        const response = await axios[method](`${BASE_URL}${endpoint}`);
        const fieldsArray = Object.entries(response.data).map(([key, value]) => ({key, value}));
        return fieldsArray;
    } catch (err) {
        handleApiError(err);
        return [];
    }
};

export const fetchStringFromApi = async (method, endpoint) => {
    try {
        const response = await axios[method](`${BASE_URL}${endpoint}`);
        return response.data;

    } catch (err) {
        handleApiError(err);
        return [];
    }
};

export const sendDataToApi = async (method, endpoint, options = [], nameReport = false, onSuccess = false, withToken = true) => {
    try {
        const params = new URLSearchParams(options);

        if (withToken != false) {
            const token = Cookies.get('jwt');
            params.append('token', token);
        }

        if (nameReport != false) {
            Cookies.set(nameReport, JSON.stringify(options));
        }
        const response = await axios[method](`${BASE_URL}${endpoint}?${params}`);

        if (onSuccess) {
            onSuccess(response);
        }
        return response;
    } catch (err) {
        handleApiError(err);
        return [];
    }
};

export const sendJsonToApi = async (method, endpoint, data = {}, nameReport = false, onSuccess = false, withToken = true) => {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };

        if (withToken) {
            const token = Cookies.get('jwt');
            headers['Authorization'] = `Bearer ${token}`;
        }

        if (nameReport) {
            Cookies.set(nameReport, JSON.stringify(data));
        }

        const response = await axios({
            method: method,
            url: `${BASE_URL}${endpoint}`,
            headers: headers,
            data: data,
        });

        if (onSuccess) {
            onSuccess(response);
        }
        return response;
    } catch (err) {
        handleApiError(err);
        return [];
    }
};