import React from 'react';
import { Outlet } from 'react-router-dom';

type Props = {};

const FinancialToolsPageLayout = (props: Props) => {
    return (
        <><Outlet /></>
    );
};

export default FinancialToolsPageLayout;