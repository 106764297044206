import React, {useState, useEffect, useRef} from 'react';
import {DateRangePicker} from 'react-date-range';

function DateRangeSelector({initialRange, onRangeChange}) {
    const [selectedRange, setSelectedRange] = useState(initialRange);
    const [showCalendar, setShowCalendar] = useState(false);
    const wrapperRef = useRef(null);

    const handleDateChange = (ranges) => {
        const {selection} = ranges;
        setSelectedRange(selection);
        onRangeChange(selection);
    };

    const toggleCalendar = () => setShowCalendar(!showCalendar);

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setShowCalendar(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const getLocalDate = (date) => {
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
    };

    return (
        <div ref={wrapperRef}>
            <div onClick={toggleCalendar} style={{
                userSelect: 'none',
                borderRadius: '5px',
                textAlign: "center",
                backgroundColor: '#f0f3f5',
                padding: '10px',
                cursor: 'pointer',
                float: 'right',
                border: '1px solid #dddfe1',
            }}>
                <div>Date Range</div>
                <div>
                    {getLocalDate(selectedRange.startDate)} - {getLocalDate(selectedRange.endDate)}
                </div>
            </div>
            {showCalendar && (
                <DateRangePicker
                    ranges={[selectedRange]}
                    onChange={handleDateChange}
                />
            )}
        </div>
    );
}

export default DateRangeSelector;

