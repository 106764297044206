import React, { useEffect } from 'react';
import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MainLayout from "./components/layout/MainLayout";
import {routes} from "./routes";
import LoginForm from "./pages/auth/login";
import WelcomePage from "./pages/auth/welcome/WelcomePage";
import './global.css';
// import ReactGA from 'react-ga';

function App() {
    return (
        <div>
        <BrowserRouter>
            <Routes>
                <Route path="/welcome" element={<WelcomePage/>}>
                </Route>
                <Route path="/login" element={<LoginForm/>}>
                </Route>
                <Route path="/" element={<MainLayout/>}>
                    {routes}
                </Route>
            </Routes>
            <ToastContainer/>
        </BrowserRouter>
        </div>
    );
}
//
// function AnalyticsTracker() {
//     const location = useLocation();
//
//     useEffect(() => {
//         // ReactGA.event({
//         //     category: 'User',
//         //     action: 'TEST',
//         //     label: `${location.pathname + location.search}`
//         // });
//        // console.log("Sending pageview for", location.pathname + location.search);
//         ReactGA.pageview(location.pathname + location.search);
//     }, [location]);
//
//     return null;
// }

export default App;
