import React, {useState, useEffect} from 'react';
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Select,
    MenuItem,
    Box,
    Alert,
    AlertTitle,
    Button,
    Grid,
    Accordion,
    Autocomplete,
    AccordionSummary,
    AccordionDetails,
    FormControlLabel,
    Checkbox,
    CircularProgress,
    InputLabel,
    Chip,
    FormControl,
    OutlinedInput,
    InputAdornment,
    FormHelperText,
} from '@mui/material';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import Cookies from 'js-cookie';
import {toast} from 'react-toastify';
import {sendDataToApi} from '../../../../API/apiHelper';

const UserTable = ({data}) => {

    const [users, setUsers] = useState([]);
    const [open, setOpen] = useState(false);
    const [editUser, setEditUser] = useState({});
    const [keys, setKeys] = useState([]);
    const [ip, setSelectedIp] = useState([]);

    const [openSend, setOpenSend] = useState(false);

    useEffect(() => {
        if (data.length) {
            const longestItem = data.reduce((prev, current) => (Object.keys(prev.value).length > Object.keys(current.value).length) ? prev : current);
            setKeys(Object.keys(longestItem.value));
            data.forEach((user) => {
                if (user.value && typeof user.value.Role === 'string') {
                    user.value.Role = user.value.Role.split(',').map(role => role.trim());
                }
                if (user.value && typeof user.value.Ip === 'string') {
                    user.value.Ip = user.value.Ip.split(',').map(ip => ip.trim());
                }
            });
            setUsers(data);
        }
    }, [data]);

    if (!data || data.length === 0) {
        return <div></div>;
    }

    const handleClickOpen = (user) => {
        setEditUser(user.value);
        if(user.value.Ip != ''){
            setSelectedIp(user.value.Ip);
        }else{
            setSelectedIp([]);
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const openSendSuccess = () => {
        setOpenSend(true);
    };

    const handleCloseSend = () => {
        setOpenSend(false);
    };

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setEditUser({...editUser, [name]: value});
    };

    const handleSave = () => {
        const userId = localStorage.getItem('user_id');
        const updatedUser = {
            ...editUser,
            Ip: ip,
            ChangedBy: userId
        };
        const response = sendDataToApi('post', '/api/user/update', updatedUser, false, (data) => {
            const updatedArray = users.map(item => {
                if (item.value.ID === editUser.ID) {
                    return {
                        ...item,
                        value: updatedUser,
                    };
                }
                return item;
            });

            setUsers(updatedArray);
            setOpen(false);
            openSendSuccess();
        });
    };

    const handleChange = (event, newValue) => {
        setSelectedIp(newValue);
    };

    return (
        <>
            <TableContainer component={Paper} sx={{
                padding: 2,
                backgroundColor: 'white',
                borderRadius: '8px',
                border: '6px',
                paddingLeft: 0,
                paddingTop: 0,
                marginLeft: '0px',
                marginTop: '10px',
                boxShadow: '0 0 1px rgba(0, 0, 0, 0.2)',
            }}>
                <Table>
                    <TableHead sx={{backgroundColor: '#f0f3f5',}}>
                        <TableRow>
                            {keys.map((key) => (
                                <TableCell key={key}>{key}</TableCell>
                            ))}
                            <TableCell key="edit-header"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((row) => (
                            <TableRow key={row.key}>
                                {keys.map((key) => (
                                    <TableCell key={key}>
                                        {Array.isArray(row.value[key]) ? (
                                            row.value[key].map((item, index) => (
                                                <span key={index}>
                                                  {item}
                                                    <br/>
                                                </span>
                                            ))
                                        ) : row.value[key] === 'active' ? (
                                            <Button variant="contained" color="success" size="small">
                                                active
                                            </Button>
                                        ) : row.value[key] === 'blocked' ? (
                                            <Button variant="contained" color="error" size="small">
                                                blocked
                                            </Button>
                                        ) : (
                                            row.value[key]
                                        )}
                                    </TableCell>
                                ))}
                                <TableCell>
                                    <Button
                                        variant="outlined"
                                        startIcon={<EditIcon/>}
                                        color="primary"
                                        size="medium"
                                        onClick={() => handleClickOpen(row)}
                                    >
                                        Edit
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={openSend} onClose={handleCloseSend}>
                <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    User changed <strong>successfully!</strong>
                </Alert>
            </Dialog>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit user {editUser.Name} </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="Name"
                        label="User Name"
                        type="text"
                        value={editUser.Name || ''}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        name="password"
                        label="Password"
                        type="password"
                        value={editUser.password || ''}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <FormControl fullWidth required margin="dense">
                        <InputLabel id="status-label">Status</InputLabel>
                        <Select
                            value={editUser.Status || ''}
                            onChange={handleInputChange}
                            label="Status"
                            name="Status"
                            fullWidth
                        >
                            <MenuItem value={"active"}>Active</MenuItem>
                            <MenuItem value={"blocked"}>Blocked</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth margin="dense">
                        <InputLabel id="role-label">Role</InputLabel>
                        <Select
                            labelId="role-label"
                            label="Role"
                            multiple
                            value={typeof editUser.Role === 'string' ? [editUser.Role.toLowerCase()] : (editUser.Role || []).map(role => role.toLowerCase())}
                            onChange={handleInputChange}
                            name="Role"
                            renderValue={(selected) => (
                                <div>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value}/>
                                    ))}
                                </div>
                            )}
                        >
                            <MenuItem value={"account manager"}>Account Manager</MenuItem>
                            <MenuItem value={"publisher manager"}>Publisher Manager</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            margin="dense"
                            name="Position"
                            label="User Position"
                            type="text"
                            value={editUser.Position || ''}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </FormControl>

                    <FormControl fullWidth margin="dense">
                        <Autocomplete
                            multiple
                            freeSolo
                            name="Ip"
                            options={[]}
                            value={ip}
                            onChange={handleChange}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({index})} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Allowed IPs"
                                    placeholder="Add an item and press Enter"
                                />
                            )}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSave}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UserTable;