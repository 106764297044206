import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import AuthLogin from './FirebaseLogin';
import Logo from '../../../assets/images/nav_logo.png';


const Login = () => {
    const theme = useTheme();

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ backgroundColor: theme.palette.common.black, height: '100%', minHeight: '100vh' }}
        >
            <Grid item xs={11} sm={7} md={6} lg={4}>
                <Card
                    sx={{
                        overflow: 'visible',
                        display: 'flex',
                        position: 'relative',
                        '& .MuiCardContent-root': {
                            flexGrow: 1,
                            flexBasis: '50%',
                            width: '50%'
                        },
                        maxWidth: '475px',
                        margin: '24px auto'
                    }}
                >
                    <CardContent sx={{ p: theme.spacing(5, 4, 3, 4) }}>
                        <Grid container direction="column" spacing={4} justifyContent="center">
                            <Grid item xs={12} md={12}>
                                <Grid item>
                                        <img src={Logo} style={{ width: 'auto', height: '80px',align: 'center' }}/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Grid container justifyContent="space-between">
                                    <Grid item>
                                        <Typography color="textPrimary"  variant="h4">
                                            Sign in
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>

                                <AuthLogin />
                            </Grid>
                            <Grid container justifyContent="flex-start" sx={{ mt: theme.spacing(2), mb: theme.spacing(1) }}>
                                <Grid item>
                                    <Typography variant="subtitle2" color="secondary" sx={{ textDecoration: 'none', pl: 2 }}>
                                        {/*Create new account*/}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default Login;
