import React from 'react';
import {Autocomplete, Chip, TextField} from '@mui/material';

function AutocompleteWrapper({
                                 optionName,
                                 options,
                                 handleInputChange,
                                 handleDelete,
                                 isOptionSelected,
                                 currentFilter,
                             }) {

   // currentFilter = filters.find(f => f.name === optionName);

    return (
        <Autocomplete
            multiple
            options={options.filter((option) => !isOptionSelected(option))}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.value)}
            freeSolo
            renderInput={(params) => <TextField {...params} label={optionName} variant="outlined"/>}
            onChange={(event, value) => handleInputChange(optionName, value)}
            renderOption={(props, option) => <li {...props}>{option.value}</li>}
            value={currentFilter ? currentFilter.values : []}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({index})}
                        onDelete={() => handleDelete(optionName, option)}
                    />
                ))
            }
        />
    );
}

export default AutocompleteWrapper;
