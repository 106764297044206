import React, {useState, useEffect} from 'react';
import {
    Pagination,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Select,
    MenuItem,
    Box,
    Alert,
    AlertTitle,
    FormControl, InputLabel, FormControlLabel, Checkbox
} from '@mui/material';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Cookies from 'js-cookie';
import {fetchINAPCreateData, fetchEntriesCreateData} from "../../../API/AdsReport";
import {sendDataToApi, fetchDataFromApi} from '../../../API/apiHelper';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';

function formatString(str) {
    let newStr = str.replace(/_/g, ' ');

    newStr = newStr.toLowerCase().replace(/\b[a-z]/g, function(letter) {
        return letter.toUpperCase();
    });

    return newStr;
}
const EntriesTable = ({data}) => {

    const StyledTableHeadCell = styled(TableCell)({
        height: '50px',
        padding: '4px 10px',
    });

    const StyledTableCell = styled(TableCell)({
        height: '20px',
        padding: '4px 10px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    });

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const handleChange = (event, value) => {
        setPage(value);
    };

    const [entitys, setEntitys] = useState([]);
    const [editEntity, setEditEntity] = useState({
        adType: "",
        dsp: "",
        newDspId: "",
        newDspName: "",
        sourceWww: "",
        publisherId: "",
        relationship: "",
        sourceId: "",
        addField: "",
        comment: "",
    });
    const [deleteEntity, setDeleteEntity] = useState([]);
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);

    const [tittle, setTittle] = useState([]);
    const [keys, setKeys] = useState([]);

    const [openSend, setOpenSend] = useState(false);

    const [openUpdate, setOpenUpdate] = useState(false);
    const [isNewFieldOpen, setNewFieldOpen] = useState(false);

    const [isNewFieldPlatformOpen, setNewFieldPlatformOpen] = useState(false);
    const [isNewFieldDeveloperOpen, setNewFieldDeveloperOpen] = useState(false);
    const [isNewFieldBundleOpen, setNewFieldBundleOpen] = useState(false);
    const [isNewFieldCategoryOpen, setNewFieldCategoryOpen] = useState(false);


    const handleNewFieldToggle = () => {
        setNewFieldOpen(!isNewFieldOpen);
    };

    const handleNewPlatformToggle = () => {
        setNewFieldPlatformOpen(!isNewFieldPlatformOpen);
    };

    const handleNewDeveloperToggle = () => {
        setNewFieldDeveloperOpen(!isNewFieldDeveloperOpen);
    };

    const handleNewBundleToggle = () => {
        setNewFieldBundleOpen(!isNewFieldBundleOpen);
    };

    const handleNewCategoryToggle = () => {
        setNewFieldCategoryOpen(!isNewFieldCategoryOpen);
    };

    const [fields, setFileds] = useState({
        adType: "",
        dsp: "",
        newDspId: "",
        newDspName: "",
        sourceWww: "",
        publisherId: "",
        relationship: "",
        sourceId: "",
        addField: "",
        comment: "",
    });

    const resetEditEntity = () => {
        setEditEntity({
            adType: "",
            dsp: "",
            newDspId: "",
            newDspName: "",
            sourceWww: "",
            publisherId: "",
            relationship: "",
            sourceId: "",
            addField: "",
            comment: "",
        });
    };

    const [fieldsData, setFieldsData] = useState({
        dsp: [],
    });

    useEffect(() => {
        if (data.length) {
            const longestItem = data.reduce((prev, current) => (Object.keys(prev.value).length > Object.keys(current.value).length) ? prev : current);
            setKeys(Object.keys(longestItem.value));
            const formattedKeys = Object.keys(longestItem.value).map(key => formatString(key));
            setTittle(formattedKeys);

            setEntitys(data);
        }
    }, [data]);

    useEffect(() => {
        const fetchCreateData = async () => {
            const fieldsArray = await fetchEntriesCreateData();

            console.log(fieldsArray);
            setFieldsData(prevFieldsData => ({
                ...prevFieldsData,
                dsp: fieldsArray.find(item => item.key === 'dsps').value,
            }));
        };

        fetchCreateData();
    }, []);

    if (!data || data.length === 0) {
        return <div></div>;
    }

    const handleClickOpen = (fields) => {
        console.log(fields);
        if (fields.value.dsp_name) {
            const dsp = fieldsData.dsp.find(item => item.dsp_name === fields.value.dsp_name);
            fields.value.dsp = dsp ? dsp.id : '';
        }

        fields.value.adType = fields.value.pub_type;
        fields.value.publisherId = fields.value.publisher_id;
        fields.value.sourceWww = fields.value.source_www;
        fields.value.sourceId = fields.value.source_id;
        fields.value.relationship = fields.value.direct_reseller;
        fields.value.addField = fields.value.add_field;

        setEditEntity(fields.value);
        setOpen(true);
    };

    const handleClickDeleteOpen = (item) => {
        setDeleteEntity(item.value);
        setDeleteOpen(true);
    };

    const handleCloseDelete = () => {
        setDeleteOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const openSendSuccess = () => {
        setOpenSend(true);
    };

    const openUpdateSuccess = () => {
        setOpenUpdate(true);
    };

    const handleCloseSend = () => {
        window.location.reload();
        setOpenSend(false);
    };
    const handleCloseUpdate = () => {
        window.location.reload();
        setOpenUpdate(false);
    };

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setEditEntity({...editEntity, [name]: value});
    };

    const handleSave = () => {
        const response = sendDataToApi('post','/api/report-ads-txt/entries/update', editEntity, false, (data) => {
            resetEditEntity();
            setOpen(false);
            openUpdateSuccess();
        });
    };

    const handleDelete = () => {
        const response = sendDataToApi('post','/api/report-ads-txt/entries/delete', deleteEntity, false, (data) => {
            setDeleteOpen(false);
            openSendSuccess();
        });
    };


    return (
        <>
            <TableContainer component={Paper} sx={{
                padding: 2,
                backgroundColor: 'white',//'white',
                borderRadius: '8px',
                border: '6px',
                paddingLeft: 0,
                paddingTop: 0,
                marginLeft: '0px',
                marginTop: '10px',
                boxShadow: '0 0 1px rgba(0, 0, 0, 0.2)',
            }}>
                <Table>
                    <TableHead sx={{backgroundColor: '#f0f3f5',}}>
                        <TableRow>
                            {tittle.map((key) => (
                                <StyledTableHeadCell key={key}>{key}</StyledTableHeadCell>
                            ))}
                            <StyledTableHeadCell key="edit-header"></StyledTableHeadCell>
                            <StyledTableHeadCell key="delete-header"></StyledTableHeadCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {entitys.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((row) => (
                            <TableRow key={row.key}>
                                {keys.map((key) => (
                                    <StyledTableCell key={key}>
                                        {
                                            row.value[key]
                                        }
                                    </StyledTableCell>
                                ))}
                                <StyledTableCell>
                                    <Button variant="outlined" startIcon={<EditIcon/>} color="primary" size="medium"
                                            onClick={() => handleClickOpen(row)}>
                                        Edit
                                    </Button>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Button variant="outlined" startIcon={<DeleteOutlineIcon/>} color="error"
                                            size="medium"
                                            onClick={() => handleClickDeleteOpen(row)}>
                                        Delete
                                    </Button>
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Pagination count={Math.ceil(entitys.length / rowsPerPage)} page={page} onChange={handleChange}/>
            </TableContainer>


            <Dialog open={openUpdate} onClose={handleCloseUpdate}>
                <Alert severity="success">
                    <AlertTitle>Row <strong>updated</strong> successfully!</AlertTitle>
                </Alert>
            </Dialog>

            <Dialog open={openSend} onClose={handleCloseSend}>
                <Alert severity="success">
                    <AlertTitle>Row <strong>deleted</strong> successfully!</AlertTitle>
                </Alert>
            </Dialog>

            <Dialog open={deleteOpen} onClose={handleCloseDelete}>
                <Alert severity="error"
                >
                    <AlertTitle>Delete row with ID <strong>{deleteEntity.id}</strong>?</AlertTitle>
                    <Button sx={{margin: '0 10px'}} variant="outlined" color="error"
                            onClick={handleCloseDelete}>No</Button>
                    <Button variant="contained" color="error" onClick={handleDelete}>Yes</Button>
                </Alert>
            </Dialog>

            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogTitle>Edit row</DialogTitle>
                <DialogContent>
                    <Box mb={2}>
                        <FormControl fullWidth>
                            <InputLabel id="adType-label">INAPP/CTV</InputLabel>
                            <Select
                                labelId="adType-label"
                                name="adType"
                                value={editEntity.adType}
                                onChange={handleInputChange}
                                required
                            >
                                <MenuItem value="INAPP">INAPP</MenuItem>
                                <MenuItem value="CTV">CTV</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mb={2}>
                        <FormControl fullWidth>
                            <InputLabel id="dsp-label">DSP</InputLabel>
                            <Select
                                labelId="dsp-label"
                                name="dsp"
                                value={editEntity.dsp}
                                onChange={handleInputChange}
                                required
                            >
                                {fieldsData.dsp.map((dsps) => (
                                    <MenuItem value={dsps.id}>{dsps.dsp_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mb={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isNewFieldOpen}
                                    onChange={handleNewFieldToggle}
                                    color="primary"
                                />
                            }
                            label="Add new DSP"
                        />
                    </Box>
                    {isNewFieldOpen && (
                        <>
                            <Box mb={2}>
                                <TextField
                                    name="newDspId"
                                    value={editEntity.newDspId}
                                    onChange={handleInputChange}
                                    label="New DSP ID"
                                    fullWidth
                                />
                            </Box>
                            <Box mb={2}>
                                <TextField
                                    name="newDspName"
                                    value={editEntity.newDspName}
                                    onChange={handleInputChange}
                                    label="New DSP Name"
                                    fullWidth
                                />
                            </Box>
                        </>
                    )}

                    <Box mb={2}>
                        <TextField
                            name="sourceWww"
                            value={editEntity.sourceWww}
                            onChange={handleInputChange}
                            label="Source WWW"
                            fullWidth
                            required
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            name="publisherId"
                            value={editEntity.publisherId}
                            onChange={handleInputChange}
                            label="Publisher ID"
                            fullWidth
                            required
                        />
                    </Box>
                    <Box mb={2}>
                        <FormControl fullWidth>
                            <InputLabel id="relationship-label">Direct/Reseller</InputLabel>
                            <Select
                                labelId="relationship-label"
                                name="relationship"
                                value={editEntity.relationship}
                                onChange={handleInputChange}
                                required
                            >
                                <MenuItem value="DIRECT">DIRECT</MenuItem>
                                <MenuItem value="RESELLER">RESELLER</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mb={2}>
                        <TextField
                            name="sourceId"
                            value={editEntity.sourceId}
                            onChange={handleInputChange}
                            label="Source ID"
                            fullWidth
                            required
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            name="addField"
                            value={editEntity.addField}
                            onChange={handleInputChange}
                            label="Add Field"
                            fullWidth
                            required
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            name="comment"
                            value={editEntity.comment}
                            onChange={handleInputChange}
                            label="Comment"
                            fullWidth
                            required
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSave}>Save</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openSend} onClose={handleCloseSend}>
                <Alert severity="success">
                    <AlertTitle>Success!</AlertTitle>
                    Entity <strong>updated</strong> successfully!
                </Alert>
            </Dialog>
        </>
    );
};

export default EntriesTable;
