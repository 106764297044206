import React, {useEffect, useState} from 'react';
import {
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Alert,
    AlertTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
    Box,
    Typography
} from '@mui/material';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import {sendJsonToApi} from '../../../../API/apiHelper';
import {toast} from 'react-toastify';
import axios from "axios";

function ApproveRowDialog({
                              open,
                              handleClose,
                              editRow,
                              onApproveSuccess,
                          }) {

    const [endpoint, setEndpoint] = useState('/api/financialtools/totalpayout/profoma');
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        if (editRow.hyper_id) {
            setShowMessage(false);
            handleSubmit(editRow);
        } else {
            setShowMessage(true);
        }
    }, [editRow])

    const handleSubmit = async (editRow) => {
        try {
            const userId = localStorage.getItem('user_id');
            editRow.user_id = userId;

            setLoading(true);
            const response = await sendJsonToApi('post', '/api/financialtools/totalpayout/getfullrevenue', editRow);
            if (Object.keys(response.data).length === 0) {
                setData(false);
            } else {
                const fieldsArray = Object.entries(response.data).map(([key, value]) => ({key, value}));
                const dataObj = fieldsArray.reduce((acc, item) => {
                    acc[item.key] = item.value;
                    return acc;
                }, {});

                setData(dataObj);
            }
            setLoading(false);
        } catch (err) {
            toast.error(`Oops, there seems to be an error on the server. The technical team has already received information about this. We apologize for the inconvenience and we will let you know when it is fixed.`);
            console.error(err);
            setLoading(false);
        }
    };

    const sendToServer = async () => {
        try {
            setLoading(true);
            const userId = localStorage.getItem('user_id');
            const formData = new FormData();

            if (editRow.id) {
                formData.append('id', editRow.id);
                formData.append('user_id', userId);
                formData.append('period', editRow.period);
                formData.append('hyper', editRow.hyper_id);
                formData.append('publisher', editRow.publisherOriginal);
                formData.append('comment', editRow.revision_comment);
                formData.append('total', data.sum);

                const response = await axios.post(`${BASE_URL}${endpoint}`, formData, {
                    headers: {'Content-Type': 'multipart/form-data'},
                });

                if (response.data.success) {
                    toast.success(response.data.message);
                    setLoading(false);
                    handleClose();
                    onApproveSuccess();
                } else {
                    setLoading(false);
                    toast.error('Error: ' + response.data.message);
                }
            } else {
                setLoading(false);
                toast.error('System error: This row without ID.');
            }
        } catch (error) {
            toast.error(`An error occurred while sending data: ${error.message}`);
            console.error('An error occurred while sending data:', error);
        }
    };


    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>{"Confirm Numbers for " + editRow.publisherDisplay}</DialogTitle>
            <DialogContent>{showMessage ? (
                <Alert variant="outlined" color="error" severity="error" sx={{mb: 3}}>
                    <Typography variant="body1">
                        The publisher <b>doesn't have</b> a Hyper ID. Please <b>add a Hyper ID</b> to the Publisher using the Database
                        tools on the <b>SSP</b> tab.
                    </Typography>
                </Alert>
            ) : (
                loading ? (
                    <Grid container justifyContent="center">
                        <Grid item>
                            <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
                                <CircularProgress size={150}/>
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <Box>
                        <Alert severity="success" variant="outlined" icon={false} sx={{mb: 3}}>
                            <Typography variant="h6">
                                <BusinessCenterOutlinedIcon sx={{verticalAlign: 'middle', mr: 1}}/> Smarthub:
                                ${data.smarthub}
                            </Typography><br/>
                            <Typography variant="h6">
                                <BusinessCenterOutlinedIcon sx={{verticalAlign: 'middle', mr: 1}}/> Bidscube:
                                ${data.bidscube}
                            </Typography><br/>
                            <Typography variant="h6" component="div" sx={{fontWeight: 'bold'}}>
                                <MonetizationOnOutlinedIcon sx={{verticalAlign: 'middle', mr: 1}}/> Total: ${data.sum}
                            </Typography>
                        </Alert>
                        <Alert variant="outlined" color="warning" severity="warning" sx={{mb: 3}}>
                            After confirmation, numbers will be sent to Hyper
                        </Alert>
                    </Box>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                {!loading && !showMessage && (
                    <Button onClick={sendToServer} color="success">
                        Approve
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default ApproveRowDialog;