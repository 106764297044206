import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import colorConfigs from "../../configs/colorConfigs";
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import SidebarItem from "./SidebarItem";
import { useSelector } from "react-redux";

const SidebarItemCollapse = ({ item, onOpenChange, sidebarOpen, onMenuItemClick, isNested = false }) => {
    const [open, setOpen] = useState(false);
    const { appState } = useSelector((state) => state.appState);

    useEffect(() => {
        if (appState.includes(item.state)) {
            setOpen(true);
        }
    }, [appState, item]);

    const toggleOpen = () => {
        const newOpenState = !open;
        setOpen(newOpenState);
        if (typeof onOpenChange === "function") {
            onOpenChange(newOpenState);
        }
    };

    useEffect(() => {
        if (!sidebarOpen) {
            setOpen(false);
        }
    }, [sidebarOpen]);

    return (
        item.sidebarProps ? (
            <>
                <ListItemButton
                    onClick={toggleOpen}
                    sx={{
                        "&:hover": {
                            backgroundColor: colorConfigs.sidebar.hoverBg
                        },
                        paddingY: "12px",
                        paddingX: isNested ? "45px" : "24px",
                    }}
                >
                    <ListItemIcon sx={{
                        color: colorConfigs.sidebar.color,
                    }}>
                        {item.sidebarProps.icon && item.sidebarProps.icon}
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        primary={
                            <Typography>
                                {item.sidebarProps.displayText}
                            </Typography>
                        }
                    />
                    {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
                </ListItemButton>
                <Collapse in={open} timeout="auto">
                    <List>
                        {item.child?.map((route, index) => (
                            route.sidebarProps ? (
                                route.child ? (
                                    <SidebarItemCollapse
                                        item={route}
                                        key={index}
                                        onOpenChange={onOpenChange}
                                        sidebarOpen={sidebarOpen}
                                        onMenuItemClick={onMenuItemClick} // здесь должно быть onMenuItemClick
                                        isNested={true}
                                    />
                                ) : (
                                    <SidebarItem
                                        item={route}
                                        key={index}
                                        onMenuItemClick={onMenuItemClick} // здесь тоже onMenuItemClick
                                    />
                                )
                            ) : null
                        ))}
                    </List>
                </Collapse>
            </>
        ) : null
    );
};

export default SidebarItemCollapse;
