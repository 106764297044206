import React, {useState, useEffect, useRef} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Alert,
    AlertTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress
} from '@mui/material';
import SelectMulti from '../../../../components/select/SelectMulti';
import MonthPickerComponent from '../../../../components/date/MonthPickerComponent';
import {toast} from 'react-toastify';
import axios from "axios";

function CreateRowDialog({
                             open,
                             handleClose,
                             ssp,
                             onSuccess,
                         }) {

    useEffect(() => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
        setMonth(`${year}-${month}`);
    }, []);

    const [month, setMonth] = useState('');
    const [platform, setPlatform] = useState('');
    const [publisher, setPublisher] = useState('');
    const [final_cost, setFinal_cost] = useState('');
    const [revenue, setRevenue] = useState('');
    const [file_link, setFile_link] = useState('');
    const [file, setFile] = useState(null);
    const [comment, setComment] = useState('');
    const [cost, setCost] = useState('');

    const [endpoint, setEndpoint] = useState('/api/financialtools/totalpayout/create');
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const handleMonthChange = (selectedMonth) => {
        const periodString = `${selectedMonth.year}-${selectedMonth.month}`;
        setMonth(periodString);
    };

    const handleSetFile = (event) => {
        setFile(event.target.files[0]);
    };
    const handleSetPlatform = (event) => {
        setPlatform(event.target.value);
    };
    const handleSetPublisher = (event) => {
        setPublisher(event.target.value);
    };
    const handleSetFinal_cost = (event) => {
        setFinal_cost(event.target.value);
    };
    const handleSetRevenue = (event) => {
        setRevenue(event.target.value);
    };
    const handleSetFile_link = (event) => {
        setFile_link(event.target.value);
    };
    const handleSetComment = (event) => {
        setComment(event.target.value);
    };
    const handleSetCost = (event) => {
        setCost(event.target.value);
    };


    const sendToServer = () => {

        if (!platform ||
            !publisher ||
            !final_cost ||
            !revenue ||
            !cost
    )
        {
            toast.error('First fill required fields!');
            return;
        }

        const userId = localStorage.getItem('user_id');

        const formData = new FormData();
        if(file){
        formData.append('file', file);
        }

        if(userId){
        formData.append('user_id', userId);
        }

        if(platform){
        formData.append('platform', platform);
        }

        if(publisher){
        formData.append('publisher', publisher);
        }

        if(final_cost){
        formData.append('final_cost', final_cost);
        }

        if(revenue){
        formData.append('revenue', revenue);
        }

        if(file_link){
        formData.append('file_link', file_link);
        }

        if(month){
        formData.append('month', month);
        }

        if(cost){
        formData.append('cost', cost);
        }

        if(comment){
        formData.append('comment', comment);
        }


        axios.post(`${BASE_URL}${endpoint}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response) => {
                if (response.data.success) {
                    toast.success(response.data.message);
                    setFile(null);
                    setMonth('');
                    setPlatform('');
                    setPublisher('');
                    setFinal_cost('');
                    setRevenue('');
                    setFile_link('');
                    setComment('');
                    setCost('');
                    onSuccess();
                    handleClose();
                } else {
                    toast.error('Error: ' + response.data.message);
                }
            })
            .catch((error) => {
                toast.error('An error occurred while sending a file:', error.message);
                console.error('An error occurred while sending a file:', error.message);
            });
    };



    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Create a new row</DialogTitle>
            <DialogContent>
                <Alert variant="outlined" severity="info" color="info" sx={{marginBottom: "20px"}}>
                    Please complete this form to create a new row.<br/>
                </Alert>

                <div
                    style={{
                        userSelect: 'none',
                        borderRadius: '5px',
                        textAlign: "center",
                        backgroundColor: '#f0f3f5',
                        padding: '2px',
                        cursor: 'pointer',
                        border: '1px solid #dddfe1',
                    }}
                >
                    <div>
                        <InputLabel id="publisher-label">Choose a period</InputLabel>
                    </div>
                    <div>
                        <MonthPickerComponent onMonthChange={handleMonthChange}/>
                    </div>
                </div>

                <FormControl fullWidth margin="dense">
                    <InputLabel id="platform-label">Platform</InputLabel>
                    <Select
                        labelId="platform-label"
                        id="platform"
                        name="platform"
                        value={platform}
                        onChange={handleSetPlatform}
                        required = {true}
                        label="Platform"
                    >
                        <MenuItem value={'smarthub'}>SmartHub</MenuItem>
                        <MenuItem value={'bidscube'}>BidsCube</MenuItem>
                    </Select>
                </FormControl>

                <FormControl fullWidth margin="dense">
                    <InputLabel id="publisher-label">Publisher</InputLabel>
                    <Select
                        labelId="publisher-label"
                        id="publisher_id"
                        name="publisher_id"
                        value={publisher}
                        onChange={handleSetPublisher}
                        required = {true}
                        label="publisher"
                    >
                        {ssp.map((ssp) => (
                            <MenuItem key={ssp.value.id} value={ssp.value.id}>
                                {ssp.value.name} (ID: {ssp.value.id})
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    margin="dense"
                    id="cost"
                    label="Cost"
                    type="number"
                    fullWidth
                    name="cost"
                    value={cost}
                    onChange={handleSetCost}
                    required = {true}
                />

                <TextField
                    margin="dense"
                    id="final_cost"
                    label="Final Cost"
                    type="number"
                    fullWidth
                    name="final_cost"
                    value={final_cost}
                    onChange={handleSetFinal_cost}
                    required = {true}
                />

                <TextField
                    margin="dense"
                    id="revenue"
                    label="Revenue"
                    type="number"
                    fullWidth
                    name="revenue"
                    value={revenue}
                    onChange={handleSetRevenue}
                    required = {true}
                />

                <TextField
                    margin="dense"
                    id="file_link"
                    label="File Link"
                    type="text"
                    fullWidth
                    name="file_link"
                    value={file_link}
                    onChange={handleSetFile_link}
                />

                <div>
                    <InputLabel id="publisher-label">Or choose a file</InputLabel>
                </div>
                <div>
                    <input
                        type="file"
                        id="file"
                        name="file"
                        onChange={handleSetFile}
                    />
                </div>

                <TextField
                    margin="dense"
                    id="comment"
                    label="Revision Comment"
                    type="text"
                    fullWidth
                    name="comment"
                    value={comment}
                    onChange={handleSetComment}
                    multiline
                    rows={3}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={sendToServer} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateRowDialog;