import React, {useState, useEffect} from 'react';
import {Box, Button, Grid, Typography, Alert, AlertTitle,} from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import "@fontsource/roboto";
import CridsWhiteListOptionsForm from "./options/CridsWhiteListOptionsForm";
import DownloadButton from "../../components/reports/DownloadButton";
import CridsWhiteListDataGrid from "./components/CridsWhiteListDataGrid";
import {sendJsonToApi} from '../../API/apiHelper';
import OutletIcon from "@mui/icons-material/Outlet";
import {toast} from 'react-toastify';
import {format} from 'date-fns';


const CridsWhiteList = () => {
    const [data, setData] = useState([]);
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [cacheOptions, setCacheOptions] = useState('');

    useEffect(() => {
        const currentDate = new Date();
        const formattedDate = format(currentDate, 'yyyy-MM-dd');
        setName(`CridsWhiteList - ${formattedDate}`);
    }, [data])

    const handleSubmit = async (options) => {
        try {
            if (options) {
                setCacheOptions(options);
            }
            setLoading(true);
            const response = await sendJsonToApi('post', '/api/smarthub/cridswhitelist/get', options, 'CridsWhiteList');
            if (Object.keys(response.data).length === 0) {
                setData(false);
            } else {
                const fieldsArray = Object.entries(response.data).map(([key, value]) => ({key, value}));
                setData(fieldsArray);
            }
            setLoading(false);
        } catch (err) {
            toast.error(`Oops, there seems to be an error on the server. The technical team has already received information about this. We apologize for the inconvenience and we will let you know when it is fixed.`);
            console.error(err);
            setLoading(false);
        }
    };

    const handleRowEdit = (newData) => {
        handleSubmit(cacheOptions);
    };

    return (
        <div>
            <Grid container spacing={2}
                  sx={{
                      padding: 2,
                      backgroundColor: 'white',
                      borderRadius: '8px',
                      border: '4px',
                      paddingLeft: 0,
                      paddingTop: 0,
                      marginLeft: '-10px',
                      marginTop: '-5px',
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.08)',
                  }}
            >
                <Grid item xs={12} md={12} sx={{width: "100%"}}>
                    <Accordion sx={{width: "100%"}}>
                        <AccordionSummary
                            expandIcon={<InfoOutlinedIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                                backgroundColor: '#f0f3f5',
                                width: "100%",
                                height: '35px !important',
                                minHeight: '25px !important',
                            }}
                        >
                            Explanations
                        </AccordionSummary>
                        <AccordionDetails sx={{width: "100%"}}>
                            <Box>
                                <Typography variant="subtitle1" sx={{marginBottom: '1rem'}}>
                                    📊 Report Options and Functionalities
                                </Typography>

                                <Typography variant="subtitle1" sx={{marginBottom: '0.5rem'}}>
                                    📝 Create New Record:
                                </Typography>
                                <Typography variant="body1" sx={{marginBottom: '1rem', marginLeft: '1rem'}}>
                                    You can create a new entry by clicking the "Create" button. This allows you to fill
                                    in the fields and add a new record to the tool.
                                </Typography>

                                <Typography variant="subtitle1" sx={{marginBottom: '0.5rem'}}>
                                    🚀 Generate Report:
                                </Typography>
                                <Typography variant="body1" sx={{marginBottom: '1rem', marginLeft: '1rem'}}>
                                    Once you have made the desired selections and configurations, you can click the
                                    "Generate Report" button.
                                    The report generation process involves making API requests and fetching the required
                                    data based on the selected options.
                                </Typography>
                            </Box>

                            <Alert severity="error"
                                   icon={false}
                                   sx={{
                                       width: "100%",
                                       marginBottom: '20px',
                                   }}
                            >
                                <Typography variant="subtitle1" sx={{marginBottom: '1rem'}}>
                                    <span>⚠️</span> This tool only affects the internal iRTB functions and the automatic blocking of
                                    CRIDs.
                                    <strong> It does not create or relate to SmartHub lists.</strong>
                                </Typography>
                                <Typography variant="subtitle1">
                                    <span>🚨</span> When a company is added to this white list, <strong> it will be ignored by the CRIDS blocking
                                    system. </strong> This means it won't be blocked, even if it exceeds all thresholds.
                                </Typography>
                            </Alert>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>

            <Grid container spacing={2}
                  sx={{
                      padding: 2,
                      backgroundColor: 'white',
                      borderRadius: '8px',
                      border: '4px',
                      paddingLeft: 0,
                      marginLeft: '-10px',
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.08)',
                      marginTop: '20px',
                  }}
            >
                <Grid item xs={12} md={12} sx={{width: "100%"}}>
                    <CridsWhiteListOptionsForm onSubmit={handleSubmit}/>
                </Grid>
                <Grid item xs={12} md={12}>
                    <CridsWhiteListDataGrid data={data} onRowEdit={handleRowEdit}/>
                </Grid>
            </Grid>
        </div>
    );
};

export default CridsWhiteList;