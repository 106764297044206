import React, {useState, useEffect, useRef} from 'react';
import axios from "axios";
import {
    Button,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails, FormControlLabel, Checkbox, CircularProgress, Box, Radio,
} from '@mui/material';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import {
    fetchMissedRowsDataFields, fetchMissedRowsStatisticFields
} from '../../../API/AdsReport';
import {sendDataToApi} from '../../../API/apiHelper';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

function BundleReportOptionsForm({ onGenerate }) {
    const [options, setOptions] = useState({
        fields: [],
        entity: [],
        stats: [],
    });

    const [statistics, setStatistics] = useState([]);

    const [fields, setFields] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const fields = await fetchMissedRowsDataFields();
            setFields(fields);
        };

        const fetchStatistics = async () => {
            const stats = await fetchMissedRowsStatisticFields();
            setStatistics(stats);

        };

        fetchStatistics();
        fetchData();
    }, []);

    // useEffect(() => {
    //     if (fields.length > 0){
    //         var first = fields[0];
    //         setOptions({ ...options, fields: first.value });
    //     }
    //
    // }, [fields]);


    const handleCheckboxChange = (event) => {
        const { name } = event.target;
        setOptions({ ...options, entity: name });
    };


    const handleCheckboxChangeStat = (event) => {
        const { name, checked } = event.target;
        let stats = [...options.stats];

        if (checked) {
            stats.push(name);
        } else {
            stats = stats.filter((satat) => satat !== name);
        }

        setOptions({ ...options, stats });
    };

    useEffect(() => {
        const storedValue = Cookies.get('MissedRows');
        if (storedValue) {
            const parsedValue = JSON.parse(storedValue);

            if ('stats' in parsedValue) {
                setOptions(prevOptions => ({
                    ...prevOptions,
                    stats: parsedValue.stats
                }));
            }

            if ('entity' in parsedValue) {
                setOptions(prevOptions => ({
                    ...prevOptions,
                    entity: parsedValue.entity
                }));
            }
        }else{
            if (fields.length > 0){
                var first = fields[0];
                setOptions({ ...options, entity: first.value });
            }
        }
    }, [fields]);

    const handleSubmit = async (event) => {
        try {
            setLoading(true);
            const response = await sendDataToApi('post','/api/report-ads-txt/report', options, 'MissedRows');
            if (Object.keys(response.data).length === 0) {
                onGenerate(false);
            } else {
                const fieldsArray = Object.entries(response.data).map(([key, value]) => ({key, value}));
                onGenerate(fieldsArray);
            }
            setLoading(false);
        } catch (err) {
            toast.error(`Oops, there seems to be an error on the server. The technical team has already received information about this. We apologize for the inconvenience and we will let you know when it is fixed.`);
            setLoading(false);
            console.error(err);
            return [];
        }

    };

    return (
        <Grid container spacing={2} sx={{ width: "100%", marginLeft: '0px', }}>
            <Accordion sx={{ width: "100%" }}>
                <AccordionSummary
                    expandIcon={<KeyboardDoubleArrowDownOutlinedIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ backgroundColor: '#f0f3f5', width: "100%"  }}
                >
                    Options
                </AccordionSummary>
                <AccordionDetails sx={{ width: "100%" }}>
                    <form onSubmit={handleSubmit}>
                        <Grid item xs={12} md={12} sx={{ width: "100%" }}>
                            <Grid item xs={12} md={12} sx={{ width: "100%" }}>
                                <Grid item xs={12} md={12}>
                                    {Object.keys(fields).length != 0 ? ( <strong>Entities</strong> ) : ''}
                                </Grid>
                                {fields.map((field, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Radio
                                                checked={options.entity === field.value}
                                                onChange={handleCheckboxChange}
                                                name={field.value}
                                            />
                                        }
                                        label={field.value}
                                    />
                                ))}
                            </Grid>

                            <Grid item xs={12} md={12} sx={{ width: "100%", margin: '25px 0 25px auto' }}>

                                <Grid item xs={12} md={12}>
                                    {Object.keys(statistics).length != 0 ? (  <strong>Statistics</strong>) : ''}
                                </Grid>
                                {statistics.map((statistic, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                                checked={options.stats.includes(statistic.key)}
                                                onChange={handleCheckboxChangeStat}
                                                name={statistic.key}
                                            />
                                        }
                                        label={statistic.value}
                                    />
                                ))}

                            </Grid>
                        </Grid>
                    </form>
                </AccordionDetails>
            </Accordion>

            <Grid item xs={6} md={6}>
                <Button
                    size={"large"}
                    startIcon={<BackupTableOutlinedIcon fontSize="small"  />}
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ marginLeft: '-15px' }}
                >
                    Generate Report
                </Button>
            </Grid>
            {loading &&
                <Grid item xs={12} md={12}>
                    <Box justifyContent="center" textAlign={"center"} alignItems="center">
                        <CircularProgress size={200}/>
                    </Box>
                </Grid>}
        </Grid>
    );
}

export default BundleReportOptionsForm;
